import Skeleton from '@mui/material/Skeleton';
import './SkeletonLogin.css';


function SkeletonLogin() {

    return (
        <>
            <div className="row d-flex justify-content-center ">
                {/* <div className=" col-lg-2 col-md-5 col-sm-5 mb20">
                    <span className=" box-url">
                        <Skeleton className='' variant="rounded" width={'100%'} height={20} />
                    </span>
                </div> 
                <div className="col-lg-2 col-md-5 col-sm-5 mb20">
                    <span className=" box-url">
                        <Skeleton className='' variant="rounded" width={'100%'} height={20} />
                    </span>
                </div>
                <div className=" col-lg-2 col-md-5 col-sm-5 mb20">
                    <span className=" box-url">
                        <Skeleton className='' variant="rounded" width={'100%'} height={20} />
                    </span>
                </div>*/}
            </div>
            <div className="row d-flex justify-content-center ">
                <div className=" metamask col-xl-3 col-lg-4 col-md-6 mb30">
                    <span className=" box-url">
                        <Skeleton className='circle' variant="circular" width={70} height={70} />
                        <Skeleton className='rectangle' variant="rounded" width={110} height={30} />
                        <Skeleton className='mt-2' variant="rounded" width={130} height={35} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                    </span>
                </div>
                <div className=" torus col-xl-3 col-lg-4 col-md-6 mb30">
                    <span className=" box-url">
                        <Skeleton className='circle' variant="circular" width={70} height={70} />
                        <Skeleton className='rectangle' variant="rounded" width={110} height={30} />
                        <Skeleton className='mt-2' variant="rounded" width={130} height={35} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                    </span>
                </div>
                {/* <div className=" torus col-xl-3 col-lg-4 col-md-6 mb30">
                    <span className=" box-url">
                        <Skeleton className='circle' variant="circular" width={70} height={70} />
                        <Skeleton className='rectangle' variant="rounded" width={110} height={30} />
                        <Skeleton className='mt-2' variant="rounded" width={130} height={35} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                        <Skeleton variant="text" width={250} height={34} />
                    </span>
                </div> */}


            </div>
        </>

    )

}
export default SkeletonLogin;
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ConfigType, SCValueType, setSCError, setSCSingleConfig, setSCSingleValue, SmartContractDetail} from "../../features/smartContractDetail/smartContractDetailsReducer";
import {genericMetamaskAction, PausePayload, ValuePayload} from "./metamask/genericMetamaskAction";
import { UserState } from "../../features/user/user";
import { ethers } from "ethers";
import SmartContractActionsService from "../../services/smartContract-actions.service";

export const setNewPriceAction = createAsyncThunk('SC/setNewPrice', async ({ smartContractId, value }: ValuePayload, thunkAPI) => {

    const user: UserState = (thunkAPI.getState() as any).user;
    const {smartContract}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    const wallet = user.userInfo.wallets?.find(wallet => wallet.wallet === smartContract?.walletOwner);
    if (wallet?.loginType === 'metamask') { //TODO: Handle multiple metamask accounts
        await genericMetamaskAction({
            smartContractId,
            value,
            scValueKey: SCValueType.price,
            contractAction: 'setPrice',
            params: [value.toString()],
            transactionMessage: 'price'
        }, thunkAPI);
    } else {
        thunkAPI.dispatch(setSCSingleValue({smartContractId, scValueKey: SCValueType.price, loading: true, isValidated: true}));
        try {
            await SmartContractActionsService.getInstance().smartContractSetPrice(smartContractId, value);
            const newValue = Number(ethers.utils.formatEther(value));;
            thunkAPI.dispatch(setSCSingleValue({smartContractId, scValueKey: SCValueType.price, loading: false, isValidated: false, showModal: false, value: newValue}));

        } catch (error) {
            thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'Error set price'}));
            thunkAPI.dispatch(setSCSingleValue({smartContractId, scValueKey: SCValueType.price ,loading: false, showModal: false, isValidated: false}));
        }
    }
});

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function TorusModal({ show, setShow }: TorusModalProps) {
    const { t } = useTranslation('common');
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('login.torusmodal.torus')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('login.torusmodal.the-functionality')}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-main' onClick={() => setShow(false)}>{t('login.torusmodal.close')}</button>
            </Modal.Footer>
        </Modal>
    );
}

interface TorusModalProps {
    show: boolean;
    setShow: (value: boolean) => void;
}

export default TorusModal;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SmartContractDetail, setBurnNFT } from "../../../features/smartContractDetail/smartContractDetailsReducer";
import { TransactionMTM } from "../../../services/smartContract.service";
import BlockchainConnector from "../../../services/blockchain/BlockchainConnector";


export const burnNFTsActionTx = createAsyncThunk('SC/burnNFTsTX', async ({smartContractId ,idNFTs, tx}: TransactionMTM, thunkAPI) => {

    const {smartContract}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    
    if (smartContract) {
        const chain = parseInt(smartContract.blockchainId!, 10);
        const blockchain = new BlockchainConnector(chain);
        const provider = await blockchain.getProvider();
        const transaction = await provider.getTransaction(tx);
        await transaction.wait(1);
    
        const idNFT = idNFTs[0];
    
        thunkAPI.dispatch(setBurnNFT({smartContractId, idNFT, burning: false}))
    }
});

import { ethers } from "ethers";
import { BlockchainNetwork } from "../../models/blockchains";
import { BlockchainDeployer } from "./BlockchainConnector";
import { CLPublicKey, CLValueBuilder, CasperClient, CasperServiceByJsonRPC, DeployUtil, RuntimeArgs, Contracts, Signer } from "casper-js-sdk";
import SmartContractService from "../smartContract.service";
import { SmartContract } from "../../models/smartContract";
import { NFT, NFTView } from "../../models/nfts";

export default class CasperDeployer implements BlockchainDeployer {

    casperClient: CasperClient;
    casperService: CasperServiceByJsonRPC;

    

    constructor(private blockchain: BlockchainNetwork) {
        this.casperClient = new CasperClient(this.blockchain.rpcUrl);
        this.casperService = new CasperServiceByJsonRPC(this.blockchain.rpcUrl);
    }

    async getConnection() {
        try {
            const CasperWalletProvider = (window as any).CasperWalletProvider;
            const provider = CasperWalletProvider();
            await provider.requestConnection();
        } catch (error) {
            throw new Error('Error connecting with Casper Wallet')
        }
        
    }

    getProvider(): any {
        return (window as any).CasperWalletProvider;
    }

    async execute(smartContractCompiled: any, smartContractId : string, smartContractName: string): Promise<string> {
        const CasperWalletProvider = (window as any).CasperWalletProvider;
        const provider = CasperWalletProvider();
        const isConnected = await provider.isConnected();
        if (!isConnected) {
            throw new Error('You need to connect with Casper Wallet');
        }
        const publicKeyHex = await provider.getActivePublicKey();
        const publicKey = CLPublicKey.fromHex(publicKeyHex);
        console.log("SM TO DEPLOY: ", smartContractCompiled);
        const {signature} = await provider.sign(JSON.stringify(smartContractCompiled), publicKeyHex);
        DeployUtil.setSignature(smartContractCompiled.deploy, signature, publicKey);
        const deployObject = DeployUtil.deployFromJson(smartContractCompiled);
        if (!deployObject.ok) {
            console.error(deployObject);
            throw new Error('Error creating the deploy from given params');
        }
        // deployObject.val.header.timestamp = new Date().getTime();
        const deployHash = await this.casperClient.putDeploy(deployObject.val);
        await this.getDeploy(deployHash);

        const accountInfo = await this.getAccountInfo(publicKey);
        const smartContractAddress = accountInfo?.namedKeys.find(nk => nk.name === 'cep78_contract_hash_' + smartContractName)?.key!;
        const packageHash = accountInfo?.namedKeys.find(nk => nk.name === 'cep78_contract_package_' + smartContractName)?.key!;
        // console.log("Account info: ", accountInfo );

        SmartContractService.getInstance().signSmartContract(smartContractAddress,packageHash, smartContractId);
        return smartContractAddress;
    }

    async mint(smartContract: SmartContract, nfts: NFTView[],nft : NFTView) {
        const CasperWalletProvider = (window as any).CasperWalletProvider;
        const provider = CasperWalletProvider();
        const isConnected = await provider.isConnected();
        if (!isConnected) {
            throw new Error('You need to connect with Casper Wallet');
        }
     
        const publicKeyHex = await provider.getActivePublicKey();
        const publicKey = CLPublicKey.fromHex(publicKeyHex);
        // console.log('hex: ', publicKeyHex , 'key: ' , publicKey);

        const contractClient = new Contracts.Contract(this.casperClient);
        contractClient.setContractHash(smartContract.smartContractAddress!);

        
        // const response : any = await fetch(nft.json);
        const nftData : any = {name : nft.name, description : nft.description, attributes : nft.attributes, image : nft.ipfsImage}

        //  console.log("nft IS : ", nftData);

        const runtimeArgs = RuntimeArgs.fromMap({
            token_owner: CLValueBuilder.key(CLPublicKey.fromHex(nft.wallet)),
            token_meta_data: CLValueBuilder.string(JSON.stringify(nftData)),
        });

        // console.log("Args mint:", runtimeArgs);

        
        // try {
            const isTestnet: boolean = process.env.REACT_APP_ENV_IS_TESTNET === 'true';
            const mintDeploy = contractClient.callEntrypoint('mint', runtimeArgs, publicKey, isTestnet ? 'casper-test' : 'casper', '3000000000');

            const {signature} = await provider.sign(JSON.stringify(DeployUtil.deployToJson(mintDeploy)), publicKeyHex);

            DeployUtil.setSignature(mintDeploy, signature, publicKey);

            const deployHash = await this.casperClient.putDeploy(mintDeploy);
            await this.getDeploy(deployHash);
        // } catch (error) {
        //     console.log("ERROR CASPER DEPLY: ", error);
        // }
        
    }

    private async getAccountInfo (publicKey: CLPublicKey)  {
        const stateRootHash = await this.casperService.getStateRootHash();
        const accountHash = publicKey.toAccountHashStr();
        const blockState = await this.casperService.getBlockState(stateRootHash, accountHash, []);
        return blockState.Account;
    };

    private async getDeploy(deployHash: string) {
        let i = 300;
        while (i !== 0) {
          const [deploy, raw] = await this.casperClient.getDeploy(deployHash);
          if (raw.execution_results.length !== 0) {
            // @ts-ignore
            if (raw.execution_results[0].result.Success) {
              return deploy;
            } else {
              // @ts-ignore
              throw Error(
                "Contract execution: " +
                  // @ts-ignore
                  raw.execution_results[0].result.Failure.error_message
              );
            }
          } else {
            i--;
            await this.sleep(1000);
            continue;
          }
        }
        throw Error("Timeout after " + i + "s. Something's wrong");
    }

    private sleep = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };


}
import {setPriceShowModal} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {Modal, Spinner} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../app/hooks";
import AnalyticsService from "../../../services/analytics.service";
import {setNewPriceAction} from "../../../actions/smartContractDetails/setNewPriceAction";
import {useState} from "react";
import {useSelector} from "react-redux";
import {selectSCPrice} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";

function SCDetailsPriceModal() {

    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const {smartContractId} = useParams<{ smartContractId: string }>();
    const price = useSelector(selectSCPrice(smartContractId!));

    const [newPrice, setNewPrice] = useState<number>(0);


    const submitPrice = (event: any) => {
        AnalyticsService.click("SDC:(BC) Set Price");
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true && smartContractId) {
            dispatch(setNewPriceAction({smartContractId, value: ethers.utils.parseUnits(String(newPrice), 18)}))
        }
    }

    return (
        <Modal
            className='modal-container'
            show={price?.showModal}
            onHide={() => smartContractId && dispatch(setPriceShowModal({smartContractId, show: false}))}
            backdrop="static"
            keyboard={true}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton >
                <Modal.Title className="col-11" >
                    <h3 className="text-center">{t('scdetails.set-price')}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form validated={price?.isValidated} onSubmit={submitPrice}>
                    <Form.Group>
                        <Form.Control onChange={(event: any) => setNewPrice(event.target?.value)} type="number" step="0.01" min="0" pattern="[0-9]*" placeholder="Price..." required />
                        {price?.loading && 
                            <div className="honeycomb">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            </div>
                        }
                        {!price?.loading && <button className='btn-main' type="submit">{t('scdetails.Set-Price')}</button>}
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default SCDetailsPriceModal;
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3AuthNoModalOptions } from "@web3auth/no-modal";

export const TestnetWeb3Chain: Web3AuthNoModalOptions = {
    clientId: process.env.REACT_APP_WEB3AUTH_CLIENTID!,
    chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x13881",
        displayName: 'Mumbai',
        ticker: 'MATIC',
        rpcTarget: "https://rpc-mumbai.maticvigil.com", // This is the public RPC we have added, please pass on your own endpoint while creating an app
        blockExplorer: 'https://mumbai.polygonscan.com'
    },
    web3AuthNetwork: "testnet",
}

export const MainnetWeb3Chain: Web3AuthNoModalOptions = {
    clientId: process.env.REACT_APP_WEB3AUTH_CLIENTID_MAINNET!,
    chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x89",
        displayName: 'Polygon',
        ticker: 'MATIC',
        rpcTarget: "https://polygon-rpc.com", // This is the public RPC we have added, please pass on your own endpoint while creating an app
        blockExplorer: 'https://polygonscan.com/'
    },
    web3AuthNetwork: "mainnet",
}

import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
import { selectJWT } from "../features/user/user";

export enum FileType {
    NFT_IMG,
    NFT_JSON,
    PROFILE_IMG,
    PROFILE_BANNER,
    SMART_CONTRACT_IMG,
    SMART_CONTRACT_BANNER
}

class FileService {

    JWT = useSelector(selectJWT);
    plainJWT: string = '';

    async uploadFile(file : any, fileType : FileType){
        const formData = new FormData();
        formData.append("file", file);

        try {
          ////console.log("TRY UPLOAD IMAGE", formData);
          const url = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + this.getPath(fileType);
          const response = await axios.post(url,formData,this.getConfigRequest());
          return response?.data?.result?.imageS3?.name;
        } catch(error) {
          ////console.log(error)
          return {name: "name Y"};
        }
    }

    getPath(fileType: FileType) {

        switch (fileType) {
            case FileType.NFT_IMG:
                return '/nft/upload-image'
            case FileType.NFT_JSON:
                return '/nft/upload-image'
            case FileType.SMART_CONTRACT_IMG:
                return '/files/smart-contract/image';
            case FileType.SMART_CONTRACT_BANNER:
                return '/files/smart-contract/banner'
            case FileType.PROFILE_IMG:
                return '/files/profile/image';
            case FileType.PROFILE_BANNER:
                return '/files/profile/banner';
    
        }
    }


    getConfigRequest () :AxiosRequestConfig {
        return {
            headers: {
                authorization: this.JWT || this.plainJWT
            }
        };
    }
}

export default FileService;
import { BlockchainNetwork } from "../models/blockchains";
import { MetamaskBlockchainListAvailable, CasperBlockchainListAvailable } from "./blockchain/BlockchainsData";

class BlockchainConnectService {

  static async getConnection(chainId: number) {
    const blockchainList = MetamaskBlockchainListAvailable;
    let blockchain = blockchainList.find(bc => bc.chainId === chainId);
    if(blockchain){
      this.getMetamaskConnection(blockchain);
    }
    const casperBlockchain = CasperBlockchainListAvailable;
    blockchain = casperBlockchain.find(bc => bc.chainId === chainId);
    if (blockchain) {
      this.getCasperConnection(blockchain);
    }
    
  }

  private static async getMetamaskConnection(blockchain: BlockchainNetwork) {
    try {
      await (window as any).ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId : '0x' + blockchain.chainId.toString(16) }], 
      })
    } catch (error) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId : '0x' + blockchain.chainId.toString(16), // '0x3830303031'
              blockExplorerUrls: [blockchain.explorerUrl], // ['https://mumbai.polygonscan.com']
              chainName: blockchain.name, // 'Mumbai Testnet'
              nativeCurrency: {
                decimals: 18,
                name: blockchain.name,
                symbol: blockchain.currencyName
              },
              rpcUrls: [blockchain.rpcUrl] // ['https://matic-mumbai.chainstacklabs.com']
            },
          ],
        });
      } catch (error: any) {
        throw new Error("Error adding Network");
        
        // user rejects the request to "add chain" or param values are wrong, maybe you didn't use hex above for `chainId`?
        // //console.log(`wallet_addEthereumChain Error: ${error.message}`)
      }
    }
    
  }

  private static async getCasperConnection(blockchain: BlockchainNetwork) {
  }
}

export default BlockchainConnectService;
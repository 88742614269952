import { useTranslation } from "react-i18next";
import AnalyticsService from "../../services/analytics.service";
import { LOGIN_TYPE, Web3AuthConnector } from "../../services/blockchain/Web3Connector";
import SkeletonLogin from "./SkeletonLogin";
import useLoadingAuth from "./useLoadingAuth";
import { ethers } from "ethers";
import { CLPublicKey,Keys } from "casper-js-sdk";
// import fs from "fs";
// import path from "path";

interface LoginButtonsProps {
    loadLogin: boolean;
    login: (wallet: string, loginType: LOGIN_TYPE, userInfo?: any) => void;
}

function LoginButtons({loadLogin, login}: LoginButtonsProps) {

    const {isAuthLoading, loadingAuth, setLoading} = useLoadingAuth();
    const { t } = useTranslation('common');

    // const createAccountKeys = () => {
    //     // Generating keys
    //     const edKeyPair = Keys.Ed25519.new();
    //     const { publicKey, privateKey } = edKeyPair;

    //     console.log(publicKey);
    
    //     // Create a hexadecimal representation of the public key
    //     const accountAddress = publicKey.toHex();
    
    //     // Get the account hash (Uint8Array) from the public key
    //     const accountHash = publicKey.toAccountHash();
    
    //     // Store keys as PEM files
    //     const publicKeyInPem = edKeyPair.exportPublicKeyInPem();
    //     const privateKeyInPem = edKeyPair.exportPrivateKeyInPem();
    
        
    //     console.log("PUBLIC:", publicKeyInPem);
    //     console.log("PRIVATE:", privateKeyInPem);
    //     // fs.writeFileSync(folder + "/" + accountAddress + "_public.pem", publicKeyInPem);
    //     // fs.writeFileSync(folder + "/" + accountAddress + "_private.pem", privateKeyInPem);

    //     console.log("KEYS", accountAddress);
    
    //     return accountAddress;
    // };

    const connectWith = async (loginType: LOGIN_TYPE) => {
        //console.log("LOGIN WITH : ", loginType);
        try {
            setLoading(loginType, true);
            AnalyticsService.click('Login with ' + loginType);

            let wallet ;
            let userInfo = {};
            if(loginType !== LOGIN_TYPE.casper){
                const [rpc, web3Auth] =await Web3AuthConnector.connect(loginType);
                wallet = await rpc.getAccounts();
                userInfo = await web3Auth.getUserInfo();
                //console.log("wallet is : " , wallet, " -> USER INFO: ", userInfo);
            }
            else{
                const CasperWalletProvider = (window as any).CasperWalletProvider;
                const provider = CasperWalletProvider();

                const req = await provider.requestConnection();
                //console.log("REQ: ", req);
                // const provider = CasperWalletProvider();
                const isConnected = await provider.isConnected();
                if (!isConnected) {
                    throw new Error('You need to connect with Casper Wallet');
                }
                const publicKeyHex = await provider.getActivePublicKey();
                //console.log(publicKeyHex);
                wallet = publicKeyHex;
                //console.log('Wallet casper is: ', wallet);
            }
            login(wallet, loginType, userInfo);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(loginType, false);
        }
    }

    return (
        <>
            <div className='container '>
                    {loadLogin && (<SkeletonLogin/>)}
                    {/* <div className={loadLogin ? "hide" : "row d-flex justify-content-center"}>
                        <button className="social-login box-url" onClick={createAccountKeys} disabled={isAuthLoading}>
                            <img src="./img/login/google.png"/>
                            CREAR WALLET
                        </button>
                        </div> */}
                    {/* <div className={loadLogin ? "hide" : "row d-flex justify-content-center"}>
                        <button className="social-login box-url" onClick={() => connectWith(LOGIN_TYPE.google)} disabled={isAuthLoading}>
                            <img src="./img/login/google.png"/>
                            {t('login.google')}
                        </button>
                        <button className="social-login box-url"  onClick={() => connectWith(LOGIN_TYPE.discord)} disabled={isAuthLoading}>
                            <img src="./img/login/discord.png"/>
                            {t('login.discord')}
                        </button>
                        <button className="social-login box-url"  onClick={() => connectWith(LOGIN_TYPE.linkedin)} disabled={isAuthLoading}>
                            <img src="./img/login/linkedin.png"/>
                            {t('login.linkedin')}
                        </button>
                    </div> */}
                    {/* <div className={loadLogin ? "hide" : "row d-flex justify-content-center"}>
                        <div className="separator col-lg-4 col-md-6"></div>
                    </div> */}
                    <div className={loadLogin ? "hide" : "row d-flex justify-content-center"}>
                        <div className="torus col-xl-3 col-lg-4 col-md-6  mb30">
                            <span className=" box-url" onClick={() => connectWith(LOGIN_TYPE.casper)}>
                                <img src="./img/wallet/casper.svg" alt="" className="mb20" />
                                <br/><br/>
                                <span className="box-url-label">Most Recommended</span>
                                <br/>
                                <br/>
                                {/* <h4>Casper Wallet </h4> */}
                                <p>Casper Wallet is the easy and secure way to manage your CSPR, sign transactions, connect to dapps and more.</p>
                            </span>
                        </div>
                        <div className=" metamask col-xl-3 col-lg-4 col-md-6 mb30">
                            <span className=" box-url" onClick={() => connectWith(LOGIN_TYPE.metamask)}>
                                <span className="box-url-label">{t('login.most-popular')}</span>
                                <img src="./img/wallet/1.png" alt="" className="mb20" />
                                <h4>{t('login.metamask')}</h4>
                                <p>{t('login.start')}</p>
                            </span>
                        </div>

                        {/* <div className="torus col-xl-3 col-lg-4 col-md-6  mb30">
                            <span className=" box-url" onClick={() => connectWith(LOGIN_TYPE.torus)}>
                                <img src="./img/wallet/8.png" alt="" className="mb20" />
                                <h4>{t('login.torus')}</h4>
                                <p>{t('login.open')}</p>
                            </span>
                        </div> */}

                        
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                {isAuthLoading && (
                <div className="login-loading-container">
                    {/* <Spinner animation="border" className="login-spinner"/> */}
                    <div className="honeycomb">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                </div>
                )}
        </>
    );

}

export default LoginButtons;
import {
    mintNFTs,
    signNFTs,
    SmartContractDetail
} from "../../features/smartContractDetail/smartContractDetailsReducer";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { TransactionMTM } from "../../services/smartContract.service";
import BlockchainConnector from "../../services/blockchain/BlockchainConnector";

export const mintNFTsActionTx = createAsyncThunk('SC/mintNFTsTx', async ({smartContractId ,idNFTs, tx}: TransactionMTM, thunkAPI) => {
    const {nftsPending, smartContract}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    const mintedNFTs = nftsPending?.filter(sc => idNFTs.includes(sc._id));
    if (mintedNFTs && mintedNFTs.length > 0) {
        thunkAPI.dispatch(signNFTs({smartContractId, nftsMinting: mintedNFTs}))
        const chain = parseInt(smartContract?.blockchainId!, 10);
        const blockchain = new BlockchainConnector(chain);
        const provider = await blockchain.getProvider();
        const transaction = await provider.getTransaction(tx);
        await transaction.wait(1);
        const nftsNotMinted = nftsPending?.filter(nft => !mintedNFTs.includes(nft._id));
        thunkAPI.dispatch(mintNFTs({smartContractId, nftsPending: nftsNotMinted, nftsMintedMessage: 'NFTs minted correctly'}))
    }
});

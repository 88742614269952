import {modifySCDetails} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {Modal} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../app/hooks";
import {useSelector} from "react-redux";
import {selectSCisMinting} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";
import { Alert, Snackbar } from "@mui/material";

function SCDetailsMintingNotification() {

    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const {smartContractId} = useParams<{ smartContractId: string }>();
    const [showMint, loadingMinting] = useSelector(selectSCisMinting(smartContractId!));
    const close = ()=> dispatch(modifySCDetails({smartContractId: smartContractId!, showMint: false}));
    
    return (
        <Snackbar
            className='modal-container'
            open={showMint}
            onClose={close}
            aria-labelledby="contained-modal-title-vcenter"
            autoHideDuration={6000}>
            <Alert severity="info" onClose={close}>
                {t('scdetails.nftsCreating')}
            </Alert>
        </Snackbar>
    )
}

export default SCDetailsMintingNotification;
import { WALLET_ADAPTERS } from "@web3auth/base";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { TorusWalletAdapter } from "@web3auth/torus-evm-adapter";
import EthereumRpc from "./ethersRPC";
import {MainnetWeb3Chain, TestnetWeb3Chain} from "./Web3Chains";
import * as Sentry from "@sentry/react";
import { CLPublicKey } from "casper-js-sdk";

export class Web3AuthConnector {

    private static web3Auth: Web3AuthNoModal;
    private static provider: EthereumRpc | any ;

    private constructor() {}

    static async init() {
        try {
            const isTestnet: boolean = process.env.REACT_APP_ENV_IS_TESTNET === 'true';
            const web3Auth = new Web3AuthNoModal(isTestnet ? TestnetWeb3Chain : MainnetWeb3Chain);
    
            const openloginAdapter = new OpenloginAdapter({
                loginSettings: {
                    mfaLevel: "none"

                },
                adapterSettings: {
                    whiteLabel: {
                    name: "Minteandome",
                    logoLight: "https://app.minteando.me/img/favicon.png",
                    logoDark: "https://app.minteando.me/img/favicon.png",
                    defaultLanguage: "en",
                    dark: true, // whether to enable dark mode. defaultValue: false
                    },
                },
            });
            web3Auth.configureAdapter(openloginAdapter);
    
            const metamaskAdapter = new MetamaskAdapter(isTestnet ? TestnetWeb3Chain : MainnetWeb3Chain);
            web3Auth.configureAdapter(metamaskAdapter);
    
            const torusWalletAdapter = new TorusWalletAdapter({
                clientId: isTestnet ? process.env.REACT_APP_WEB3AUTH_CLIENTID : process.env.REACT_APP_WEB3AUTH_CLIENTID_MAINNET!,
            });
    
            web3Auth.configureAdapter(torusWalletAdapter);
    
            this.web3Auth = web3Auth;
    
            await web3Auth.init();    
          } catch (error) {
            Sentry.captureException(error);
            console.error(error);
          }
    }

    static async connect(loginType: LOGIN_TYPE): Promise<Web3Connection> {
        await this.getWeb3Auth();
        if (this.web3Auth.provider) {
            await this.logout();
        }
        this.provider = await this.getProvider(loginType);
        return [this.provider, this.web3Auth];
    }

    static async getInfo(): Promise<Web3Connection> {
        if (!this.provider) {
            this.provider = await this.getProvider();
        }
        return [this.provider, this.web3Auth];
    }

    static async logout() {
        if (this.web3Auth) {
            await this.web3Auth.logout();
        }
        this.provider = null as any;
    }

    private static async getWeb3Auth(): Promise<Web3AuthNoModal> {
        if (!this.web3Auth) {
            await this.init();
        }
        return this.web3Auth;
    }

    private static async getProvider(loginType?: LOGIN_TYPE): Promise<EthereumRpc> {
        //console.log('Get provider with casper case');
        if (!this.web3Auth) {
            await this.init();
        }
        let web3authProvider;

        if(loginType === LOGIN_TYPE.casper){
            const CasperWalletProvider = (window as any).CasperWalletProvider;
            web3authProvider = CasperWalletProvider();
            // const provider = CasperWalletProvider();
            const isConnected = await web3authProvider.isConnected();
            if (!isConnected) {
                throw new Error('You need to connect with Casper Wallet');
            }
        
        }
        else if (loginType) {
            const login = LOGINS[loginType];
            web3authProvider = await this.web3Auth.connectTo(login.walletAdapter, login.loginParams);
        } else {
            web3authProvider = this.web3Auth.provider;
        }
        if (!web3authProvider) {
            throw new Error('Web3Auth connect not completed');
        }
        const provider = new EthereumRpc(web3authProvider);
        this.provider = loginType === LOGIN_TYPE.casper ? web3authProvider : provider;
        return provider;
    }

}

const LOGINS = {
    google: {
        walletAdapter: WALLET_ADAPTERS.OPENLOGIN,
        loginParams: {
            loginProvider: "google",
          }
    },
    metamask: {
        walletAdapter: WALLET_ADAPTERS.METAMASK,
        loginParams: {
            loginProvider: 'metamask'
        }
    },
    discord: {
        walletAdapter: WALLET_ADAPTERS.OPENLOGIN,
        loginParams: {
            loginProvider: 'discord'
        }
    },
    torus: {
        walletAdapter: WALLET_ADAPTERS.TORUS_EVM,
        loginParams: {
            loginProvider: 'torus'
        }
    },
    linkedin: {
        walletAdapter: WALLET_ADAPTERS.OPENLOGIN,
        loginParams: {
            loginProvider: 'linkedin'
        }
    },
    casper : {
        walletAdaper : 'CASPER',
        loginParams: {
            loginProvider: 'casper'
        }
    }
}

export enum LOGIN_TYPE {
    google = 'google',
    metamask = 'metamask',
    discord = 'discord',
    torus = 'torus',
    linkedin = 'linkedin',
    casper = 'casper'
}


type Web3Connection = [provider: EthereumRpc | any , web3Auth: Web3AuthNoModal];

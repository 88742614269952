import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate } from 'react-router-dom';
import analyticsService from '../services/analytics.service';
import { useAppDispatch } from '../app/hooks';
import { logout, selectJWT } from "../features/user/user";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ModalNotification from './ModalNotification';
import { selectPaymentData } from '../features/dashboard/dashboardSelectors';
import ModalPayment from './ModalPayment';
import { useTranslation } from 'react-i18next';
import MTMDrawer from './Drawer';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function NewHeader() {

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const jwt = useSelector(selectJWT);
    const [showModalCrypto, setShowModalCrypto] = React.useState(false);
    const paymentData = useSelector(selectPaymentData);
    const { t } = useTranslation('common');

    const handleDrawerOpen = () => {
        setOpen(window.screen.width > 610 ? true : false);
    };

    const closeSession = async (e: any) => {
        analyticsService.click("Logout");
        e?.preventDefault();
        await invalidateUser();
    }


    const invalidateUser = async () => {
        dispatch(logout())
        navigate('/');
    }

    return (
        <>
            <Box sx={{ display: 'flex' }} className='flex-shrink-0 text-white header-style'>
                <CssBaseline />
                <AppBar position="fixed" open={open} className=' header-style bg-dark-mtm'>
                    <Toolbar>
                        {jwt && (<IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>)}
                        <div className='row' style={{ width: '100%' }}>
                            <div className='col-3'>
                                <NavLink to="/">
                                    <img
                                        src={process.env.REACT_APP_ENV_IS_TESTNET! === 'true' ? "/img/header/mtm-white-hd.png" : "/img/header/mtmprowhite.png"}
                                        className="m-3 img-header d-block logo-header"
                                        alt="#"
                                    />
                                </NavLink>
                            </div>
                           {jwt && ( <div className='col-9 d-flex flex-row-reverse'>
                                <a className="btn-main  btn-close-header" onClick={closeSession} href='/#'>
                                    <img alt="" src="/img/header/close.png" className="img-close" />
                                </a>
                            </div>)}
                        </div>
                    </Toolbar>
                </AppBar>
                {jwt && <MTMDrawer open={open} setOpen={setOpen}/>}
            </Box>
            <Modal
                show={showModalCrypto}
                onHide={() => setShowModalCrypto(false)}
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title className="col-11" >
                        <h3 className="text-center">
                            {t('header.modal.title')}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 m-3 icon-box style-selected rounded" onClick={() => window.open("https://mumbaifaucet.com/", "_blank")}>
                            <img className="img-blockchain" src="/img/blockchains/white/polygon.png" alt="polygon" />
                        </div>
                        <div className="col-6 m-3 icon-box style-selected rounded" onClick={() => window.open("https://goerlifaucet.com/", "_blank")}>
                            <img className="img-blockchain" src="/img/blockchains/white/eth.png" alt="polygon" />
                        </div>
                        <div className="col-6 m-3 icon-box style-selected rounded" onClick={() => window.open("https://testnet.bnbchain.org/faucet-smart", "_blank")}>
                            <img className="img-blockchain" src="/img/blockchains/white/bsc.png" alt="polygon" />
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <ModalNotification />
            {paymentData?.clientSecret && (<ModalPayment paymentData={paymentData} />)}
        </>
    );
}


export default NewHeader;
import { ethers } from "ethers";
import {
    ConfigType, SCValueType,
    setSCError,
    setSCSingleConfig, setSCSingleValue,
    SmartContractDetail
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import AnalyticsService from "../../../services/analytics.service";
import {initializeContract} from "../../../utils/blockchainUtils";
import BlockchainConnector from "../../../services/blockchain/BlockchainConnector";
import { setNotification } from "../../../features/dashboard/dashboardReducer";

export const genericMetamaskAction = async ({ smartContractId, pause, configKey, transactionMessage, contractPauseAction,
                                        contractUnPauseAction, contractAction, scValueKey, value = 0, params = []}: FullActionPayload, thunkAPI: any) => {
    
    const smartContractDetails: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];


    if (!(await isSelectedWalletOwner(smartContractDetails.smartContract))) {
        thunkAPI.dispatch(setNotification({
            title: 'Your Metamask wallet is not the SmartContract owner',
            description: 'The owner of the SmartContract is: ',
            descriptionDetails: smartContractDetails.smartContract?.walletOwner,
            paymentButton: false
        }));
        return;
    }
    AnalyticsService.click("SDC: Set " + transactionMessage);
    
    if (configKey) {
        thunkAPI.dispatch(setSCSingleConfig({smartContractId, configKey ,loading: true}));
    } else {
        thunkAPI.dispatch(setSCSingleValue({smartContractId, scValueKey ,loading: true, isValidated: true}));
    }
    const contract = await initializeContract(smartContractDetails?.smartContract?.smartContractAddress!, smartContractDetails?.smartContract?.blockchainId!);

    let actionPayload: FullActionPayload = contractPauseAction ? {smartContractId, configKey} : {smartContractId, scValueKey};
    try {
        // //console.log("action:", contractUnPauseAction , "value : ", pause);
        const SCAction = contractAction ? contract[contractAction]: (pause ? contract[contractPauseAction!] : contract[contractUnPauseAction!]);
        const response = await SCAction(...params);
        await response.wait(1);
        AnalyticsService.transaction("SDC:(BC) Set " + transactionMessage);
        const newValue = Number(ethers.utils.formatEther(value))
        actionPayload = configKey ? {smartContractId, configKey, pause}: {smartContractId, scValueKey, value : newValue};
    } catch (error: any) {
        const errorMessage = `SDC:(BC) Set ${transactionMessage} ( ${error.reason} ) Blockchain error -> ${error.reason}`;
        AnalyticsService.error(errorMessage);
        thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: errorMessage}));
    } finally {
        const stateAction = configKey ?
            buildSingleConfigAction(actionPayload) :
            buildSingleValueAction(actionPayload);
        thunkAPI.dispatch(stateAction);
    }
};

const buildSingleConfigAction = ({smartContractId, configKey, pause}: FullActionPayload) => {
    const payload: any = {
        smartContractId,
        configKey,
        loading: false,
    }
    if (pause !== undefined) {
        payload.value = pause;
    }
    return setSCSingleConfig({
        smartContractId,
        configKey,
        loading: false
    });
}

const buildSingleValueAction = ({smartContractId, scValueKey, value}: FullActionPayload) => {
    
    const payload: any = {
        smartContractId,
        scValueKey: scValueKey!,
        showModal: false,
        loading: false,
        isValidated: false
    }
    if (value !== undefined) {
        payload.value = value;
    }
    return setSCSingleValue(payload);
}

const isSelectedWalletOwner = async (smartContract: any) => {
    const chain = parseInt(smartContract.blockchainId!, 10);
    const bc = new BlockchainConnector(chain);
    const [currentWallet] = await bc.getCurrentAccount();
    return currentWallet.toLowerCase() === smartContract.walletOwner.toLowerCase();
}

export interface PausePayload {
    smartContractId: string;
    pause: boolean;
}

export interface ValuePayload {
    smartContractId: string;
    value: ethers.BigNumber | number;
}

interface FullActionPayload {
    smartContractId: string;
    value?: ethers.BigNumber | number;
    pause?: boolean;
    contractPauseAction?: string;
    contractUnPauseAction?: string;
    contractAction?: string;
    configKey?: ConfigType;
    scValueKey?: SCValueType;
    transactionMessage?: string;
    params?: unknown[];
}

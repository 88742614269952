import { t } from "i18next";
import { burnNFTsAction } from "../../actions/smartContractDetails/burn/burnNFTsAction";
import { burnNFTsMetamaskAction } from "../../actions/smartContractDetails/burn/burnNFTsMetamaskAction";
import { setNotification } from "../../features/dashboard/dashboardReducer";
import { isSelectedWallet } from "../../services/blockchain/BlockchainConnector";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { selectIsWeb3Contract } from "../../features/user/user";
import { selectSmartContractDetailsChainId, selectSmartContractWalletOwner } from "../../features/smartContractDetail/smartContractDetailSelectors";
import { NFTView } from "../../models/nfts";
import { mintNFTsAction } from "../../actions/smartContractDetails/metamask/mintNFTsAction";

function MintButton({smartContractId, nft}: {smartContractId: string, nft? : NFTView}) {
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    
    const isWeb3Contract = useSelector(selectIsWeb3Contract(smartContractId));
    const chainId = useSelector(selectSmartContractDetailsChainId(smartContractId));
    const walletOwner = useSelector(selectSmartContractWalletOwner(smartContractId));

    return (
        <button className="btn-main"
            onClick={() => dispatch(setNotification({
                title: t('forms.NFTCard.mintTitle'),
                description: t('forms.NFTCard.mintDesc'),
                actionButton: isWeb3Contract ? {
                    text: t('forms.NFTCard.mintButton'),
                    action: () => dispatch(mintNFTsAction({smartContractId,nft})),
                    validator: () => isSelectedWallet(walletOwner, chainId)
                } : undefined,
                errorText: (
                    <>
                        {t('scdetails.walletOwner')} {walletOwner}<br/>
                        {t('scdetails.changeWallet')}
                    </>
                )
        }))}>
            {t('forms.NFTCard.Mint')}     
        </button>
    );
}

export default MintButton;
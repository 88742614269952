import axios, { AxiosRequestConfig } from "axios";
import { PaymentData } from "../models/payment";
import {store} from "../app/store";

class PaymentService {

    // Auth endpoints
    static async paid(body: PaymentData) {
        try {
            const url = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + '/payments/paid';
            const response = await axios.post(url, body, PaymentService.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    static getConfigRequest () :AxiosRequestConfig {
        return {
            headers: {
                authorization: store.getState().user.jwt
            }
        };
    }

}

export default PaymentService;
import { useState } from "react";
import { LOGIN_TYPE } from "../../services/blockchain/Web3Connector";

function useLoadingAuth(): UseLoading {

    const [isAuthLoading, setIsAuthLoading] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState({
        [LOGIN_TYPE.google]: false,
        [LOGIN_TYPE.discord]: false,
        [LOGIN_TYPE.linkedin]: false,
        [LOGIN_TYPE.metamask]: false,
        [LOGIN_TYPE.torus]: false,
        [LOGIN_TYPE.casper]: false,
    });
    const setLoading = (login: LOGIN_TYPE, value: boolean) => {
        setLoadingAuth({
            ...loadingAuth,
            [login]: value,
        });
        setIsAuthLoading(value);
    }
    return {isAuthLoading, loadingAuth, setLoading};
}

interface UseLoading {
    isAuthLoading: boolean;
    loadingAuth: {
        [key in LOGIN_TYPE]: boolean;
    };
    setLoading: (login: LOGIN_TYPE, value: boolean) => void;
}

export default useLoadingAuth;
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectJWT, selectWallet, setJWT, setSubscriptionData, setUserInfo, setWallet } from "../../features/user/user";
import EmailModal from "./emailModal/emailModal";
import PinModal from "./pinModal/pinModal";
import ApiKeyModal from "./apiKeyModal/apiKeyModal";
import LoginService from "../../services/login.service";
import AnalyticsService from "../../services/analytics.service";
import './login.scss';
import TorusModal from "./torusModal/torusModal";
import { useTranslation } from "react-i18next";
import { LOGIN_TYPE, Web3AuthConnector as Web3AuthConnector } from "../../services/blockchain/Web3Connector";
import Notification from '../../components/Notification'
import LoginButtons from "./LoginButtons";
import { CLPublicKey, DeployUtil } from "casper-js-sdk";

function Login() {

    const loginService = new LoginService();
    const userWallet = useAppSelector(selectWallet);

    const [myEmail, setEmail] = useState('');
    const [myNonce, setNonce] = useState('');
    const [myApiKey, setApiKey] = useState('');
    const jwt = useSelector(selectJWT);
    const analyticsService = new AnalyticsService();
    const { t } = useTranslation('common');


    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showPINModal, setShowPINModal] = useState(false);
    const [errorPINModal, setErrorPINModal] = useState(false);
    const [errorSignup, setErrorSignup] = useState(false);
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [showTorusModal, setShowTorusModal] = useState(false);
    const [loginType , setLoginType] = useState<LOGIN_TYPE>(LOGIN_TYPE.metamask);

    const [loadLogin, setLoadLogin] = useState(true);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();



    const connected = async (wallet: string, loginType: LOGIN_TYPE, userInfo?: any) => {
        //console.log("RECIBO LOGIN TYPE : ", loginType, " - wallet : ", wallet);
        try {
            dispatch(setWallet(wallet));
            setLoginType(loginType);
            const user = await loginService.findUserByWalletId(wallet);
            if (user) {
                signIn(wallet, user.nonce, loginType);
            } else {
                if (userInfo.email) {
                    await signUp(userInfo.email, wallet, loginType);
                } else {
                    // METAMASK
                    setErrorPINModal(false);
                    setShowEmailModal(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const validateEmail = async (email: string) => {

        analyticsService.click('validate email User');
        try {
            const result = await loginService.validateEmail(email);
            if (!result) {
                throw new Error('Error while validating email');
            }
            setEmail(email);
            setShowPINModal(true);
        } catch (error) {
            setErrorPINModal(true);
            analyticsService.error('Validate email User');
        }
    }

    const signUp = async (email: string, walletUser: string, loginType : LOGIN_TYPE, pin?: string) => {

        analyticsService.click('SigUp User');
        try {
            setEmail(email);
            let userInfo ,provider, loginInfo : any;
            if(loginType !== LOGIN_TYPE.casper){
                [provider, loginInfo] = await Web3AuthConnector.getInfo();
                userInfo = await loginInfo.getUserInfo();
            }
            // const signature = await provider.getSigner().signMessage('Creating an account for minteandome');
            const signature = loginType === LOGIN_TYPE.casper ?
                            await signUpWithCasper(walletUser, 'Creating an account for minteandome') : 
                            await provider.getSigner().signMessage('Creating an account for minteandome');

            const { nonce, apiKey, accessToken } = await loginService.signUp({
                wallet: walletUser,
                img: userInfo?.profileImage,
                email, 
                loginType: loginType,
                name: userInfo?.name,
                signature,
                idToken: userInfo?.idToken,
                pin
            });
            if (userInfo?.profileImage && userInfo.typeOfLogin !== 'google') { //Google doesnt allow it this way
                loginService.plainJWT = accessToken;
                await loginService.updateImageUserByWalletId(walletUser, userInfo.profileImage);
            }
            setNonce(nonce);
            setApiKey(apiKey);
            if (apiKey) {
                setShowApiKeyModal(true);
            } else {
                signIn(walletUser, nonce,loginType);
            }
        } catch (error) {
            // //console.log("ERROR: ", error);
            analyticsService.error('SigUp User');
            setErrorSignup(true);
        }

    }


    const signIn = async (wallet: string, nonce: string , loginType?: LOGIN_TYPE) => {

        // analyticsService.click('SigIn User ' + wallet);
        try {
            const signature = loginType === LOGIN_TYPE.casper ? await signWithCasper(wallet, nonce) : await signMessage(nonce);
            // //console.log("RECIBO SIGNATURE:", signature);
            if (signature) {
                const loginResponse = await loginService.signIn(wallet, signature, loginType);
                dispatch(setJWT(loginResponse.accessToken));
                dispatch(setUserInfo(loginResponse.userInfo ? loginResponse.userInfo : {}));
                dispatch(setSubscriptionData(loginResponse.subscriptionInfo ? loginResponse.subscriptionInfo : undefined));
                await Web3AuthConnector.logout();
                navigate('/dashboard');
            }
        } catch (error) {
            analyticsService.error('SigIn User');
        }

    }

    const signWithCasper = async (wallet : string, nonce: string) => {
        const CasperWalletProvider = (window as any).CasperWalletProvider;
        const provider = CasperWalletProvider();
        const publicKey = CLPublicKey.fromHex(wallet);
        const publicKeyHex = await provider.getActivePublicKey();
        const res = await provider.signMessage(`Login on minteandome with nonce: ${nonce}`, publicKeyHex);
        if (res.cancelled) {
            throw ('Error signing on Casper');
        }
        
        return {signature : res, publicKey , publicKeyHex, tag : publicKey.getTag()};
    }

    const signUpWithCasper = async (wallet : string, msg: string) => {
        const CasperWalletProvider = (window as any).CasperWalletProvider;
        const provider = CasperWalletProvider();
        const publicKey = CLPublicKey.fromHex(wallet);
        const publicKeyHex = await provider.getActivePublicKey();
        const res = await provider.signMessage(msg, publicKeyHex);
        if (res.cancelled) {
            throw ('Error signing on Casper');
        }
        
        return {signature : res, publicKey , publicKeyHex, tag : publicKey.getTag()};
    }

    const signMessage = async (nonce: string) => {
        const [provider] = await Web3AuthConnector.getInfo();
        const signer = provider.getSigner();
        const signature = await signer.signMessage(`Login on minteandome with nonce: ${nonce}`);
        return signature;
    }

    useEffect(() => {
        analyticsService.view('Login');
        if (jwt) {
            navigate('/dashboard');
        }
        setTimeout(() => {
            setLoadLogin(false);
        }, 1000);
    }, [navigate, jwt]);

    return (
        <>
            <section className="jumbotron breadcumb no-bg">
                <div className='mainbreadcumb mt-5'>
                        <div className='row'>
                            <div className='col-lg-12 d-flex justify-content-center'>
                                <h1 className='text-center'>{t('login.welcome')}<br /><br/></h1>
                            </div>
                        </div>
                </div>
                <LoginButtons loadLogin={loadLogin} login={connected}/>
            </section>
            <EmailModal show={showEmailModal} setShow={setShowEmailModal} onSubmit={(email) => validateEmail(email)} />
            <PinModal
                show={showPINModal}
                error={errorPINModal}
                setShow={setShowPINModal}
                onSubmit={(pin) => signUp(myEmail, userWallet, loginType, pin)}
                resend={() => validateEmail(myEmail)}
            />
            <ApiKeyModal apiKey={myApiKey} email={myEmail} show={showApiKeyModal} setShow={setShowApiKeyModal} onSubmit={() => signIn(userWallet, myNonce)} />
            <TorusModal show={showTorusModal} setShow={setShowTorusModal} />
            {errorSignup && (
                <Notification
                    title={t('login.errorSignup')}
                    show={errorSignup}
                    close={() => setErrorSignup(false)}
                    severity="error"
                />
            )}
        </>
    );
}

export default Login;
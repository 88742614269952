import {setPriceShowModal, setSupplyShowModal} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {Modal, Spinner} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../app/hooks";
import AnalyticsService from "../../../services/analytics.service";
import {setNewPriceAction} from "../../../actions/smartContractDetails/setNewPriceAction";
import {useState} from "react";
import {useSelector} from "react-redux";
import {setNewSupplyAction} from "../../../actions/smartContractDetails/setNewSupplyAction";
import {selectSCSupply} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";

function SCDetailsSupplyModal() {

    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const {smartContractId} = useParams<{ smartContractId: string }>();
    const supply = useSelector(selectSCSupply(smartContractId!));

    const [newSupply, setNewPrice] = useState<number>(0);

    const submitSupply = (event: any) => {
        AnalyticsService.click("SDC:(BC) Set Supply");
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true && smartContractId) {
            dispatch(setNewSupplyAction({smartContractId, value: newSupply}));
        }
    }

    return (
        <Modal
            className='modal-container'
            show={supply?.showModal}
            onHide={() => smartContractId && dispatch(setSupplyShowModal({smartContractId, show: false}))}
            backdrop="static"
            keyboard={true}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton >
                <Modal.Title className="col-11" >
                    <h3 className="text-center">
                        {t('scdetails.set-new')}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form validated={supply?.isValidated} onSubmit={submitSupply}>
                    <Form.Group>
                        <Form.Control onChange={(event: any) => setNewPrice(event.target?.value)} type="number" min='0' step="1" pattern="[0-9]*" placeholder="Supply..." required />
                        {supply?.loading && 
                        <div className="honeycomb">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        </div>}
                        {!supply?.loading && <button className='btn-main' type="submit">{t('scdetails.Set-Supply')}</button>}
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default SCDetailsSupplyModal;
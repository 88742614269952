import { useFieldArray, useFormContext } from 'react-hook-form';
import { NFTsFormValues } from '../../models/nfts';

function useNFTsFormField() {
  const { control, register, getValues, setValue } = useFormContext<NFTsFormValues>();

  const { fields, append, remove } = useFieldArray<NFTsFormValues>({
    control,
    name: 'nfts'
  });

  const addNewNFT = (name: string, id: number, nameImage: string, file: any) => {
    append({
      name,
      id,
      nameImage,
      file,
      attributes : [ {key : "", value : ""}]
    });
  };

  const addAttribute = (nftIndex: number) => () => {
    setValue(`nfts.${nftIndex}.attributes`, getValues(`nfts.${nftIndex}.attributes`)?.concat({key : "", value : ""}));
  };

  const removeAttribute = (nftIndex: number, attrIndex : number) => () => {
    const attrList = getValues(`nfts.${nftIndex}.attributes`)?.slice(attrIndex+1, getValues(`nfts.${nftIndex}.attributes`)?.length);
    setValue(`nfts.${nftIndex}.attributes`, getValues(`nfts.${nftIndex}.attributes`)?.slice(0, attrIndex).concat(attrList ? attrList : []) );
  };

  const removeNFT = (nftIndex: number) => () => {
    remove(nftIndex);
  };

  return {
    fields,
    register,
    getValues,
    addNewNFT,
    removeNFT,
    addAttribute,
    removeAttribute
  };
}

export default useNFTsFormField;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BlockchainInfo } from "../models/blockchains";
import { useAppDispatch } from "../app/hooks";
import { UserInfo, WalletsUser, selectUserInfo, setAddWallet } from "../features/user/user";
import { useSelector } from "react-redux";
import LoginService from "../services/login.service";


export type BlockchainsProps = {
    blockchainchosen?: any,
    blockchainsList: any[],
    cols: string,
    polygonMain?: boolean;
    setCasperWallet?: (wallet: string) => void,
}

function Blockchains({ blockchainchosen, blockchainsList, cols, setCasperWallet, polygonMain }: BlockchainsProps) {

    // const { t } = useTranslation('common');
    const [myBCs, setBCs] = useState<BlockchainInfo[]>([]);
    const polygon = myBCs.find(bc => bc.name === 'Polygon');
    const userInfo: UserInfo = useSelector(selectUserInfo);
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const loginService = new LoginService();
    
    const selectBlockchain = async (blockchain: any) => {
        if (blockchain.isCasper && setCasperWallet) {
            try {
                const CasperWalletProvider = (window as any).CasperWalletProvider;
                const provider = CasperWalletProvider();
                await provider.requestConnection();
                if (await provider.isConnected()) {
                    const publicKeyHex = await provider.getActivePublicKey();
                    setCasperWallet(publicKeyHex);
                    if(!userInfo.wallets?.some( wall => wall.wallet === publicKeyHex)){
                        const auth : WalletsUser = {wallet : publicKeyHex, loginType : 'casper', selected : true };
                        loginService.addWalletToUser(auth);
                        dispatch(setAddWallet(auth));
                    }
                    // //console.log(publicKeyHex);
                }

            } catch (error) {
                throw new Error('Casper Wallet not found')
            }
        }
        blockchain.selected = !blockchain.selected;
        blockchainchosen(process.env.REACT_APP_ENV_IS_TESTNET! === 'true' ? blockchain.id_testnet : blockchain.id);

        myBCs.forEach(item => item.selected = false);
        myBCs.forEach(item => {
            if (item === blockchain) {
                item.selected = true;
            }
        });
        setBCs(myBCs);
    }

    useEffect(() => {
        setBCs(blockchainsList);
    }, [blockchainsList]);

    return (
        <>
            {polygonMain && (
            <>
            <div className='row d-flex justify-content-center'>
                <div className='col-10' onClick={() => selectBlockchain(polygon)}>
                    <Link className={polygon?.selected ? "icon-box style-selected rounded" : "icon-box style-2 rounded"} to="">
                        <img className="img-blockchain img-bc-conf" style={{maxWidth : '80%'}} src={polygon?.img} alt={polygon?.name} />
                    </Link>
                </div>
            </div>
            <h4 className="text-center pt-5">{t('forms.blockchains.others')}</h4>
            </>
            )}
            <div className='row d-flex justify-content-center'>
                {myBCs && myBCs.filter(bc => bc.name !== 'Polygon' || !polygonMain).map((item: any, index: number) => (
                    <>
                        {false ? (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">Coming soon!</Tooltip>}
                            >
                                <div key={index} className='col-10'>
                                    <div className={item.selected ? "icon-box style-selected rounded" : "icon-box style-2 rounded"}>
                                        <img className="img-blockchain img-bc-conf" src={item.img} alt={item.name} />
                                        {item.comingSoon && (<span>{t('forms.blockchains.coming')}</span>)}
                                    </div>
                                    <br/>
                                <br/>
                                </div>
                                
                            </OverlayTrigger>
                        ) : (
                            <div key={index} className={item.isCasper ? 'col-10' : cols} onClick={() => selectBlockchain(item)}>
                                <Link className={item.selected ? "icon-box style-selected rounded" : "icon-box style-2 rounded"} to="">
                                    <img className="img-blockchain img-bc-conf" style={{maxWidth : '80%'}} src={item.img} alt={item.name} />
                                    {item.comingSoon && (<span>{t('forms.blockchains.coming')}</span>)}
                                </Link>
                                {item.isCasper && <><br/><br/></>}
                            </div>
                        )}
                    </> 
                ))}
            </div>
        </>
    )
};
export default Blockchains;
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    createSmartContractDetails,
    modifySCDetails,
    SCValueType,
    setBalance,
    setSCConfig,
    setSCError,
    setSCSingleValue,
    showNFTsMinted,
    SmartContractDetail
} from "../../features/smartContractDetail/smartContractDetailsReducer";
import { useAppDispatch } from "../../app/hooks";
import SmartContractService, { TransactionMTM } from "../../services/smartContract.service";
import AnalyticsService from "../../services/analytics.service";
import { SMART_CONTRACT_TYPE, SmartContract } from "../../models/smartContract";
import { BigNumber, ethers } from "ethers";
import { STATUS_IN_BC } from "../../models/blockchains";
import initializeSmartContractDetails from "../../features/smartContractDetail/smartContractInitialize";
import {
    getConfigFromSmartContract,
    initializeContract
} from "../../utils/blockchainUtils";
import NFTService from "../../services/nft.service";
import {
    selectSmartContractDetails,
    selectSmartContractDetailsId
} from "../../features/smartContractDetail/smartContractDetailSelectors";
import { selectDashboardSignedSCs } from "../../features/dashboard/dashboardSelectors";
import SmartContractDetails from "./SmartContractDetails";

export default function useSmartContractDetails(smartContractId: string) {

    const dispatch = useAppDispatch();
    const smartContractDetails = useSelector(selectSmartContractDetails(smartContractId)) || initializeSmartContractDetails(smartContractId);
    const smartContractDetailsId = useSelector(selectSmartContractDetailsId(smartContractId));


    const smartContractsSigned = useSelector(selectDashboardSignedSCs);
    const [isDeployed, setDeployed] = useState(smartContractsSigned.some((smartContract: SmartContract) => smartContract._id === smartContractId));
    const [isDeployedRecently, setDeployedRecently] = useState(false);
    if (!isDeployed && smartContractsSigned.some((smartContract: SmartContract) => smartContract._id === smartContractId)) {
        setDeployed(true);
        setDeployedRecently(true);
    }

    useEffect(() => {
        if (!smartContractDetailsId) {
            dispatch(createSmartContractDetails({ ...smartContractDetails, smartContractId }));
        }
        const calculateStateBlockchain = async (smartContract: SmartContract) => {

            const {config, values} = await SmartContractService.getInstance().getFeatures(smartContract._id);
            //TO DO Control smart contract type and functions called
            const [balance, price, supply] = values;
            try {
                dispatch(setBalance({
                    smartContractId,
                    // status: STATUS_IN_BC.MINTED,
                    balance: Number(ethers.utils.formatEther(balance))
                }));
            } catch (error) {

            }

            try {
                dispatch(setSCSingleValue({
                    smartContractId,
                    scValueKey: SCValueType.price,
                    value: Number(ethers.utils.formatEther(price))
                }));
            } catch (error) {

            }

            try {
                dispatch(setSCSingleValue({
                    smartContractId,
                    scValueKey: SCValueType.supply,
                    value: BigNumber.from(supply).toNumber()
                }));
            } catch (error) {

            }

            const newConfig: SmartContractDetail = {
                smartContractId,
                config: getConfigFromSmartContract(smartContract, config)
            };

            dispatch(setSCConfig(newConfig));
        }
        const getData = async (smartContractId: string) => {
            try {
                await NFTService.getInstance().nftsPendingMint(smartContractId);
                const data = await SmartContractService.getInstance().smartContractDetails(smartContractId);

                if (!data) {
                    dispatch(setSCError({ smartContractId, error: `SCD(BC): Contract not found` }));
                    return;
                }

                if (data.smartContract?.status !== STATUS_IN_BC.MINTED) {
                    // const compiled = await SmartContractService.getInstance().smartContractsCompiled(smartContractId);
                    // data.smartContract.smartContractCompiled = compiled?.find( (sc : any) => sc.id === smartContractId )?.compiled ;
                }

                const payload: SmartContractDetail = {
                    smartContractId,
                    smartContract: data?.smartContract,
                    nfts: data?.nfts.filter((nft: any) => !nft.evolved),
                    originalNFTs: data?.nfts.filter((nft: any) => !nft.evolved),
                    loadingMinting: false,
                    nftsPending: data?.nfts.filter((nft: any) => (nft.status === STATUS_IN_BC.PENDING || nft.status === STATUS_IN_BC.FAILED) && !nft.evolved),
                    loading: false,
                    status: data?.smartContract?.status,
                    transactions : data?.transactions
                }
                if (data?.smartContract?.banner) {
                    payload.banner = data.smartContract.banner;
                }
                dispatch(modifySCDetails(payload));
                if (data && data.smartContract && data.smartContract.smartContractAddress && data.smartContract.blockchainId && !smartContractDetailsId) {
                    calculateStateBlockchain(data.smartContract);
                }
                if (isDeployedRecently) {
                    setDeployedRecently(false);
                    calculateStateBlockchain(data.smartContract);
                }


            } catch (error) {
                AnalyticsService.error(`SCD(BC): ${error}`);
                dispatch(setSCError({ smartContractId, error: `SCD(BC): ${error}` }));
            }
        }
        if (smartContractDetails.nftsMintedMessage !== '') {
            dispatch(showNFTsMinted({ smartContractId, showNFTsMinted: true }))
        }
        getData(smartContractId);
    }, [smartContractId, smartContractDetails.loadingMinting, smartContractDetails.nftsMintedMessage, isDeployedRecently]);

    return [smartContractDetails];
}
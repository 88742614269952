import {
    mintMessage,
    setSCError, setSCLoading,
    SmartContractDetail
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import AnalyticsService from "../../../services/analytics.service";
import {createAsyncThunk} from "@reduxjs/toolkit";
import SmartContractService from "../../../services/smartContract.service";
import BlockchainConnector from '../../../services/blockchain/BlockchainConnector';
import { addSignedContract } from "../../../features/dashboard/dashboardReducer";

export const signSmartContractAction = createAsyncThunk('SC/deploySC', async ({smartContractId}: MintPayload, thunkAPI) => {

    AnalyticsService.click("Dashboard: Sign SmartContract");
    const smartContractDetails: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    ////console.log("SIGN ACTION");
    const compiled = await SmartContractService.getInstance().smartContractsCompiled(smartContractId);
    const smartContractCompiled = compiled?.find( (sc : any) => sc.id === smartContractId )?.compiled ;

    if (!smartContractDetails.smartContract || !smartContractDetails.smartContract.blockchainId || 
        !smartContractDetails.smartContract._id || !smartContractCompiled ) {
        return;
    }
    try {
        thunkAPI.dispatch(setSCLoading({smartContractId: smartContractId!, loading: true}));
        const chain = parseInt(smartContractDetails.smartContract.blockchainId, 10);
        
        const blockchainConnector = new BlockchainConnector(chain);
        await blockchainConnector.deploySmartContract(smartContractCompiled, smartContractId, smartContractDetails.smartContract?.name);

        thunkAPI.dispatch(addSignedContract(smartContractDetails.smartContract));

        SmartContractService.getInstance().deploySmartContract(smartContractId);
        thunkAPI.dispatch(mintMessage({smartContractId: smartContractId!, nftsMintedMessage: 'Smart Contract Deployed'}));

    } catch(error: any) {
        AnalyticsService.error(error);
        thunkAPI.dispatch(setSCError({smartContractId: smartContractId!, error: 'Transaction Failed, please, contact with support'}));
        thunkAPI.dispatch(setSCLoading({smartContractId: smartContractId!, loading: false}));
    }
});

interface MintPayload {
    smartContractId: string;
}

import {
    setSCError
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../app/hooks";
import {useSelector} from "react-redux";
import {selectSCError} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";
import { Alert, Snackbar } from "@mui/material";


function SCDetailsErrorNotification() {

    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const {smartContractId} = useParams<{ smartContractId: string }>();
    const [error, showError] = useSelector(selectSCError(smartContractId!));
    const close = () => dispatch(setSCError({smartContractId: smartContractId!, showError: false, error: ''}));

    return (
        <Snackbar
            className='modal-container'
            open={!!showError}
            onClose={close}
            aria-labelledby="contained-modal-title-vcenter"
            autoHideDuration={6000}>
            <Alert severity="error" onClose={close}>
                {error}
            </Alert>
        </Snackbar>

    )
}


export default SCDetailsErrorNotification;
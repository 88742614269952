import {useRef, useState} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import GenerateNFTs from "../../components/GenerateNFTs";
import NFTsList from "../../components/NFTsList";
import {BLOCKCHAIN, STATUS_IN_BC} from "../../models/blockchains";
import {SmartContract} from "../../models/smartContract";
import "./smartContractDetails.scss";
import AnalyticsService from "../../services/analytics.service";
import {calculateBlockchain, calculateScan, initializeContract} from "../../utils/blockchainUtils";
import useSmartContractDetails from "./useSmartContractDetails";
import {useAppDispatch} from "../../app/hooks";
import {
    FilterNFT,
    RESULT_TRANSACTION,
    TYPE_TRANSACTION,
    Transaction,
    mintOnlyMessage,
    modifySCDetails,
    setBalance,
    setSCError,
} from "../../features/smartContractDetail/smartContractDetailsReducer";
import SCNFTsFilter from "./components/SCNFTsFilter";
import SCDetailsConfig from "./components/SCDetailsConfig";
import SCDetailsSummary from "./components/SCDetailsSummary";
import SCDetailsPriceModal from "./components/SCDetailsPriceModal";
import SCDetailsSupplyModal from "./components/SCDetailsSupplyModal";
import SCDetailsErrorModal from "./components/SCDetailsErrorModal";
import SCDetailsMintingModal from "./components/SCDetailsMintingModal";
import SCDetailsMintedModal from "./components/SCDetailsMintedModal";
import { useTranslation } from "react-i18next";
import SCDetailsPayDeployModal from "./components/SCDetailsPayDeploy";
import { useSelector } from "react-redux";
import { selectIsWeb3Contract, selectWallets } from "../../features/user/user";
import MintButton from "../../components/NFTCard/MintButton";
import { Breadcrumbs, Link } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { TRANSACTION_TYPE } from "../../models/payment";

function SmartContractDetails() {

    const { t } = useTranslation('common');

    const { smartContractId } = useParams<{ smartContractId: string }>();
    const dispatch: any = useAppDispatch();
    const navigate = useNavigate();

    const setShowGenerateNFTs = (showGenerateNFTs: boolean ) => dispatch(modifySCDetails({smartContractId: smartContractId!, showGenerateNFTs})); //TODO: Refactor later

    const [evolveNFT, setEvolveNFT] = useState(false);
    const [idToEvolve, setIdToEvolve] = useState(-1); //TODO: Should be part of child component
    const [modalSign, setModalSign] = useState(false);
    const [modalTransactions, setModalTransactions] = useState(false);
    const tableRef = useRef(null);
    

    const generateErrorData = async ( msgAnalytics : string, msgUser : string) => {
        AnalyticsService.error(msgAnalytics);
        dispatch(setSCError({smartContractId: smartContractId!, error: msgUser}));
    }

    const smartContractWithNFTsPending = async (smartContract: SmartContract) => {
        ////console.log("METER SPINNER o algo");
    }

    const getTypeTransaction = ( type : TYPE_TRANSACTION) => {
        return type === TYPE_TRANSACTION.DEPLOY_CONTRACT ? 'Smart Contract Deploy' : type === TYPE_TRANSACTION.MINT_NFT ? 'Mint NFT' : type === TYPE_TRANSACTION.BURN_NFT ? 'Burn NFT' : 'Evolve NFT'; 
    }

    const releaseBalance = async () => {
        if (smartContractDetails?.smartContract?.smartContractAddress && smartContractDetails?.smartContract.blockchainId) {
            const contract = await initializeContract(smartContractDetails?.smartContract.smartContractAddress, smartContractDetails?.smartContract.blockchainId);
            //TODO CONTROL SC TYPE
            contract.releaseAll({gasLimit: 16721970}).then((response: any) => {
                response.wait(1).then((resp: any) => {
                    AnalyticsService.transaction("SDC:(BC) Release Balance");
                    dispatch(setBalance({smartContractId : smartContractId!,balance : 0}));
                }).catch((error: any) => {
                    generateErrorData( "SDC:(BC) Release Balance(" + error.reason + ")" , "Blockchain error -> " + error.reason);
                })
            }).catch((error: any) => {
                generateErrorData( "SDC:(BC) Release Balance(" + error.reason + ")" , "Blockchain error -> " + error.reason);
            });
        }
    }
    
    const [smartContractDetails] = useSmartContractDetails(smartContractId ?? '');
    const userWallets = useSelector(selectWallets);
    const userCanDeploy = useSelector(selectIsWeb3Contract(smartContractId!));

    return (
        <div className="d-flex flex-column" style={{width : '100%'}}>
            <section className='container d_coll no-bottom' >
                <div className='row mt-1 '>
                    {/* <a onClick={ () => {navigate('/dashboard'); AnalyticsService.click("SCD: Back to Dahsboard")}} className="btn-subscribe d-flex justify-content-start">
                        <h4><i className="arrow_left"></i>&nbsp;{t('scdetails.Dashboard')}</h4>
                    </a> */}
                    <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link className="pointer" underline="hover" color="inherit" onClick={ () => {navigate('/dashboard'); AnalyticsService.click("SCD: Back to Dahsboard")}} >
                        <HomeIcon sx={{ mr: 0.5, mb: 0.3 }} fontSize="inherit" />
                        Dashboard
                    </Link>
                    <Link underline="hover" color="inherit" >
                        {smartContractDetails.smartContract?.name}
                    </Link>
                    {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
                    </Breadcrumbs>
                    
                </div>
                {smartContractId && smartContractDetails && smartContractDetails.smartContract && smartContractDetails.smartContract.blockchain &&  (
                    <>
                    <div className='row no-top'>
                        <div className="col-lg-4 col-md-12 col-sm-12 mt-5">
                            <div className="d_profile">
                                <div className="profile_avatar profile_details">
                                    <div className="row d_profile_img">
                                        <div className="col-lg-6 col-sm-12">
                                            <img className="" src={smartContractDetails.smartContract.img ? smartContractDetails.smartContract.img : smartContractDetails.smartContract.standard === 'ERC-721' ? "/img/dashboard/card-721.png" : "/img/dashboard/card-1155.png"} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-sm-12  d-flex justify-content-center">
                                            <div className="row justify-content-center">
                                                <div className="col-sm-6 col-md-6 col-lg-12">
                                                    {smartContractDetails.status === STATUS_IN_BC.MINTED && (
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip id="button-tooltip-2">{t('scdetails.scanner')}</Tooltip>}
                                                            >
                                                            <img className="img-click"
                                                                onClick={ () => window.open(calculateScan(
                                                                    smartContractDetails?.smartContract?.blockchain ? smartContractDetails.smartContract.blockchain : 0,
                                                                    smartContractDetails?.smartContract?.smartContractAddress ? smartContractDetails.smartContract.smartContractAddress : ''))}
                                                                src={calculateBlockchain(smartContractDetails.smartContract.blockchain ? smartContractDetails.smartContract.blockchain : 0)} alt="" />
                                                        </OverlayTrigger>
                                                    )}
                                                    
                                                    {/* <i className="fa fa-check"></i> */}
                                                    {!smartContractDetails.loading && (smartContractDetails.smartContract.includeDeploy && userCanDeploy) && (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id="button-tooltip-2">
                                                        {smartContractDetails.smartContract.includeDeploy  ?  t('scdetails.includeDeploy') : smartContractDetails.smartContract.includeDeploy && !smartContractDetails.smartContract.paid ? t('scdetails.deploy') : t('scdetails.noPaid') }</Tooltip>}
                                                    >
                                                        <button className={smartContractDetails.status !== STATUS_IN_BC.SIGNED && smartContractDetails.status !== STATUS_IN_BC.MINTED 
                                                            ? "active btn-main-2" : "hide btn-main-2" }
                                                            onClick={() => setModalSign(true)}>Sign SmartContract
                                                        </button>
                                                    </OverlayTrigger>)}
                                                    
                                                    {smartContractDetails.loading && (
                                                        <div className="honeycomb">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        </div>
                                                    )}
                                                </div>
                                                {userWallets?.length > 1 && userCanDeploy && 
                                                        <div className="col-md-6 col-sm-6 col-lg-12">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={<Tooltip id="button-tooltip-2">{smartContractDetails.smartContract.publicKeyCasper ? smartContractDetails.smartContract.publicKeyCasper :  smartContractDetails.smartContract.walletOwner}</Tooltip>}
                                                            >
                                                                <img className="metamask-logo" src={smartContractDetails.smartContract.publicKeyCasper ? "/img/blockchains/white/casper.png" : "/img/wallet/1.png"} alt="metalogo"/>
                                                            </OverlayTrigger>
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 profile_name">
                                        <h4>
                                            {smartContractDetails.smartContract.name}
                                            <div className="clearfix"></div>
                                            <span id="wallet" className="profile_wallet">{smartContractDetails.smartContract.description}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {smartContractId && <SCDetailsConfig smartContractId={smartContractId} releaseBalance={releaseBalance}/>}
                    </div>
                    {(smartContractDetails.smartContract?.smartContractAddress && smartContractDetails.smartContract?.blockchain
                        && ![BLOCKCHAIN.CASPER, BLOCKCHAIN.CASPER_TESTNET].includes(smartContractDetails.smartContract?.blockchain)) &&
                     <SCDetailsSummary smartContractId={smartContractId} />
                     }
                    </>
                )}
                {smartContractDetails && !smartContractDetails.loadingMinting && smartContractId && (
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                            <SCNFTsFilter smartContractId={smartContractId} />
                            
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                            <div className="items_filter d-flex justify-content-center">
                                <ul className="de_nav">
                                    
                                {!smartContractDetails.loading && smartContractDetails.smartContract?.blockchain
                                        && ![BLOCKCHAIN.CASPER, BLOCKCHAIN.CASPER_TESTNET].includes(smartContractDetails.smartContract?.blockchain) 
                                        && smartContractDetails.filter !== FilterNFT.MINTED && smartContractDetails.nftsPending?.length
                                        && !smartContractDetails.nfts?.filter(nft => nft.isEvolution && nft.status !== STATUS_IN_BC.MINTED)?.length && userCanDeploy && <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">{t('scdetails.Sign-NFTs')}</Tooltip>}
                                >
                                    <li><MintButton smartContractId={smartContractId}/></li>
                                </OverlayTrigger>}       
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">{t('scdetails.CreateNFTs')}</Tooltip>}
                                >
                                
                                    <li className={"active btn-main"} onClick={() => {
                                        AnalyticsService.click("SDC: Generate NFTs");
                                        setShowGenerateNFTs(true)}}>{t('scdetails.Create-NFTs')}</li>
                                </OverlayTrigger>
                                {smartContractDetails.transactions?.length && (<OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{'Transactions'}</Tooltip>}
                                    >
                                    
                                        <li className={"active btn-main"} onClick={() => {
                                            setModalTransactions(true)}}>{'Transactions'}</li>
                                    </OverlayTrigger>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </section>
            {smartContractDetails.loadingMinting && (
                <section className='container' style={{minHeight : '1000px'}} >
                    <div className="row m-5 ">
                        <div className="m-5 card-body d-flex justify-content-center">
                            <div className="honeycomb">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {smartContractId && smartContractDetails.nfts && smartContractDetails.nfts.length > 0 && !smartContractDetails.loadingMinting && (
                <section className='container no-bottom no-top ' style={{minHeight : '600px'}}>
                    <div className="row d-flex justify-content-center">
                        <NFTsList 
                            spinner={smartContractDetails.loading}
                            nfts={smartContractDetails.nfts}
                            setShowGenerateNFTs={setShowGenerateNFTs} 
                            evolveNFT={setEvolveNFT} 
                            burneable={smartContractDetails.config?.burnNFTs?.value}
                            evolveable={smartContractDetails?.config?.dynamicNFTs?.value}
                            setIdToEvolve={setIdToEvolve}
                            smartContract={smartContractDetails?.smartContract}
                            generateErrorData={generateErrorData}
                            setSpinnerMinting={(value: boolean) => dispatch(modifySCDetails({smartContractId, loadingMinting: value}))} //TODO: Refactor
                         />
                    </div>
                </section>
            )}
            {!smartContractDetails.loading && smartContractDetails.nfts && smartContractDetails.nfts.length === 0 && (
                <section className='container'>
                    <div className="d-flex justify-content-center">
                        <h1 className="text-center">{t('scdetails.noNFTs')}</h1>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </section>
            )}
            {smartContractId && smartContractDetails?.smartContract && smartContractDetails.showGenerateNFTs && (
                <GenerateNFTs 
                    show={smartContractDetails.showGenerateNFTs}
                    setShow={setShowGenerateNFTs} 
                    smartContract={smartContractDetails.smartContract}
                    smartContractWithNFTsPending={smartContractWithNFTsPending} 
                    setShowMint={(value: boolean) => dispatch(modifySCDetails({smartContractId, showMint: value}))} //TODO: refactor
                    setSpinnerMinting={(value: boolean) => {dispatch(modifySCDetails({smartContractId, loadingMinting: value}))}} //TODO: refactor
                    supplyBC={smartContractDetails.supply?.value}
                    evolveNFT={evolveNFT}
                    setEvolveNFT={setEvolveNFT}
                    setNFTsMinted={() => dispatch(mintOnlyMessage({nftsMintedMessage: 'NFTs Evolved', smartContractId}))}
                    idToEvolve={idToEvolve}
                    scDeployed={smartContractDetails.status!}
                />
            )}
            {smartContractId && smartContractDetails.showNFTsMinted && (<SCDetailsMintedModal />)}
            {smartContractId && smartContractDetails.error !== '' && smartContractDetails.showError && (<SCDetailsErrorModal />)}
            {smartContractId && smartContractDetails.showMint && (<SCDetailsMintingModal />)}
            {smartContractDetails.price?.showModal && (<SCDetailsPriceModal />)}
            {smartContractDetails.supply?.showModal && (<SCDetailsSupplyModal />)}
            {smartContractDetails.smartContract?.name && modalSign && (
                <SCDetailsPayDeployModal
                    setShow={setModalSign}
                    smartContractDetails={smartContractDetails}/>
            )}
            <Modal
                    className='modal-container modal-table'
                    show={modalTransactions}
                    onHide={() => setModalTransactions(false)}
                    keyboard={true}
                    size="lg"
                    centered>
                    <Modal.Header closeButton >
                        <Modal.Title className="col-11" >
                            <h3 className="text-center">
                                Transacciones
                            </h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {smartContractDetails?.transactions && (
                        <table className="table table-striped align-middle" ref={tableRef}>
                            <thead className="sticky-top">
                                <tr className="text-light-emphasis">
                                    <th scope="col">Hash</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Success/Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {smartContractDetails?.transactions.map((transaction: Transaction, index: number) => (
                                    <tr key={index}>
                                        <td className="" style={{maxWidth : '300px'}}>
                                            <div className="row">
                                                <div className="col">
                                            <p>{` ${transaction.hash?.slice(0, 10)}. . .${transaction.hash?.slice(-10)}`}</p>
                                                </div>
                                                <div className="col">
                                            <i style={{cursor: 'pointer'}} className="bi bi-clipboard-plus" data-toggle="tooltip" data-placement="top" title={transaction.hash} onClick={ () => {navigator.clipboard.writeText(transaction.hash);}}></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="">
                                            <p>{transaction.priceTransaction ? (transaction.priceTransaction / 1_000_000_000)?.toFixed(3)  : 'error'}&nbsp; CSPR</p>
                                        </td>
                                        <td className="">
                                            <p>{getTypeTransaction(transaction.type)}</p>
                                        </td>
                                        <td className="">
                                            <p>{transaction.typeResult === RESULT_TRANSACTION.SUCCESS ? 'Success': 'Error'}</p>
                                        </td>
                                        
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>)}
                    </Modal.Body>
                </Modal>
        </div>
    )
}

export default SmartContractDetails;

import { useEffect, useState } from "react";
import { useSearchParams, redirect } from "react-router-dom";
import NFTService from "../../services/nft.service";
import LoginButtons from "../Login/LoginButtons";
import { useTranslation } from "react-i18next";
import { NFTView } from "../../models/nfts";
import { SmartContract } from "../../models/smartContract";
import './MintByEmail.scss';
import { MetamaskBlockchainListAvailable } from "../../services/blockchain/BlockchainsData";

function MintByEmail() {

    const { t } = useTranslation('common');

    const [searchParams, _] = useSearchParams();
    const token = searchParams.get('token')!;
    const [smartContract, setSmartContract] = useState<SmartContract>();
    const [nft, setNFT] = useState<NFTView>();
    const [claimed, setClaimed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [blockchainName, setBlockchainName] = useState<string>();

    useEffect(() => {
        const getInfo = async () => {
            if (!token) {
                redirect('/');
                return;
            }
            try {
                const { smartContract, nft } = await NFTService.getInstance().getClaimInfo(token);
                setSmartContract(smartContract);
                setNFT(nft);
                if (nft.wallet && nft.wallet !== process.env.REACT_APP_ADDRESS_MINT4ALL) {
                    setClaimed(true);
                }
                setLoading(false);
                const name = MetamaskBlockchainListAvailable.find(blockchain => blockchain.id === smartContract.blockchain)?.name;
                setBlockchainName(name?.toLocaleLowerCase());
            } catch (e) {
                redirect('/');
            }
        }
        getInfo();
    }, []);

    const claim = async (wallet: string, userInfo: any) => {
        await NFTService.getInstance().claimNFT(token, wallet);
        setClaimed(true);
    };

    const isTesnet = process.env.REACT_APP_ENV_IS_TESTNET === 'true';
    const basicURL = isTesnet ? process.env.REACT_APP_OPENSEA_TESTNET_URL : process.env.REACT_APP_OPENSEA_URL;

    return (
        <section className="jumbotron breadcumb no-bg">
            <div className='mainbreadcumb mt-5 no-bottom'>
                <div className='row'>
                    <div className='col-lg-12 d-flex justify-content-center'>
                        <h1 className='text-center'>{t('login.claim.title')}<br /><br /></h1>
                    </div>
                </div>
            </div>
            <div className="claim-container row">
                {smartContract &&
                    <div className="text-center">
                        <div>Collection {smartContract.name}</div>
                        <div>{smartContract.description}</div>
                    </div>
                }
                {nft &&
                    <div className="claim-object nft">
                        <img src={nft.isImage || nft.contentType?.includes('image') ? nft.path : "/img/img-defecto.png"} />
                    </div>
                }
            </div>
            {!loading && (
                <>
                    {!claimed && <LoginButtons loadLogin={false} login={claim} />}
                    {claimed &&
                        <>
                            <div className="row claimed mb-5 ">
                                <div className="col-12 d-flex justify-content-center mt-2 mb-2">
                                    <div className="opensea">
                                        <img src="/img/login/check.png" />
                                        <div>You have claimed your NFT!</div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-2 mb-5">
                                    <a href={`${basicURL}/${blockchainName}/${smartContract?.smartContractAddress}/${nft?.idNFT}`}
                                        className="opensea"
                                        target="_blank">
                                        <img src="/img/login/opensea.png" />
                                        <div>Visualize it here</div>
                                    </a>
                                </div>
                            </div>
                        </>
                    }
                </>
            )}


        </section>
    );
}

export default MintByEmail;
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import './emailModal.scss';
import Form from 'react-bootstrap/Form';
import AnalyticsService from '../../../services/analytics.service';
import { useTranslation } from "react-i18next";

function EmailModal({ show, setShow, onSubmit }: EmailModalProps) {

  const [email, setEmail] = useState('');
  const [acceptedLegal, setAcceptedLegal] = useState(true);
  const analyticsService = new AnalyticsService();
  const { t } = useTranslation('common');
  const handleClose = () => setShow(false);
  const submitEmail = () => {
    if(email !== '' && acceptedLegal){
      analyticsService.modal('Submit email');
      onSubmit(email);
      handleClose();
    }
  };

  const legalConditions = (event : any) => {
    setAcceptedLegal(!acceptedLegal);
  }

  useEffect(() => {
    if (show) { analyticsService.modal('Form Email'); }
  }, [show]);


  return (
    <Modal
      className='modal-container'
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('login.emailmodal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label style={{color : 'white'}}> <span >Email address*</span></Form.Label>
        <Form.Control value={email} onChange={(event) => setEmail(event.target?.value)} type="text" placeholder="Email..." required />
        <Form.Check 
          checked={acceptedLegal}
          onChange={legalConditions}
          type='checkbox'
          id='checkbox'
          label=
            {(
              <div className='white-letter' style={{color : 'white'}}>{t('login.emailmodal.accept-legal-conditions')}&nbsp;
                <a className='white-letter' href={t('login.emailmodal.url-legal-conditions')} target="_blank" rel="noreferrer">{t('login.emailmodal.legal-conditions')}</a>
              </div>
            )}
        />
      </Modal.Body>
      <Modal.Footer>
        <button disabled={!acceptedLegal} className='btn-main' onClick={submitEmail}>{t('login.emailmodal.register')}</button>
      </Modal.Footer>
    </Modal>
  );
}

interface EmailModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  onSubmit: (email: string) => void;
}

export default EmailModal;
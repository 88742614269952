import { Modal } from "react-bootstrap";
import { PaymentData } from "../models/payment";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

export type ModalPaymentProps = {
    paymentData : PaymentData
}

function ModalPayment ( {paymentData} : ModalPaymentProps) {

    const [showModalPayment, setShowModalPayment] = useState<boolean>(true);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE ? process.env.REACT_APP_STRIPE : '');
    const { t } = useTranslation('common');
    useEffect(() => {
        //console.log("STRIPE: ", process.env.REACT_APP_STRIPE);
        //console.log("Payment data ", paymentData);
    }, [paymentData])

    return (
        <Modal
            className='modal-container'
            show={showModalPayment}
            onHide={ () => {setShowModalPayment(false)} }
            backdrop="static"
            keyboard={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header className='d-flex justify-content-center'>
                <h2>Add your payment method</h2>
            </Modal.Header>
            <Modal.Body className="row mt-2 mb-2  d-flex justify-content-center" >
                <div className="col-lg-12 row d-flex justify-content-center mb-2">
                    <div className="col-12 d-flex justify-content-center "><h3>Amount: {paymentData.amount}</h3><br/></div>
                    {paymentData?.isForValidation && (
                        <div className="col-12 d-flex justify-content-center">
                            <p className="text-center">Your card has been saved for charge the next actions (Create contracts, nfts, etc).
                                <br/><a className='white-letter' href={t('login.emailmodal.url-legal-conditions')} target="_blank" rel="noreferrer">{t('login.emailmodal.legal-conditions')}</a>
                            </p>
                        </div>
                    )}
                </div>
                <div className="col-lg-12 d-flex justify-content-center mb-2">
                    {paymentData?.clientSecret && (
                    <Elements options={{ clientSecret:  paymentData?.clientSecret, appearance: { theme: "night" , variables : { borderRadius : '20px' , colorLogo : '#D93A2B' } } }} stripe={stripePromise}>
                        <CheckoutForm 
                            paymentData={paymentData}
                            setModalPayment={setShowModalPayment}
                        />
                    </Elements>)}
                </div>
            </Modal.Body>
                
        </Modal>
    )
}

export default ModalPayment;
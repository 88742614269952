import {mintMessage, modifySCDetails} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {Modal} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../app/hooks";
import {useSelector} from "react-redux";
import {selectSCisMinted} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";
import { Alert, Snackbar } from "@mui/material";

function SCDetailsMintedNotification() {

    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const {smartContractId} = useParams<{ smartContractId: string }>();
    const [showNFTsMinted, nftsMintedMessage] = useSelector(selectSCisMinted(smartContractId!));
    const close = ()=> {
        dispatch(modifySCDetails({smartContractId: smartContractId!, showNFTsMinted: false}));
        dispatch(mintMessage({smartContractId: smartContractId!, nftsMintedMessage : ''}));
    }
    
    return (
        <Snackbar
            className='modal-container-nfts'
            open={!!showNFTsMinted}
            onClose={close}
            aria-labelledby="contained-modal-title-vcenter"
            autoHideDuration={6000}>
            <Alert severity="success" onClose={close}>
                {nftsMintedMessage}
            </Alert>
        </Snackbar>
                
    )
}

export default SCDetailsMintedNotification;
import { useForm } from 'react-hook-form';
import { STATUS_IN_BC } from '../../models/blockchains';
import { MetadataNFTs, MintNFTSmartContract, NFT, NFTsFormValues } from '../../models/nfts';
import AnalyticsService from '../../services/analytics.service';
import FileService, { FileType } from '../../services/file.service';
import NFTService from '../../services/nft.service';
import { setNotification, setPaymentData } from '../../features/dashboard/dashboardReducer';
import { useAppDispatch } from '../../app/hooks';
import { addNFTs, updateNFT } from '../../features/smartContractDetail/smartContractDetailsReducer';

 
function useNFTsForm({idSmartContract, files, setSpinner, setNftsToSign, setPercent , evolveNFT, idToEvolve,setShow} : any) {

  const nftService = new NFTService();
  const fileService = new FileService();
  const analyticsService = new AnalyticsService();
  const dispatch = useAppDispatch();

  const methods = useForm<NFTsFormValues>({
    defaultValues: {
      idSmartContract : idSmartContract,
      nfts: [],
    },
  });

 
  const handleSubmit = async (values: NFTsFormValues) => {

    try {
      analyticsService.click("Submit Generate NFTs")
      setSpinner(true);
      const filesUploaded = Array.from(files).map((file: any) => { 
        return fileService.uploadFile(file, FileType.NFT_IMG);
      });

      const namesFilesAWS : any = await Promise.all(filesUploaded);

      const nfts : NFT[] = [];
      values.nfts.forEach( (nftForm, index) => {
        const indexNFT = nfts.findIndex((nft : NFT) => {
            if (nftForm.email) {
                return nft.email === nftForm.email;
            } else {
                const wallet = nftForm.wallet || nftForm.myWallet;
                // console.log("WALLET: ", wallet);
                return nft.userWallet === wallet;
            }
        });
        const nftNew : MetadataNFTs = {
          name: nftForm.name ? nftForm.name : '',
          imageName: namesFilesAWS[index],
          description : nftForm.description ? nftForm.description : '', 
          attributes : nftForm.attributes && nftForm.attributes?.length > 0 && nftForm.attributes[0]?.key != '' ? nftForm.attributes : []
        };
        if(!nftNew.imageName){
          throw new Error("File Error ");
        }
        if(evolveNFT && idToEvolve >= 0){
          nftNew.idToEvolve = idToEvolve;
        }
        if (indexNFT >= 0  && nfts[indexNFT].metadata) {
            nfts[indexNFT].metadata.push(nftNew);
        } else {
            const newOwner: NFT = {userWallet :  nftForm.wallet || nftForm.myWallet, metadata : [nftNew]};
            // console.log(" NEW OWNER : ", newOwner);
            if (nftForm.email) {
                delete newOwner.userWallet;
                newOwner.email = nftForm.email;
            }
            nfts.push(newOwner);
        }
  
      });

      let response : MintNFTSmartContract | undefined;
      if(evolveNFT && idToEvolve >= 0){
        response = await nftService.evolveNFT(values.idSmartContract , nfts); // esperar a respuesta y setShow(false); 
      }
      else{
        response = await nftService.generateNFT(values.idSmartContract , nfts);
      }
      
      
      // //console.log("response is : ", response);
      if(response){
        setSpinner(false);
        setPercent(90);
        const nfts = response.nftsCreated.map( (nft : NFT , index ) => { return {...nft, 
          json : response?.jsons ? response?.jsons[index] : '',
          path : response?.images ? response?.images[index] : '' }});
        
        setNftsToSign(response);

        if(evolveNFT){
          dispatch(updateNFT( {
            smartContractId : values.idSmartContract,
            nftsPending: nfts,
            nfts : nfts,
            idToEvolve
          }));
        }
        else{
          dispatch(addNFTs( {
            smartContractId : values.idSmartContract,
            nftsPending: nfts,
            nfts : nfts,
          }));
        }
        
        if(response.paymentData?.clientSecret){
          dispatch(setPaymentData(response.paymentData));
        }
        else if(response.minteableFree){
          dispatch(setNotification({title :  'NFTs created', description : 'Your nfts will be minted in the next 24 hours', paymentButton: false}));
          setShow(false);
        }
      }

      

    } catch (error) {
      console.error("ERROR: ", error);
      analyticsService.error("Generate NFTs:(API) ");
    }

    
  };
 
  return {
    methods,
    handleSubmit: methods.handleSubmit(handleSubmit),
  };
}
 
export default useNFTsForm;
import { ethers } from "ethers";
import { MetamaskBlockchainListAvailable, CasperBlockchainListAvailable } from "./BlockchainsData";
import CasperDeployer from "./CasperDeployer";
import MetamaskDeployer from "./MetamaskDeployer";
import { SmartContract } from "../../models/smartContract";
import { NFTView } from "../../models/nfts";

export default class BlockchainConnector {


    private blockchainDeployer: BlockchainDeployer;
    isCasper: boolean = false;

    constructor(chain: number) {

        const metamaskList = MetamaskBlockchainListAvailable;
        const metamaskBlockchain = metamaskList.find(bc => bc.chainId === chain);
        if(metamaskBlockchain){
            this.blockchainDeployer = new MetamaskDeployer(metamaskBlockchain);
            return;
        }
        const casperList = CasperBlockchainListAvailable;
        const casperBlokchain = casperList.find(bc => bc.chainId === chain);
        if (casperBlokchain) {
            this.blockchainDeployer = new CasperDeployer(casperBlokchain);
            this.isCasper = true;
        } else {
            throw new Error('Blockchain ID not found')
        }
    }


    async deploySmartContract(smartContractCompiled: any,smartContractId : string, smartContractName?: string): Promise<string> {
        await this.blockchainDeployer.getConnection();
        const smartContractAddress = await this.blockchainDeployer.execute(smartContractCompiled,smartContractId, smartContractName);
        return smartContractAddress;
    }

    async mintNFTs(smartContract: SmartContract, nftsToSign: any, nft? : NFTView) {
        await this.blockchainDeployer.getConnection();
        await this.blockchainDeployer.mint(smartContract, nftsToSign,nft);
    }

    async getProvider() {
        await this.blockchainDeployer.getConnection();
        return this.blockchainDeployer.getProvider();
    }

    async getCurrentAccount() {
        const provider = await this.getProvider();
        return await provider.send('eth_requestAccounts', []);
    }
}


export interface BlockchainDeployer {
    getConnection(): Promise<void>;
    execute(smartContractCompiled: any, smartContractId : string, smartContractName?: string) : Promise<string>;
    mint(smartContract: SmartContract, nftsToSign: any, nft? : NFTView): Promise<void>;
    getProvider(): ethers.providers.Web3Provider | any;
}

export async function isSelectedWallet(walletOwner: string, chainId: string): Promise<boolean> {
    const chain = parseInt(chainId, 10);
    const blockchainConnector = new BlockchainConnector(chain);
    if (blockchainConnector.isCasper) {
        return true;
    }
    const [currentWallet] = await blockchainConnector?.getCurrentAccount();
    return currentWallet.toLowerCase() === walletOwner.toLowerCase();
}
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NotificationAction, setNotification } from "../features/dashboard/dashboardReducer";
import { selectNotification } from "../features/dashboard/dashboardSelectors";
import { useAppDispatch } from "../app/hooks";
import { Alert } from "@mui/material";
import { useState } from "react";

function ModalNotification() {
    const dispatch = useAppDispatch();

    const notificationData = useSelector(selectNotification);
    const hideNotification = () => {
        dispatch(setNotification({
            title : '',
            description : '',
            errorText: undefined,
            descriptionDetails: '',
            actionButton: undefined,
            extraActionButton: undefined
        }));
    }

    const [showError, setShowError] = useState(false);


    const execute = async (notification: NotificationAction) => {
        const isValidAction = notification.validator === undefined || notification.validator && (await notification.validator());
        setShowError(!isValidAction);
        if (isValidAction) {
            notification.action();
            hideNotification();
        }
    }

    return (
        <Modal
            className='modal-container'
            show={notificationData.title !== ''}
            onHide={hideNotification}
            backdrop="static"
            keyboard={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title className="col-11" >
                    <h3 className="text-center">
                        {notificationData.title}
                    </h3>
                </Modal.Title>
                <button type="button" onClick={hideNotification} className="btn-close btn-close-white" aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body className="row mt-2 mb-2  d-flex justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center mb-2">
                <i className="bi bi-card-checklist icon-big"></i> <br/>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                    <p>{notificationData.description}</p>
                </div>
                {notificationData.descriptionDetails && (
                <div className="col-lg-8 d-flex justify-content-center">
                    <h4 className="text-center">{notificationData.descriptionDetails}</h4>
                </div>)}
                {showError &&
                    <Alert severity="error">
                        {notificationData.errorText}
                    </Alert>
                }
            </Modal.Body>
            {notificationData.paymentButton && (<Modal.Footer>
                <div className="row">
                    <div className="col-12">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                            <Tooltip id="button-tooltip-2">
                                Payment process is disabled.<br/> Contact with support
                            </Tooltip>}
                        >
                        <button onClick={() => {}} className="btn-main">Add Payment data</button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Modal.Footer>)}
            {notificationData.actionButton && (<Modal.Footer>
                <div className="row justify-content-between">
                    {notificationData.extraActionButton && (
                        <div className="col-5 mr-2">
                            <button onClick={() => execute(notificationData.extraActionButton!)}
                                className="btn-main">{notificationData.extraActionButton.text}</button>
                        </div>
                    )}
                    <div className="col-5">
                        <button onClick={() => execute(notificationData.actionButton!)}
                            className="btn-main">{notificationData.actionButton.text}</button>
                    </div>
                </div>

            </Modal.Footer>)}
        </Modal>
    );
}

export default ModalNotification;
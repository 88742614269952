import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import { STATUS_IN_BC } from "../models/blockchains";
import { SMART_CONTRACT_TYPE, SmartContract } from "../models/smartContract";
import AnalyticsService from "../services/analytics.service";
import NFTService from "../services/nft.service";
import NFTsFormField from "./forms/nftsFormField";
import useNFTForm from "./forms/useNftsForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../app/hooks";
import { setSCError } from "../features/smartContractDetail/smartContractDetailsReducer";
import { signSmartContractAction } from "../actions/smartContractDetails/metamask/signSmartContractAction";
import BlockchainConnectService from "../services/blockchainConnect.service";
import { mintNFTsAction } from "../actions/smartContractDetails/metamask/mintNFTsAction";
import { MintNFTSmartContract } from "../models/nfts";

type GenerateNFTsProps = {
    show: boolean;
    setShow: any;
    smartContract: SmartContract;
    smartContractWithNFTsPending: any;
    setNFTsMinted: any;
    setSpinnerMinting: any;
    setShowMint: any;
    setError?: any;
    showError?: any;
    scDeployed: STATUS_IN_BC;
    supplyBC?: ethers.BigNumber | number;
    evolveNFT?: boolean;
    setEvolveNFT: any;
    idToEvolve?: number;
    signSmartContract?: any; //TODO: REMOVE
}

function GenerateNFTs({ show, setShow, smartContract, smartContractWithNFTsPending, setNFTsMinted, setSpinnerMinting, setShowMint, scDeployed, supplyBC, evolveNFT = false, setEvolveNFT, idToEvolve }: GenerateNFTsProps) {

    const dispatch = useAppDispatch();
    const [smartContractId] = useState(smartContract._id);

    const [spinnerNFTs, setSpinner] = useState(false);
    const [images, setImages] = useState<string[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [nftsToSign, setNftsToSign] = useState<MintNFTSmartContract>();
    const [chooseFiles, setChooseFiles] = useState(false);
    const [percent, setPercent] = useState(50);
    const { handleSubmit, methods } = useNFTForm({ idSmartContract: smartContract._id, files, setSpinner, setNftsToSign, setPercent, evolveNFT, idToEvolve,setShow });
    const nftService = new NFTService();
    const analyticsService = new AnalyticsService();
    const { t } = useTranslation('common');

    const getPercentStep = () => {
        return percent;
    }

    const mintEvolveNFT = async () => {
        mintOrEvolveNFTs(true);
    }

    const mintOrEvolveNFTs = async (evolve: boolean) => {

        analyticsService.click(evolve ? "Evolve now" : "Mint Now");
        const overrides = {
            gasLimit: 50000,
        };

        try {

            if (nftsToSign && nftsToSign.ids && nftsToSign.uris ) {
                const chain = parseInt(nftsToSign.blockchainId, 10);

                await BlockchainConnectService.getConnection(chain);
                
                const smartContractABI = require(smartContract.smartContractType === 3 ? './../smartContracts/smartContractMinGeneric.json' : './../smartContracts/smartContractGeneric.json').abi;
                const provider = new ethers.providers.Web3Provider((window as any).ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(nftsToSign.smartContractAddress, smartContractABI, signer);
                let response;
                if (evolve) {
                    if(smartContract.smartContractType  === SMART_CONTRACT_TYPE.MTM_1155_MIN){
                        response = contract.evolveNFT(nftsToSign.ids[0], overrides);
                    }
                    else{
                        response = contract.evolveNFT(nftsToSign.ids[0], nftsToSign.uris[0], overrides);
                    }
                }
                else {
                    if(smartContract.smartContractType  === SMART_CONTRACT_TYPE.MTM_1155_MIN){
                        response = await contract.mintBatch(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.ids, overrides);
                    }
                    else{
                        response = await contract.mintNFTs(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.uris, overrides);
                    }
                    
                }

                response.then((response: any) => {
                    setSpinnerMinting(true);
                    setShowMint(true);
                    smartContractWithNFTsPending(smartContract);
                    response.wait(1).then((finalized: any) => {
                        setSpinnerMinting(false);
                        setShowMint(false);
                        setNFTsMinted(evolveNFT ? 'NFT evolved correctly' : 'NFTs minted correctly');
                        NFTService.getInstance().mintNFT(smartContract._id ? smartContract._id : '', nftsToSign);
                    }).catch((error: any) => {
                        setShowMint(false);
                        setSpinnerMinting(false);
                        dispatch(setSCError({ smartContractId: smartContractId!, error: "Blockchain error Minting NFT:  -> " + error.reason }));
                        AnalyticsService.error("Generate NFTs:(BC) " + error.reason);
                    })
                    setSignedNFTs();
                }).catch((error: any) => {
                    setSpinnerMinting(false);
                    dispatch(setSCError({ smartContractId: smartContractId!, error: error.reason }));
                    AnalyticsService.error("Generate NFTs:(BC) " + error.reason);
                })
            }

        } catch (error: any) {
            setSpinnerMinting(false);
            dispatch(setSCError({ smartContractId: smartContractId!, error: "Blockchain error Minting NFT:  -> " + error.reason }));
            AnalyticsService.error("Generate NFTs:(BC) " + error.reason);
        }


    }

    const setSignedNFTs = async () => {

        try {
            await nftService.signNFT(smartContract._id ? smartContract._id : '', nftsToSign);
            setShow(false);
        } catch (error) {
            console.error(error);
            AnalyticsService.error("Generate NFTs:(API) Sign NFTs");
        }
    }

    const getFiles = (event: any) => {
        const myImages: string[] = images;
        const maxNFTs = supplyBC ? supplyBC : smartContract.maxNFTs;

        if (!evolveNFT && maxNFTs && (event.target.files.length > maxNFTs || (event.target.files.length + smartContract.nftsGenerated) > maxNFTs)) {
            AnalyticsService.error("Generate NFTs: Max NFTs");
            dispatch(setSCError({ smartContractId: smartContractId!, error: "Your Max NFTs allowed are:  -> " + smartContract.maxNFTs }));

            setShow(false);
        }
        else if (event.target.files.length > 0) {
            Array.from(event.target.files).forEach((file: any) => {
                myImages.push(URL.createObjectURL(file))
            });
            setFiles([...files, ...event.target.files]);
            setImages(myImages);
        }
    };


    useEffect(() => {
        AnalyticsService.modal("Generate NFTs");
    }, [smartContract, nftsToSign]);

    return (
        <>
            <Modal
                className='modal-container'
                show={show}
                onHide={() => { setEvolveNFT(false); setShow(false); }}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title className="col-11" >
                        <h2 className="text-center">
                            {evolveNFT ? "Evolve NFT" : "Generate NFTs to: " + smartContract.name}
                        </h2>
                        <ProgressBar animated now={getPercentStep()} variant="mtmbar" />
                    </Modal.Title>
                    <button type="button" onClick={() => setShow(false)} className="btn-close btn-close-white" aria-label="Close"></button>  
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center" style={{minHeight : '500px', maxHeight: '600px', overflowY : 'auto', overflowX : 'hidden'}}>
                        {!spinnerNFTs && !nftsToSign && files.length === 0 && (<div className="col-10 m-5">
                            <form id="form-create-item" onSubmit={() => { }} className="form-border mt-5" >
                                <div className="field-set ">
                                    <h5 className="text-center">{evolveNFT ? "Choose file for evolve NFT" : "Choose files for NFTs"}</h5>
                                    <div className="d-create-file">
                                        <p id="file_name">{t('forms.generateNFTs.PNG')}.</p>
                                        <div className='browse'>
                                            <input type="button" id="get_file" className="btn-main" value={evolveNFT ? "Choose your file" : "Choose your files"} />
                                            <input id='upload_file' type="file" onChange={getFiles} multiple={evolveNFT ? false : true} onClick={() => { setChooseFiles(true) }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>)}
                        {spinnerNFTs && (
                            <div className="row m-5">
                                <div className="card-body d-flex justify-content-center m-5">
                                <div className="honeycomb m-5">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                </div>
                                </div>
                            </div>
                        )}
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit} className="form-border">
                                {!spinnerNFTs && !nftsToSign && (<NFTsFormField files={files} setShow={setShow} evolveNFT={evolveNFT} smartContractId={smartContractId}/>)}
                                {!spinnerNFTs && !nftsToSign && files.length > 0 && (
                                    <div className="col-12 d-flex justify-content-center mt-4">
                                        <button className='btn-main' type="submit">{evolveNFT ? "Evolve NFT" : "Generate NFTs"}</button>
                                    </div>
                                )}
                            </form>
                        </FormProvider>
                        {smartContractId && nftsToSign && !nftsToSign?.minteableFree && !spinnerNFTs && !evolveNFT && scDeployed === STATUS_IN_BC.MINTED && (
                            <div className="row">
                                <div className="col-6 d-flex justify-content-end">
                                    <button className='btn-main' onClick={() => { dispatch(mintNFTsAction({ smartContractId })); setShow(false) }} type="submit">{t('forms.generateNFTs.mint-now')}</button>
                                </div>
                                <div className="col-6 d-flex justify-content-start">
                                    <button className='btn-main' onClick={() => { analyticsService.click("Mint after"); setSpinnerMinting(true); setShow(false) }} type="submit">{t('forms.generateNFTs.mint-after')}</button>
                                </div>
                            </div>
                        )}
                        {nftsToSign && !spinnerNFTs && evolveNFT && scDeployed === STATUS_IN_BC.MINTED && (
                            <div className="row">
                                <div className="col-6 d-flex justify-content-center">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">"TODO Message"</Tooltip>}
                                    >
                                         <button className='btn-main' onClick={() => { analyticsService.click("Mint Automatically"); setSpinnerMinting(true); setShow(false) }} type="submit">{t('forms.generateNFTs.mintAuto')}</button>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-6 d-flex justify-content-center">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{t('forms.generateNFTs.Evolve')}</Tooltip>}
                                    >
                                        <button className='btn-main' onClick={mintEvolveNFT} type="submit">{t('forms.generateNFTs.mint-evolve')}</button>
                                    </OverlayTrigger>
                                </div>
                                {/* <div className="col-6 d-flex justify-content-start">
                                    <button className='btn-main' onClick={() => { analyticsService.click("Mint after"); setSpinnerMinting(true); setShow(false) }} type="submit">Evolve after</button>
                                </div> */}
                            </div>
                        )}
                        {smartContractId && nftsToSign && !spinnerNFTs && scDeployed !== STATUS_IN_BC.MINTED && (
                            <>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h3>{t('forms.generateNFTs.please')}</h3>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-start">
                                        <button className='btn-main' onClick={() => { analyticsService.click("Sign SmartContract"); dispatch(signSmartContractAction({ smartContractId })); setShow(false) }} type="submit">{t('forms.generateNFTs.deploy')}</button>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                        <button className='btn-main' onClick={() => { analyticsService.click("Mint after"); setSpinnerMinting(true); setShow(false) }} type="submit">{t('forms.generateNFTs.mint-after')}</button>
                                    </div>
                                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                        <button className='btn-main-2' onClick={() => { analyticsService.click("Sign SmartContract"); dispatch(signSmartContractAction({smartContractId})); setShow(false) }} type="submit">Sing SmartContract</button>
                                    </div> */}
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <h1>FOOTER</h1>

                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default GenerateNFTs;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { loadState } from '../../app/sessionHelper';
import { RootState } from '../../app/store';
import { BLOCKCHAIN, STATUS_IN_BC } from "../../models/blockchains";
import { SMART_CONTRACT_TYPE, SmartContract } from '../../models/smartContract';
import { NFTView } from '../../models/nfts';
import { error } from 'console';
import { STANDARD } from '../../models/smartContract';

export interface SmartContractDetailState {
    [key: string]: SmartContractDetail;
}

export enum TYPE_TRANSACTION {
    DEPLOY_CONTRACT,
    MINT_NFT,
    EVOLVE_NFT,
    BURN_NFT
}

export enum RESULT_TRANSACTION {
    SUCCESS,
    ERROR
}


export interface Transaction {
    priceTransaction: number;
    blockchain: BLOCKCHAIN;
    hash : string;
    type: TYPE_TRANSACTION;
    walletOwner : string;
    typeResult : RESULT_TRANSACTION
    smartContractId : string;
}

export interface SmartContractDetail {
    smartContractId: string;
    smartContract?: SmartContract;
    nfts?: any[];
    originalNFTs?: any[];
    nftsPending?: any[]
    nftsMinting?: any[]
    nftsMintedMessage?: string;
    showNFTsMinted?: boolean;
    filter?: FilterNFT;
    config?: SmartContractConfig;
    configuration?: SmartContractConfig;
    error?: string;
    showError?: boolean;
    showGenerateNFTs?: boolean;
    loading?: boolean;
    balance?: number;
    price?: SmartContractValue;
    supply?: SmartContractValue;
    loadingMinting?: boolean;
    showMint?: boolean;
    status?: STATUS_IN_BC;
    banner?: string;
    idToEvolve ? : number;
    transactions ?: Transaction[];
}

export enum FilterNFT {
    'ALL',
    'NOT_MINTED',
    'MINTED',
}

export interface SmartContractConfig {
    royalties?: Config;
    whiteList?: Config;
    dynamicNFTs?: Config;
    fixedPrice?: Config;
    dynamicSupply?: Config;
    customMintDate?: Config;
    mintPausable?: Config;
    burnNFTs?: Config;
    transferPausable?: Config;
    transferCustom?: Config;
    marketBlocked?: Config;
    marketPausable?: Config;
    mintPrice?: Config;
    percentFees?: Config;
    dateInitMint?: Config;
}

interface Config {
    value?: any;
    loading?: boolean;
    isAllowed?: boolean;
}

interface SmartContractValue {
    value?: ethers.BigNumber | number;
    newValue?: ethers.BigNumber | number;
    showModal?: boolean;
    isValidated?: boolean;
    loading?: boolean;
}

interface PayloadConfig extends Config {
    smartContractId: string;
    configKey?: ConfigType
}

interface PayloadValue extends SmartContractValue {
    smartContractId: string;
    scValueKey?: SCValueType;
}

interface PayloadModal {
    smartContractId: string;
    show: boolean;
}

interface PayloadFilter {
    smartContractId: string;
    filterType: FilterNFT;
}

interface PayloadBurn {
    smartContractId: string;
    idNFT: number | string;
    burning: boolean;
    error?: boolean;
}

const filterMapping = (filterType: FilterNFT): STATUS_IN_BC[] => {
    switch (filterType) {
        case FilterNFT.ALL:
            return [];
        case FilterNFT.NOT_MINTED:
            return [STATUS_IN_BC.SIGNED, STATUS_IN_BC.FAILED, STATUS_IN_BC.PENDING];
        case FilterNFT.MINTED:
            return [STATUS_IN_BC.MINTED];
    }
}

export enum ConfigType {
    royalties = 'royalties',
    whiteList = 'whiteList',
    dynamicNFTs = 'dynamicNFTs',
    fixedPrice = 'fixedPrice',
    dynamicSupply = 'dynamicSupply',
    customMintDate = 'customMintDate',
    mintPausable = 'mintPausable',
    burnNFTs = 'burnNFTs',
    transferPausable = 'transferPausable',
    transferCustom = 'transferCustom',
    marketBlocked = 'marketBlocked',
    marketPausable = 'marketPausable'
}

export enum SCValueType {
    price = 'price',
    supply = 'supply'
}

const initialState: SmartContractDetailState = {};

export const smartContractDetailsSlice = createSlice({
    name: 'smartContractDetails',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        createSmartContractDetails: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId] = action.payload;
        },
        modifySCDetails: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId] = {
                ...state[action.payload.smartContractId],
                ...action.payload,
            }
        },
        setSCLoading: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].loading = action.payload.loading;
        },
        setSCError: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].error = action.payload.error;
            state[action.payload.smartContractId].showError = action.payload.showError ?? true;
        },
        showNFTsMinted: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].showNFTsMinted = action.payload.showNFTsMinted;
        },
        setSCConfig: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].config = {
                ...state[action.payload.smartContractId].config,
                ...action.payload.config
            };
        },
        setSCSingleConfig: (state, action: PayloadAction<PayloadConfig>) => {
            const configKey = action.payload.configKey!;
            let config = state[action.payload.smartContractId]?.config!;
            config[configKey] = {
                ...config[configKey],
                ...action.payload
            }
        },
        setSCSingleValue: (state, action: PayloadAction<PayloadValue>) => {
            state[action.payload.smartContractId][action.payload.scValueKey!] = {
                ...state[action.payload.smartContractId][action.payload.scValueKey!],
                ...action.payload
            }
        },
        setPriceShowModal: (state, action: PayloadAction<PayloadModal>) => {
            state[action.payload.smartContractId].price = {
                ...state[action.payload.smartContractId].price,
                showModal: action.payload.show
            };
        },
        setSupplyShowModal: (state, action: PayloadAction<PayloadModal>) => {
            state[action.payload.smartContractId].supply = {
                ...state[action.payload.smartContractId].supply,
                showModal: action.payload.show
            };
        },
        filterNFTs: (state, action: PayloadAction<PayloadFilter>) => {
            state[action.payload.smartContractId].nfts = state[action.payload.smartContractId]?.originalNFTs?.filter((nft: any) => {
                const filters = filterMapping(action.payload.filterType);
                if (filters.length === 0) return true; // ALL
                return filters.includes(nft.status) && !nft.evolved;
            });
            state[action.payload.smartContractId].filter = action.payload.filterType;
        },
        signNFTs: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].loading = true;
            state[action.payload.smartContractId].loadingMinting = true;
            state[action.payload.smartContractId].showMint = true;
            state[action.payload.smartContractId].nftsMinting = state[action.payload.smartContractId].nftsMinting || state[action.payload.smartContractId].nftsPending;
        },
        evolveNFTs: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].loadingMinting = true;
            state[action.payload.smartContractId].showMint = true;
        },
        mintNFTs: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].loadingMinting = false;
            state[action.payload.smartContractId].showNFTsMinted = true;
            state[action.payload.smartContractId].showMint = false;
            state[action.payload.smartContractId].nftsPending = action.payload.nftsPending;
            state[action.payload.smartContractId].nftsMintedMessage = action.payload.nftsMintedMessage;
            state[action.payload.smartContractId].nftsMinting = [];

        },
        addNFTs: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].nftsPending = state[action.payload.smartContractId].nftsPending && action.payload.nftsPending ?
            action.payload.nftsPending.concat(state[action.payload.smartContractId].nftsPending) : action.payload.nftsPending;
            state[action.payload.smartContractId].nfts = state[action.payload.smartContractId].nfts && action.payload.nftsPending?
            action.payload.nfts?.concat(state[action.payload.smartContractId].nfts) : action.payload.nfts;
        },
        updateNFT: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].nftsPending = state[action.payload.smartContractId].nftsPending && action.payload.nftsPending && action.payload.idToEvolve?
            action.payload.nftsPending.concat(state[action.payload.smartContractId].nftsPending?.filter( nft => nft.idNFT !== action.payload.idToEvolve)) : action.payload.nftsPending;
            state[action.payload.smartContractId].nfts = state[action.payload.smartContractId].nfts && action.payload.nftsPending?
            action.payload.nfts?.concat(state[action.payload.smartContractId].nfts?.filter( nft => nft.idNFT !== action.payload.idToEvolve)) : action.payload.nfts;
        },
        mintMessage: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].nftsMintedMessage = action.payload.nftsMintedMessage;
            state[action.payload.smartContractId].nftsPending = action.payload.nftsPending;
            state[action.payload.smartContractId].loading = false;
        },
        mintOnlyMessage: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].nftsMintedMessage = action.payload.nftsMintedMessage;
            state[action.payload.smartContractId].loading = false;
        },
        setBalance: (state, action: PayloadAction<SmartContractDetail>) => {
            state[action.payload.smartContractId].balance = action.payload.balance;
        },

        setBurnNFT: (state, action: PayloadAction<PayloadBurn>) => {
            const nft = state[action.payload.smartContractId].nfts?.find(nft => nft.idNFT === action.payload.idNFT);
            nft.burning = action.payload.burning;
            if (!action.payload.error) {
                nft.statusBurned = !action.payload.burning;
            }
        },
    }
});

export const {
    createSmartContractDetails,
    modifySCDetails,
    setSCError,
    showNFTsMinted,
    setSCConfig,
    setSCSingleValue,
    setSCSingleConfig,
    setPriceShowModal,
    setSupplyShowModal,
    filterNFTs,
    signNFTs,
    mintNFTs,
    setSCLoading,
    mintMessage,
    mintOnlyMessage,
    setBalance,
    addNFTs,
    updateNFT,
    setBurnNFT
} = smartContractDetailsSlice.actions;

export default smartContractDetailsSlice.reducer;

import { useForm } from 'react-hook-form';
import { WalletRoyaltyFormValues } from '../../../models/smartContract';

 
function useWalletRoyaltyForm() {


  const methods = useForm<WalletRoyaltyFormValues>({
    defaultValues: {
      total : 0,
      royaltyList: [{wallet : '', royalties : 0}],
    },
  });
 
  return {
    methods
  };
}
 
export default useWalletRoyaltyForm;
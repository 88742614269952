// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.user.value)`
import {RootState} from "../../app/store";
import { NFTView } from "../../models/nfts";

export const selectAllSmartContractDetails = (state: RootState) => state.smartContractDetails;
export const selectSmartContractDetails = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId];
export const selectSmartContractDetailsChainId = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.smartContract?.blockchainId!;
export const selectSmartContractDetailsId = (smartContractId: string) =>  (state: RootState) => state.smartContractDetails[smartContractId]?.smartContractId;
export const selectSmartContractWalletOwner= (smartContractId: string) =>  (state: RootState) => state.smartContractDetails[smartContractId]?.smartContract?.walletOwner!;
export const selectSCNFTsFilter = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.filter;
export const selectSCNFTs = (smartContractId: string) => (state: RootState) => [state.smartContractDetails[smartContractId]?.nfts, state.smartContractDetails[smartContractId]?.nftsPending];
export const selectSCConfig = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.config;
export const selectSCData = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.smartContract!;
export const selectSCStatus = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.status;
export const selectSCisMinting = (smartContractId: string) => (state: RootState) => [state.smartContractDetails[smartContractId]?.showMint, state.smartContractDetails[smartContractId]?.loadingMinting];
export const selectSCisMinted = (smartContractId: string) => (state: RootState) => [state.smartContractDetails[smartContractId]?.showNFTsMinted, state.smartContractDetails[smartContractId]?.nftsMintedMessage];
export const selectSCError = (smartContractId: string) => (state: RootState) => [state.smartContractDetails[smartContractId]?.error, state.smartContractDetails[smartContractId]?.showError];
export const selectSCPrice = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.price;
export const selectSCSupply = (smartContractId: string) => (state: RootState) => state.smartContractDetails[smartContractId]?.supply;
export const selectSCSummary = (smartContractId: string) => (state: RootState) => {
    return [
    state.smartContractDetails[smartContractId]?.price?.value || state.smartContractDetails[smartContractId].smartContract?.configuration?.mintPrice!,
    state.smartContractDetails[smartContractId]?.supply?.value,
    state.smartContractDetails[smartContractId]?.balance]
}
;
export const selectSCIsNFTMinting =
    (nft: NFTView) =>
    (state: RootState) =>
    state.smartContractDetails[nft.smartContractId].nftsMinting?.some(n => n._id === nft._id);
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import './pinModal.scss';
import Form from 'react-bootstrap/Form';
import AnalyticsService from '../../../services/analytics.service';
import { useTranslation } from "react-i18next";

function PinModal({ show, error, setShow, onSubmit, resend }: PinModalProps) {

  const [pin, setPin] = useState('');
  const { t } = useTranslation('common');
  const handleClose = () => setShow(false);
  const submitPin = () => {
    if(pin !== ''){
      AnalyticsService.modal('Submit pin');
      onSubmit(pin);
      handleClose();
    }
  };

  useEffect(() => {
    if (show) { AnalyticsService.modal('Form pin'); }
  }, [show]);


  return (
    <Modal
      className='modal-container'
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('login.pinModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control value={pin} onChange={(event) => setPin(event.target?.value)} type="text" placeholder="Pin..." />
        <span className='sub-text white-letter '>{t('login.pinModal.check')}</span>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={resend} className='resend white-letter'>{error ? t('login.pinModal.error') : t('login.pinModal.resend')}</button>
        <button disabled={!pin} className='btn-main ' onClick={submitPin}>{t('login.pinModal.submit')}</button>
      </Modal.Footer>
    </Modal>
  );
}

interface PinModalProps {
  show: boolean;
  error: boolean;
  setShow: (value: boolean) => void;
  onSubmit: (pin: string) => void;
  resend: () => Promise<void>;
}

export default PinModal;
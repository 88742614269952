import { ethers } from "ethers";
import { BlockchainNetwork } from "../../models/blockchains";
import { BlockchainDeployer } from "./BlockchainConnector";
import SmartContractService from "../smartContract.service";
import { initializeContract } from "../../utils/blockchainUtils";
import { SMART_CONTRACT_TYPE, SmartContract } from "../../models/smartContract";

export default class MetamaskDeployer implements BlockchainDeployer {

    constructor(private blockchain: BlockchainNetwork) {
    }

    async getConnection() {
        try {
            await (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId : '0x' + this.blockchain.chainId.toString(16) }], 
            })
        } catch (error) {
            try {
                await (window as any).ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                    chainId : '0x' + this.blockchain.chainId.toString(16), // '0x3830303031'
                    blockExplorerUrls: [this.blockchain.explorerUrl], // ['https://mumbai.polygonscan.com']
                    chainName: this.blockchain.name, // 'Mumbai Testnet'
                    nativeCurrency: {
                        decimals: 18,
                        name: this.blockchain.name,
                        symbol: this.blockchain.currencyName
                    },
                    rpcUrls: [this.blockchain.rpcUrl] // ['https://matic-mumbai.chainstacklabs.com']
                    },
                ],
                });
            } catch (error: any) {
                throw new Error("Error adding Network");
                // user rejects the request to "add chain" or param values are wrong, maybe you didn't use hex above for `chainId`?
                // //console.log(`wallet_addEthereumChain Error: ${error.message}`)
            }
        }
    }

    getProvider(): ethers.providers.Web3Provider {
        return new ethers.providers.Web3Provider((window as any).ethereum);
    }

    

    async execute(smartContractCompiled: any, smartContractId : string): Promise<string> {
        const provider = new ethers.providers.Web3Provider((window as any).ethereum);
        const signer = provider.getSigner();
        const tx = { data: smartContractCompiled , gasLimit : 16721970};
        const transaction: any = await signer.sendTransaction(tx);
        SmartContractService.getInstance().signSmartContract(transaction.creates, '', smartContractId);
        await transaction.wait(1);
        return transaction.creates; // SmartContract Address
    }

    async mint(smartContract: SmartContract, nftsToSign: any) {
        const overrides = {
            gasLimit: 1672197,
        };
        const contract = await initializeContract(smartContract.smartContractAddress!, smartContract.blockchainId!);
        let response;
        if(smartContract.smartContractType  === SMART_CONTRACT_TYPE.MTM_1155_MIN){
            response = await contract.mintBatch(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.ids, overrides);
        }
        else {
            response = await contract.mintNFTs(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.uris, overrides);
        }
        await response.wait(1);

    }

}
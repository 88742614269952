import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
type NotificationProps = {
    title?: string,
    description?: string,
    show: boolean,
    close: any,
    severity: AlertColor,
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Notification({ title, description, show, close, severity }: NotificationProps) {
    return (
        <Snackbar
        className='left-notification'
        open={show}
        autoHideDuration={6000}
        onClose={close}>
            <Alert
            onClose={close}
            severity={severity}
            sx={{ width: '100%' }}>
                {title}
            </Alert>
        </Snackbar>
    )
};
export default Notification;
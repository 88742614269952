import {FilterNFT, SmartContractConfig, SmartContractDetail} from "./smartContractDetailsReducer";
import {STATUS_IN_BC} from "../../models/blockchains";

export default function initializeSmartContractDetails(smartContractId: string): SmartContractDetail {
    return {
        smartContractId,
        smartContract: undefined,
        nfts: [],
        nftsPending: [],
        nftsMintedMessage: '',
        showNFTsMinted: false,
        filter: FilterNFT.ALL,
        config: initializeSmartContractDetailsConfig(),
        error: '',
        showError: false,
        showGenerateNFTs: false,
        loading: true,
        balance: 0,
        price: {
            value: 0,
            newValue: 0,
            showModal: false,
            loading: false,
            isValidated: false,
        },
        supply: {
            value: 0,
            newValue: 0,
            showModal: false,
            loading: false,
            isValidated: false
        },
        loadingMinting: true,
        showMint: false,
        status: STATUS_IN_BC.PENDING,
        banner: '/img/home/backgroundhome.jpeg'
    }
}

function initializeSmartContractDetailsConfig(): SmartContractConfig {
    return {
        royalties: initializeConfig(),
        whiteList: initializeConfig(),
        dynamicNFTs: initializeConfig(),
        fixedPrice: initializeConfig(),
        dynamicSupply: initializeConfig(),
        customMintDate: initializeConfig(),
        mintPausable: initializeConfig(),
        burnNFTs: initializeConfig(),
        transferPausable: initializeConfig(),
        transferCustom: initializeConfig(),
        marketBlocked: initializeConfig(),
        marketPausable: initializeConfig()
    }
}

function initializeConfig() {
    return {
        value: false,
        loading: true
    }
}
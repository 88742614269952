import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Slider, { Settings } from "react-slick";
import { SmartContract } from "../../models/smartContract";
import CardSlide from "../CardSlide";

const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

type smartContractListProps = {
    title : string, 
    description : string,
    smartContracts : SmartContract[],
    generateNFTs : any,
    getPenfingNFTs? : any,
    signSmartContract? : any
}

const banners : string[] = ["5.png", "6.png" , "7.png"]
function SmartContractList ({title, description, smartContracts,generateNFTs , getPenfingNFTs,signSmartContract } : smartContractListProps) {

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center'>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{description}</Tooltip>}>
                        <h2>{title}</h2>
                    </OverlayTrigger>

                    <div className="small-border"></div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nft'>
                    <Slider {...settings}>
                        {smartContracts && smartContracts.map((item: SmartContract, index) => (
                        <CardSlide
                            key={index}
                            avatar={item.img ? item.img : item.standard === 'ERC-721' ? "/img/dashboard/card-721.png" : "/img/dashboard/card-1155.png"}
                            banner={item.banner ? item.banner : "/img/dashboard/banners/" + banners[index%3]}
                            smartContract={item}
                            createNFTs={generateNFTs}
                            smartContractSigned={signSmartContract}
                            mintNFTs={getPenfingNFTs}
                        />
                        ))}
                    </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmartContractList;
import { Socket, io } from 'socket.io-client';
import { store } from '../app/store';

// "undefined" means the URL will be computed from the `window.location` object
export default class MTMSocket {

    private static instance: MTMSocket | undefined;;
    socket: Socket;
    private constructor() {
        this.socket = io(process.env.REACT_APP_BASE_URL!, {
            auth: {
                token: store.getState().user.jwt
            }
        });
    }

    static getInstance(): Socket {
        if (!this.instance) {
            this.instance  = new MTMSocket();
        }
        return this.instance.socket;
    }

    static disconnect() {
        this.getInstance().disconnect();
        this.instance = undefined;
    }
}
import { useEffect, useState } from 'react';
import useNFTFormField from './useNFTFormField';
import './nftsFormField.scss';
import { Attribute } from '../../models/smartContract';
import SelectOwnerTabs from './SelectOwnerTab';

const NFTsFormField = ({ files, setShow, evolveNFT, smartContractId }: any) => {
    const {
        fields,
        register,
        getValues,
        addNewNFT,
        removeNFT,
        addAttribute,
        removeAttribute
    } = useNFTFormField();

    const [first, setFirst] = useState(true);

    useEffect(() => {

        const getFiles = () => {
            if (first && files.length > 0) {
                for (let i = 0; i < files.length - fields.length; i++) {
                    addNewNFT("Name NFT", i, files[i].name, files[i]);
                }
                setFirst(false);
            }
            if (!first && fields.length === 0 && files.length > 0) {
                setShow(false);
            }

        }

        getFiles();

    }, [files, fields]);

    return (
        <section className="no-top mt-5 no-bottom">
            {fields.map((field: any, index) => (
                <div key={index} className='row nft_create m-2 p-3 pt-5'>
                    <div className="nft_preview_create col-lg-3 col-md-12 pb-4">
                        <button
                            type="button"
                            onClick={removeNFT(index)}
                            className="btn-close btn-close-white btn-close-nfts col-12"
                        ></button>

                        {field.file.type.includes("image") &&
                            (<img className="image-nfts" src={URL.createObjectURL(field.file)}></img>)
                        }
                        {field.file.type.includes("video") &&
                            (<div className='video-container'>
                                <video width="100%" height="auto" controls className="video-nfts">
                                    <source src={URL.createObjectURL(field.file)} type="video/mp4" />
                                    <source src="movie.ogg" type="video/ogg" />
                                </video>
                            </div>
                            )
                        }
                        {!field.file.type.includes("image") &&
                            !field.file.type.includes("video") &&
                            <img className="image-nfts" src="/img/img-defecto.png"></img>
                        }


                    </div>
                    <div className="col-12 col-lg-9 col-md-12">
                        <div className="col-12">
                            <input type="text" {...register(`nfts.${index}.name`)} className="form-control" required placeholder="e.g. 'Dashboard minteandome Collection'" />
                        </div>
                        <div className="col-12">
                            <input data-autoresize required {...register(`nfts.${index}.description`)} className="form-control" placeholder="Description: e.g. 'This is very limited nft'" />
                        </div>
                        {!evolveNFT && <SelectOwnerTabs index={index} register={register} smartContractId={smartContractId}/>}

                        {getValues(`nfts.${index}.attributes`) && getValues(`nfts.${index}.attributes`)?.length > 0 && (<div className="col-12">
                           <h4 className='text-center'>Characteristics</h4>
                        </div>)}
                        { getValues(`nfts.${index}.attributes`)?.map( (attr : Attribute, indexAttr : number) => 
                            <div key={indexAttr} className="row col-12">
                                <div className='col-12 col-md-1 col-xs-6'>
                                    <a className='btn-close btn-close-white' onClick={removeAttribute(index, indexAttr)} ></a>
                                </div>
                                <div className='col-12 col-md-4 col-xs-6'>
                                    <input data-autoresize required {...register(`nfts.${index}.attributes.${indexAttr}.key`)} className="form-control" placeholder="Name" />
                                </div>
                                <div className='col-12 col-md-5 col-xs-6'>
                                    <input data-autoresize required {...register(`nfts.${index}.attributes.${indexAttr}.value`)}className="form-control" placeholder="Value" />
                                </div>
                                <div className='col-md-2 col-xs-6'>
                                    <a className='btn-main' onClick={addAttribute(index)}>Add</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                // {/* <div key={field.id} className="">
                //     <button
                //         type="button"
                //         onClick={removeNFT(index)}
                //         className=""
                //     >
                //         -
                //     </button>
                //     <input {...register(`nfts.${index}`)} placeholder="Name" />
                // </div>   */}
            ))
            }
        </section >
    );
};

export default NFTsFormField;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadState } from '../../app/sessionHelper';
import { RootState } from '../../app/store';
import { ConfigSmartContract, SMART_CONTRACT_TYPE } from '../../models/smartContract';
import { BLOCKCHAIN } from '../../models/blockchains';
// import { fetchCount } from './userAPI';

export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export enum USER_TYPE {
  FREE,
  PROFESIONAL,
  ENTERPRISE,
  ZERO
}

export enum SUBSCRIPTION_TYPE  {
  FREE,
  PROFFESIONAL,
  ENTERPRISE
}

export enum SUBSCRIPTION_STATUS {
  DEPRECATED,
  ACTIVE,
  DISABLED
}

export type WalletsUser = {
  wallet : string;
  loginType : 'google' | 'discord' | 'linkedin' | 'metamask' | 'casper';
  selected: boolean;
}

export type SubscriptionUserInfo = {
  subscriptionId: string;
  collectionsDeployed: number;
  collectionsMonthlyDeployed: number;
  collectionsMonthlyHistoricDeployed: number;
  nftsdMinted: number;
  nftsMonthlyMinted: number;
  nftsMonthlyHistoricMinted: number;
  dateSignUp: Date;
  dateRenewal: Date
}

export type UserInfo = {
  email?: string,
  name?: string,
  url?: string,
  img?: string,
  banner?: string
  defaultConfig?: ConfigSmartContract,
  subscriptionData?: SubscriptionUserInfo;
  wallet?: string;
  wallets? : WalletsUser[];
}

export type SubscriptionInfo = {
  name?: string;
  publicName?: string;
  description?: string;
  type?:  SUBSCRIPTION_TYPE;
  status?:SUBSCRIPTION_STATUS;
  price?: number;
  blockchainsAllowed?: Array<BLOCKCHAIN>;
  blockchainsCostIncluded?: Array<BLOCKCHAIN>;
  recurrent?: boolean;
  collectionsIncluded?: number;
  collectionsMonthly?: number;
  priceCollectionExtra?: number;
  nftsIncluded?: number;
  nftsMonthly?: number;
  priceNFTExtra?: number;
  analytics?: boolean;
  customAnalytics?: boolean
}
export interface UserState {
  wallet: string;
  status: 'ok' | 'ko';
  jwt: string;
  userInfo: UserInfo;
  subscriptionInfo: SubscriptionInfo;
}

const initialState: UserState = loadState();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWallet: (state, action: PayloadAction<string>) => {
      state.wallet = action.payload;
    },
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setSubscriptionData: (state, action: PayloadAction<SubscriptionInfo>) => {
      state.subscriptionInfo = action.payload;
    },
    setAddWallet :  (state, action: PayloadAction<WalletsUser>) => {
      state.userInfo.wallets = state.userInfo.wallets?.some( wall => wall.wallet === action.payload.wallet) ?  state.userInfo.wallets :  state.userInfo.wallets?.concat(action.payload);
    },
    logout: (state, action: PayloadAction<void>) => {
      sessionStorage.removeItem('userState');
      return { wallet: '', jwt: '', status: 'ko', userInfo: { email: '' }, subscriptionInfo: {}, wallets : [] };
    }
  }
});

export const { setWallet, setJWT, setUserInfo, setSubscriptionData, logout, setAddWallet } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.user.value)`
export const selectWallet = (state: RootState) => state.user.wallet;
export const selectJWT = (state: RootState) => state.user.jwt;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectSubscriptionInfo = (state: RootState) => state.user.subscriptionInfo;
export const selectWallets = (state: RootState) => state.user.userInfo.wallets || [];
export const selectIsWeb3Contract = 
  (smartContractId: string) => 
    (state: RootState) => 
      state.user.userInfo.wallets?.some(w => w.wallet === state.smartContractDetails[smartContractId]?.smartContract?.walletOwner && w.loginType === 'metamask')
      || state.smartContractDetails[smartContractId]?.smartContract?.smartContractType === SMART_CONTRACT_TYPE.MTM_78

export default userSlice.reducer;

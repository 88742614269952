import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import userReducer from '../features/user/user';
import smartContractDetailReducer from '../features/smartContractDetail/smartContractDetailsReducer';
import { saveState } from './sessionHelper';
import dashboardReducer from '../features/dashboard/dashboardReducer';


const appCombinedReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  smartContractDetails: smartContractDetailReducer
});


const rootReducer = (state: any, action: Action) => {
  if(action.type === 'user/logout') {
    state = undefined;
  }
  return appCombinedReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
});

store.subscribe(() => {
  saveState(store.getState().user);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SmartContract } from '../../models/smartContract';
import { PaymentData } from '../../models/payment';

export interface NotificationData {
  title : string,
  description : string,
  descriptionDetails? : string,
  paymentButton ?: boolean
  actionButton?: NotificationAction;
  extraActionButton?: NotificationAction;
  errorText?: JSX.Element;
}

export interface NotificationAction {
  text: string;
  action: () => any;
  validator?: () => Promise<boolean>;
}

export interface DashboardState {
    loading: boolean;
    loadingMinting: boolean;
    loadingNFTs: boolean;
    smartContracts: SmartContract[];
    smartContractsSigned: SmartContract[];
    errorMessage: string;
    nftsList : any[];
    showNFTMinted: boolean;
    showSCSigned: boolean;
    NFTsMintedMessage: string;
    succesMessage : string;
    notification : NotificationData;
    paymentData : PaymentData ;
}


const initialState: DashboardState = {
  loading: true,
  loadingMinting: false,
  loadingNFTs: false,
  smartContracts: [],
  smartContractsSigned: [],
  nftsList: [],
  errorMessage: '',
  showNFTMinted: false,
  showSCSigned: false,
  NFTsMintedMessage: '',
  succesMessage : '',
  notification : { title : '', description : '', paymentButton : false},
  paymentData : {}
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDashboardLoading: (state, action: PayloadAction<boolean>) => {
        return {...state, loading: action.payload};
    },
    setDashboardMintingLoading: (state, action: PayloadAction<boolean>) => {
      return {...state , loadingMinting: action.payload};
    },
    setDashboardNFTsLoading: (state, action: PayloadAction<boolean>) => {
      return {...state , loadingNFTs: action.payload};
    },
    setDashboardPendingSCs: (state, action: PayloadAction<SmartContract[]>) => {
      return {...state , smartContracts: action.payload};
    },
    addPendingContract: (state, action: PayloadAction<SmartContract>) => {
      return {...state , smartContracts: [action.payload].concat(state.smartContracts)};
    },
    setDashboardSignedSCs: (state, action: PayloadAction<SmartContract[]>) => {
      return {...state , smartContractsSigned: action.payload};
    },
    addSignedContract: (state, action: PayloadAction<SmartContract>) => {
      return {...state , 
        smartContractsSigned: [action.payload].concat(state.smartContractsSigned),
        smartContracts: state.smartContracts.filter( (sc) => sc._id !== action.payload._id)
      };
    },
    setDashboardNFTs: (state, action: PayloadAction<any[]>) => {
      return {...state , nftsList: action.payload};
    },
    setDashboardShowNFTsMinted: (state, action: PayloadAction<boolean>) => {
      return {...state , showNFTMinted: action.payload};
    },
    setDashboardShowSCSigned: (state, action: PayloadAction<boolean>) => {
      return {...state , showSCSigned: action.payload};
    },
    setDashboardSuccesMessage: (state, action: PayloadAction<string>) => {
      return {...state , succesMessage: action.payload};
    },
    setDashboardMintedMessage: (state, action: PayloadAction<string>) => {
      return {...state , NFTsMintedMessage: action.payload};
    },
    setDashboardErrorMessage: (state, action: PayloadAction<string>) => {
      return {...state , errorMessage: action.payload};
    },
    setNotification: (state, action: PayloadAction<NotificationData>) => {
      return {...state, notification: action.payload};
    },
    setPaymentData: (state, action: PayloadAction<PaymentData>) => {
      return {...state, paymentData: action.payload};
    },
  }
});

export const {
    setDashboardLoading,
    setDashboardMintingLoading,
    setDashboardNFTsLoading,
    setDashboardPendingSCs,
    addPendingContract,
    setDashboardSignedSCs,
    addSignedContract,
    setDashboardNFTs,
    setDashboardShowNFTsMinted,
    setDashboardShowSCSigned,
    setDashboardMintedMessage,
    setDashboardErrorMessage,
    setDashboardSuccesMessage,
    setNotification,
    setPaymentData
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

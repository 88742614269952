import {
    modifySCDetails, setSCError,
    SmartContractDetail
} from "../../features/smartContractDetail/smartContractDetailsReducer";
import AnalyticsService from "../../services/analytics.service";
import {initializeContract} from "../../utils/blockchainUtils";
import NFTService from "../../services/nft.service";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { SMART_CONTRACT_TYPE } from "../../models/smartContract";
import { NFTView } from "../../models/nfts";
//TODO: Refactor GenerateNFTs for using this method
export const evolveNFTsAction = createAsyncThunk('SC/evolveNFTs', async ({smartContractId ,nft}: EvolvePayload, thunkAPI) => {
    const {smartContract}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    AnalyticsService.click("SDC: Evolve NFTs");
    const overrides = {
        gasLimit: 50000,
    };
    if(!smartContract || !smartContract._id || !smartContract.smartContractAddress || !smartContract.blockchainId){
        return;
    }
    try {
        const contract = await initializeContract(smartContract.smartContractAddress, smartContract.blockchainId, smartContract.smartContractType);
        let response;
        if(smartContract.smartContractType  === SMART_CONTRACT_TYPE.MTM_1155_MIN){
            response = await contract.evolveNFT(nft.idNFT, overrides);
        }
        else{
            response = await contract.evolveNFT(nft.idNFT, nft.ipfsUrl, overrides);
        }
        thunkAPI.dispatch(modifySCDetails({smartContractId, loadingMinting: true, showMint: true, showGenerateNFTs: false}))
        NFTService.getInstance().signEvolve(smartContract._id!, nft);
        await response.wait(1);
        NFTService.getInstance().mintEvolve(smartContract._id!, nft);
        AnalyticsService.transaction("SDC:(BC) Evolve NFTs");
        thunkAPI.dispatch(modifySCDetails({smartContractId, loadingMinting: false, nftsMintedMessage: 'NFT evolved correctly'}));
    } catch (error) {
        AnalyticsService.error(`SDC:(BC) Evolving NFTs( ${error} ) , Blockchain error -> ${error}`);
        thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'Error Minting NFTs'}));
        thunkAPI.dispatch(modifySCDetails({smartContractId, loadingMinting: false}))
    }
});

interface EvolvePayload {
    smartContractId: string;
    nft: NFTView;
}

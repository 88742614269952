
export type BlockchainInfo = {
    name?: string;
    img?: string;
    id?: BLOCKCHAIN;
    id_testnet?: BLOCKCHAIN;
    selected?: boolean;
    imgLogo?: string;
    coin?: string;
    urlScan?: string,
    urlScanTesnet?: string,
    isCasper?: boolean;
}

export type BlockchainNetwork = {
    id?: BLOCKCHAIN;
    chainId: number;
    explorerUrl: string;
    name: string;
    currencyName : string;
    rpcUrl: string;
}

export enum BLOCKCHAIN {
    GANACHE,
    ETHEREUM,
    RINKEBY,
    POLYGON,
    MUMBAI,
    BSC,
    BSC_TESNET,
    CASPER_TESTNET,
    CASPER,
    OASIS_TESTNET,
    OASIS,
    AVAX_SUBNET_TESTNET,
    AVAX_SUBNET,
    AVAX_TESTNET,
    AVAX,
    NULL
}

export enum STATUS_IN_BC {
    PENDING,
    SIGNED,
    MINTED,
    REJECTED,
    FAILED, 
}


import React from "react";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import { selectSmartContractWalletOwner } from "../../features/smartContractDetail/smartContractDetailSelectors";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }
  
  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#D93A2B',
    },
  });
  
interface StyledTabProps {
    label: string;
}
  
const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));
  

enum Selection {
    Me=0,
    Wallet=1,
    Email=2
}

interface SelectOwnerTabsProps {
    index: number;
    register: Function;
    smartContractId: string
}

export default function SelectOwnerTabs({index, register, smartContractId}: SelectOwnerTabsProps) {

    const wallet = useSelector(selectSmartContractWalletOwner(smartContractId))
    const [selection, setSelection] = React.useState<Selection>(Selection.Me);
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setSelection(newValue);
    };
  
    return (
      <Box sx={{ width: '100%'}}>
        <Box>
          <StyledTabs
            value={selection}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab label="Me" />
            <StyledTab label="Wallet" />
            <StyledTab label="Email" />
            </StyledTabs>
            <Box sx={{ p: 3 }}>
                {selection === Selection.Me && <div className="col-12">
                    <input style={{opacity: 0.5}} data-autoresize required {...register(`nfts.${index}.myWallet`)} className="form-control" readOnly value={wallet} />
                </div>}
                {selection === Selection.Wallet && <div className="col-12">
                    <input data-autoresize required {...register(`nfts.${index}.wallet`)} className="form-control" placeholder="Target Wallet: e.g. '0x000000...'" />
                </div>}
                {selection === Selection.Email && <div className="col-12">
                    <input data-autoresize required {...register(`nfts.${index}.email`)} className="form-control" placeholder="Target Email: e.g. 'sample@mail.com'" />
                </div>}
            </Box>
        </Box>
      </Box>
    );
  }
import Skeleton from '@mui/material/Skeleton';
import './Dashboard.scss'

function SkeletonDashboard() {

    return (

        <div className="d-flex justify-content-center mt-2 mb-2 p-3">
            <div className="nft_coll nft_coll2">
                <div className="rectangle1">
                    <Skeleton variant="rounded" width={410} height={200} />
                </div>
                <div className="circle">
                    <Skeleton variant="circular" width={50} height={50} />
                </div>
                <div className="rectangle2 m-3">
                    <Skeleton variant="rounded" width={100} height={30} />
                    <div className="pt-3"><Skeleton variant="rounded" width={100} height={30} />
                    </div>
                </div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <div className="button d-flex m-3">
                    <Skeleton variant="rounded" width={150} height={40} />
                    <div className="space"></div>
                    <Skeleton variant="rounded" width={150} height={40} />
                </div>
            </div>
            <div className="nft_coll nft_coll2">
                <div className="rectangle1">
                    <Skeleton variant="rounded" width={410} height={200} />
                </div>
                <div className="circle">
                    <Skeleton variant="circular" width={50} height={50} />
                </div>
                <div className="rectangle2 m-3">
                    <Skeleton variant="rounded" width={100} height={30} />
                    <div className="pt-3"><Skeleton variant="rounded" width={100} height={30} />
                    </div>
                </div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <div className="button d-flex m-3">
                    <Skeleton variant="rounded" width={150} height={40} />
                    <div className="space"></div>
                    <Skeleton variant="rounded" width={150} height={40} />
                </div>
            </div>
            <div className="nft_coll nft_coll2">
                <div className="rectangulo1">
                    <Skeleton variant="rounded" width={410} height={200} />
                </div>
                <div className="circle">
                    <Skeleton variant="circular" width={50} height={50} />
                </div>
                <div className="rectangulo2 m-3">
                    <Skeleton variant="rounded" width={100} height={30} />
                    <div className="pt-3"><Skeleton variant="rounded" width={100} height={30} />
                    </div>
                </div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <div className="button d-flex m-3">
                    <Skeleton variant="rounded" width={150} height={40} />
                    <div className="espacio"></div>
                    <Skeleton variant="rounded" width={150} height={40} />
                </div>
            </div>
        </div>
    )
}
export default SkeletonDashboard;
import {
    mintNFTs,
    modifySCDetails,
    setSCError,
    signNFTs,
    SmartContractDetail
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import AnalyticsService from "../../../services/analytics.service";
import {initializeContract} from "../../../utils/blockchainUtils";
import NFTService from "../../../services/nft.service";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { SMART_CONTRACT_TYPE } from "../../../models/smartContract";
import BlockchainConnector from "../../../services/blockchain/BlockchainConnector";
import { NFTView } from "../../../models/nfts";

export const mintNFTsAction = createAsyncThunk('SC/mintNFTs', async ({smartContractId,nft}: MintPayload, thunkAPI) => {

    AnalyticsService.click("SDC: Mint NFTs!!!!!");
    const {smartContract, nftsPending}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];


    if (!smartContract?._id || !smartContract?.smartContractAddress) {
        AnalyticsService.error('SDC:(BC) Smart Contract pending deploy');
        thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'Smart Contract pending deploy'}));
        return;
    }
    try {
        const nftsToSign = await NFTService.getInstance().nftsPendingMint(smartContract._id);
        if (nftsToSign.totalToMint === 0) {
            AnalyticsService.error('SDC:(BC) There are no pending nfts to sign');
            thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'There are no pending nfts to sign'}));
            return;
        }
        AnalyticsService.click("SDC: Mint NFTs");
        if(smartContract.smartContractAddress &&  smartContract.blockchainId){

            const chain = parseInt(smartContract.blockchainId, 10);
            const nftsToSignOk = nft ? {_ids : [nft._id],smartContractId : smartContract._id} : nftsToSign;
            thunkAPI.dispatch(signNFTs({smartContractId}));
            // await NFTService.getInstance().signNFT(smartContract._id ? smartContract._id : '', nftsToSignOk);

            const blockchainConnector = new BlockchainConnector(chain);
            await blockchainConnector.mintNFTs(smartContract, nftsPending,nft);

            AnalyticsService.transaction("SDC:(BC) Mint NFTs");
            
            await NFTService.getInstance().mintNFT(smartContract._id ? smartContract._id : '', nftsToSignOk);
            thunkAPI.dispatch(mintNFTs({smartContractId, nftsPending: [], nftsMintedMessage: 'NFTs minted correctly'}));
        }
    } catch (error : any) {
        AnalyticsService.error(`SDC:(BC) Minting NFTs( ${error} ) , Blockchain error -> ${error}`);
        thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'Error Minting NFTs'}));
        thunkAPI.dispatch(modifySCDetails({smartContractId, loadingMinting: false}))
    }
});

interface MintPayload {
    smartContractId: string;
    nft? : NFTView;
}

import { useEffect } from "react";
import { Socket } from "socket.io-client";
import { signSmartContractAction } from "../actions/list/signSmartContractAction";
import { burnNFTsActionTx } from "../actions/smartContractDetails/burn/burnNFTsTxAction";
import { mintNFTsActionTx } from "../actions/smartContractDetails/mintNFTsAction";
import { setDashboardErrorMessage } from "../features/dashboard/dashboardReducer";
import { TransactionMTM } from "../services/smartContract.service";
import MTMSocket from "../services/MTMEvents";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import { selectJWT } from "../features/user/user";

function useEvents() {

    const dispatch = useAppDispatch();
    const jwt = useSelector(selectJWT);

    useEffect(() => {

        let events: Socket;
        if (jwt) {
            dispatch(setDashboardErrorMessage(''));
            events = MTMSocket.getInstance();
            events.on('deploy', async (transaction: TransactionMTM) => {
                dispatch(signSmartContractAction(transaction));
            });
            events.on("mint", async (transaction: TransactionMTM) => {
                dispatch(mintNFTsActionTx(transaction));
            });
            events.on("burn", async (transaction: TransactionMTM) => {
                dispatch(burnNFTsActionTx(transaction));
            });
        }
        
        return () => {
            if (events) {
                events.removeAllListeners('deploy');
                events.removeAllListeners('mint');
                events.removeAllListeners('burn');
                events.disconnect();
            };
        }
      }, []);
}

export default useEvents;
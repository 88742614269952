import {
    FilterNFT,
    filterNFTs
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import { useSelector } from "react-redux";
import AnalyticsService from "../../../services/analytics.service";
import { useAppDispatch } from "../../../app/hooks";
import { selectSCNFTs, selectSCNFTsFilter } from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


function SCNFTsFilter({ smartContractId }: NFTsFilterProps) {

    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();
    const filter = useSelector(selectSCNFTsFilter(smartContractId));
    const [nfts, nftsPending] = useSelector(selectSCNFTs(smartContractId));
    return (
        <>
            {nfts && (
                <div className={nfts?.length > 0 ? "items_filter d-flex justify-content-center" : "items_filter d-flex justify-content-start"}>
                    <ul className="de_nav" onClick={() => AnalyticsService.click("SDC: Filter NFTs")}>
                        {filter !== FilterNFT.ALL && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('scdetails.All-Show')}</Tooltip>}
                            >
                                <li className={"active btn-main"}
                                    onClick={() => dispatch(filterNFTs({ smartContractId, filterType: FilterNFT.ALL }))}>{t('scdetails.All-NFTs')}</li>
                            </OverlayTrigger>)}

                        {filter !== FilterNFT.NOT_MINTED && nftsPending && nftsPending?.length > 0 && nfts?.length !== nftsPending?.length && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('scdetails.Pending-Show')}</Tooltip>}
                            >
                                <li className="active btn-main"
                                    onClick={() => dispatch(filterNFTs({ smartContractId, filterType: FilterNFT.NOT_MINTED }))}>{t('scdetails.Pending-Mint')}</li>
                            </OverlayTrigger>)}

                        {filter !== FilterNFT.MINTED && nfts?.length > 0 && nfts?.length !== nftsPending?.length && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('scdetails.Minted-Show')}</Tooltip>}
                            >
                                <li className="active btn-main"
                                    onClick={() => dispatch(filterNFTs({ smartContractId, filterType: FilterNFT.MINTED }))}>{t('scdetails.Minted')}</li>
                            </OverlayTrigger>)}
                    </ul>

                </div>
            )}
        </>
    );
}

interface NFTsFilterProps {
    smartContractId: string;
}

export default SCNFTsFilter;

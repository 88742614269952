import { Skeleton } from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { SmartContract } from "../../models/smartContract";
import AnalyticsService from "../../services/analytics.service";
import NFTService from "../../services/nft.service";
import SmartContractService from "../../services/smartContract.service";
import Notification from "../../components/Notification";


function MintByUser() {

    const nftService = new NFTService();
    const smartContractService = new SmartContractService();
    let location = useLocation();
    const { smartContractId } = useParams<{ smartContractId: string }>();

    const [myWallet, setMyWallet] = useState('');
    const [mySmartContractId, setSmartContractId] = useState('');
    const [smartContract, setSmartContract] = useState<SmartContract>();
    const [mySignature, setSignature] = useState<string>('');
    const [nfts, setNFTs] = useState<any>();
    const [error, setError] = useState('');
    const [imageNFTs, setImage] = useState<any[]>([]);
    const [myNFTs, setMyNFTs] = useState<any[]>([]);
    const [myNFTsAux, setMyNFTsAux] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [minted, setMinted] = useState(false);

    const provider = new ethers.providers.Web3Provider((window as any).ethereum);

    const connectMetamask = async () => {
        AnalyticsService.click('MBU: Connect Wallet');
        const reqAcc = await provider.send('eth_requestAccounts', []);

        if (reqAcc) {
            setMyWallet(reqAcc[0]);
            getMyNFTs(reqAcc[0]);
        }
    };

    const getMyNFTs = async (wallet: string) => {

        try {
            const signature = await signMessage(mySmartContractId);
            if (signature) {

                setLoading(true);
                setSignature(signature);

                let nftsToMint: any = await nftService.pendingNFTsUser(wallet, signature, mySmartContractId);

                if (nftsToMint.addresses && nftsToMint.addresses.length !== 0) {
                    nftsToMint.jsons.map((json: string) => {
                        return getInfoNFT(json);
                    })

                    nftsToMint.images.forEach((url: string) => {
                        imageNFTs.push(url);
                        setImage(imageNFTs);
                    })
                    setTimeout(function () {
                        setMyNFTs(myNFTsAux);
                        setLoading(false);
                    }, 1000);
                    setNFTs(nftsToMint);
                }
                else {
                    setError("NFTs are not available for mint");
                    AnalyticsService.error('MBU: get pending NFTs user');
                    setLoading(false);
                }

            }
        } catch (error) {
            setError("Error trying connect Metamask");
            AnalyticsService.error('MBU: get pending NFTs user');
        }
        
    }

    const getInfoNFT = async (url: string) => {
        if (url !== null && url !== undefined) {
            fetch(url)
                .then((response) => response.json())
                .then((nftJson) => {
                    const nftsList = myNFTsAux;
                    nftsList.push(nftJson);
                    setMyNFTsAux(nftsList);
                })
                .catch((error) => {
                    setError("Metadata is not available");
                    AnalyticsService.error('MBU: get metadata NFTs');
                });
        }
    }


    const mintNFT = async () => {

        const overrides = {
            gasLimit: 16721970,
            value: ethers.utils.parseEther(smartContract!.price!.toFixed(2))
        };
        if (nfts) {
            try {
                const chain = parseInt(nfts.blockchainId, 10);
                await (window as any).ethereum.send('eth_requestAccounts');
                await (window as any).ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x' + chain.toString(16) }]
                });

                const smartContractABI = require('./../../smartContracts/smartContractGeneric.json').abi;
                const provider = new ethers.providers.Web3Provider((window as any).ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(nfts.smartContractAddress, smartContractABI, signer);
                if (nfts.amounts[0] === nfts.uris.length) {

                    const transaction = await contract.mintNFTForUser(nfts.addresses[0], nfts.uris, overrides);
                    setMinted(true);
                    setLoading(true);
                    transaction.wait(1).then((response: any) => {
                        if (response) {
                            setLoading(false);
                            //TODO MINT BY USER
                        }
                    }).catch((error: any) => {
                        setError("Transaction Failed, please, try again");
                        AnalyticsService.error('MBU: Mint NFT Transaction failed');
                    })

                    await nftService.signNFTsUser(myWallet, mySignature, mySmartContractId, nfts);
                }
                else {
                    setError('Error with your NFTs pending mint');
                    AnalyticsService.error('MBU: Recover NFTs');
                }
            } catch (error) {
                setError("Error connecting metamask");
                AnalyticsService.error('MBU: Mint NFT connecting metamask');
            }

            

        }

    }

    const signMessage = async (nonce: string) => {
        const signer = provider.getSigner();
        const signature = await signer.signMessage(`Login on minteandome with nonce: ${nonce}`);
        return signature;
    }
    const getInfoSmartContract = async (smartContractId : string) => {
        const sc = await smartContractService.smartContractPublicDetails(smartContractId);
        setSmartContract(sc.smartContract);
    }
    useEffect(() => {
        AnalyticsService.view("MintByUser: " + smartContractId);
        if (smartContractId) {
            setSmartContractId(smartContractId);
            getInfoSmartContract(smartContractId)
        }
        else {
            setError("Url not valid");
        }
        

    }, [location, smartContractId]);

    return (
        <div>
            <section className='jumbotron breadcumb no-bg h-vh-mbu pt-5 pb-5' style={{ backgroundImage: `url(${"/img/home/backgroundhome.jpeg"})` }}>
                <div className="container">
                    <div className="row">
                        <div className='col-3 mainbreadcumb'>
                            <div className="spacer-single"></div>
                            <h6> <span className="text-uppercase color">  <b>{smartContract?.symbol}</b> {smartContract?.name}</span></h6>
                            <h1 className="col-white">{smartContract?.description}</h1>
                            <div className="spacer-10"></div>
                            <div className="mb-sm-30"></div>
                            <div className="row">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                                        <div className="de_count text-left">
                                            <h3><span>{smartContract?.price ? smartContract?.price : 'Free' }</span></h3>
                                            <h5 className="id-color">Price</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                                        <div className="de_count text-left">
                                            <h3><span>{smartContract?.maxNFTs}</span></h3>
                                            <h5 className="id-color">Collectibles</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!myWallet && (<span onClick={connectMetamask} className="btn-main inline white lead">Connect Wallet</span>)}
                        </div>
                        <div className="col-3 m-5">
                            {myWallet && myNFTs && (
                                myNFTs.map((myNFT, index) => (
                                    <div key={index.toString()} className="nft__item" >

                                        {imageNFTs[index] && (
                                            <img src={imageNFTs[index]} className='nft__item_wrap' alt="..." />
                                        )}
                                        <div className="nft__item_action mb-2 text-center">
                                            <h5 className="card-title">{myNFT.name}</h5>
                                            <p className="card-text">{myNFT.description}</p>
                                        </div>
                                        {!minted && !loading && (<div className="d-flex justify-content-center">
                                            <button onClick={mintNFT} className="btn-main">Mint NFT</button>
                                        </div>)}
                                        {minted && loading && (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border m-5" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                <h4 className="text-center width-text">MINTING NFT</h4>
                                            </>
                                        )}
                                        {minted && !loading && (
                                            <>
                                                <h4 className="text-center width-text">NFT MINTED</h4>
                                            </>
                                        )}
                                    </div>

                                ))

                            )}
                            {loading && !minted && (
                                <div className="nft__item" >
                                    {/* <Skeleton variant="rectangular" width={'100%'} height={60} /> */}
                                    <Skeleton variant="rounded" width={'100%'} height={'70%'} />
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                </div>
                            )}
                        </div>
                    </div>
                    <Notification
                        title={"Application error"}
                        description={error}
                        show={error != ''}
                        close={() => setError('')}
                        severity="error"
                    />

                </div>
            </section>
        </div>
    );
}

export default MintByUser;
import axios, { AxiosRequestConfig } from "axios";
import { MintNFTSmartContract, NFT, NFTBurnable, NFTView } from "../models/nfts";
import {store} from "../app/store";


class NFTService {

    private static _instance: NFTService;
    static getInstance() {
        if (!this._instance) {
            this._instance = new NFTService();
        }
        return this._instance;
    }

    async generateNFT(smartContractId : string, nfts : NFT[]) : Promise<MintNFTSmartContract | undefined>{
        try {
            const url = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/generate-nft/${smartContractId}`;
            const response = await axios.post(url,nfts,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error creating NFT', error);
            return undefined;
        }
    }

    async evolveNFT(smartContractId : string, nfts : NFT[]) : Promise<MintNFTSmartContract | undefined>{
        try {
            const url = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/generate-nft/${smartContractId}` + "?evolveNFT=true";
            const response = await axios.post(url,nfts,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error creating NFT (evolve)', error);
            return undefined;
        }
    }

    async pendingNFTsUser(wallet: string, signature: string, smartContractId: string) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + `/nft/user/pending-mint/${smartContractId}`, {wallet, signature});
            console.error(response);
            return response?.data?.result;
        } catch (error) {
            console.error('Error on pendingNFTsUser');
            return undefined;
        }
    }

    async nftsPendingMint (smartContractId : any){
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/nft/pending-mint/' + smartContractId;
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async getNFTs (){
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/nft/nfts';
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result.nfts;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }
    

    async signNFT (smartContractId : string, nfts : any){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/sign/${smartContractId}`;
            const response = await axios.post(url,nfts,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async mintNFT (smartContractId : string, nfts : any){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/mint/${smartContractId}`;
            const response = await axios.post(url,nfts,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async signEvolve (smartContractId : string, nft : NFTView){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/sign-evolve/${smartContractId}`;
            const response = await axios.post(url,nft,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async mintEvolve (smartContractId : string, nfts : any){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/mint-evolve/${smartContractId}`;
            const response = await axios.post(url,nfts,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async isBurnable (smartContractId : string, nftId : string) : Promise<NFTBurnable>{
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/burn/${smartContractId}/${nftId}`;
            const response = await axios.get(url ,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return {burnable : false};
        }
    }

    async signBurn (smartContractId : string, nft : NFTView,error ?: boolean){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/burn/${smartContractId}`;
            const response = await axios.post(url, {nft, error} ,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async scheduleBurn (smartContractId : string, nft : NFTView,error ?: boolean){
        
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/nft/schedule-burn/${smartContractId}`;
            const response = await axios.post(url, {nft, error} ,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching nfts');
            return undefined;
        }
    }

    async signNFTsUser(wallet: string, signature: string, smartContractId: string, nftsToSign : any) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + `/nft/sign-by-user/${smartContractId}`, {wallet, signature, nftsToSign});
            console.error(response);
            return response?.data?.result;
        } catch (error) {
            console.error('Error on pendingNFTsUser');
            return undefined;
        }
    }

    async getClaimInfo (token : string){
        const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/claim-nft`;
        const response = await axios.get(url, {headers: {authorization: token}});
        return response?.data?.result;
    }

    async claimNFT (token : string, wallet: string){
        const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/claim-nft`;
        const response = await axios.post(url, {wallet}, {headers: {authorization: token}});
        return response?.data?.result;
    }


    getConfigRequest () :AxiosRequestConfig {
        return {
            headers: {
                authorization: store.getState().user.jwt
            }
        };
    }
}

export default NFTService;
import { useState } from 'react';
import useWalletRoyaltyFormField from './useWalletRoyaltyFormField';
import './walletRoyaltyFormField.scss';

const WalletRoyaltyFormField = () => {

    const [total , setTotal ] = useState(0); 
    const {
        fields,
        register,
        addWallet,
        removeWallet,
        updateTotal,
        getValues,
        errors,
        setError,
        clearErrors
    } = useWalletRoyaltyFormField({setTotal});

    const validateWallet = (wallet: string, index: number) => {
        if (wallet && getValues().royaltyList.some((royalty, i) => royalty.wallet === wallet && i !== index)) {
            setError(`royaltyList.${index}.wallet`, {type: 'custom', message: 'Wallet is duplicated'});
            return;
        }
        clearErrors(`royaltyList.${index}.wallet`);
    }

    
    return (
        <section className="no-top no-bottom">
            <h5 className='text-center mb-3'>Royalties</h5>
            {fields.map((field: any, index) => (
                <div key={index} className='row col-12'>
                        <div className="col-lg-6">
                            <input 
                            className="form-control" 
                            data-autoresize
                            required
                            {...register(`royaltyList.${index}.wallet`,
                            {onChange: ({target: {value}})=> validateWallet(value, index)})}
                            placeholder="Target Wallet: e.g. '0x000000...'" />
                            {errors.royaltyList && (<div className='wallet-error'>{errors.royaltyList[index]?.wallet?.message}</div>)}
                        </div>
                        <div className="col-12 col-lg-2">
                            <input data-autoresize 
                                className="form-control" 
                                {...register(`royaltyList.${index}.royalties`, {onChange: () => updateTotal() })} 
                                required 
                                type="number" step=".01" min="0"
                                placeholder="Wallet royalties" />
                        </div>
                        <div className='col-6 col-lg-2 col-xs-6  d-flex justify-content-start'>
                            <button className='btn-main-2' disabled={getValues().royaltyList.length <= 1} onClick={removeWallet(index)}>Delete</button>
                        </div>
                        <div className='col-6 col-lg-1 col-xs-6  d-flex justify-content-start'>
                            <button className='btn-main-2' onClick={() => addWallet('',0.0)}>Add</button>
                        </div>
                </div>
            ))
            }
            <div className='text-center'><h5>Total : {total.toFixed(2)}%</h5></div>
        </section >
    );
};

export default WalletRoyaltyFormField;
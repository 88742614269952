import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import PaymentService from "../services/payment.service";
import { PaymentData } from "../models/payment";
import { useAppDispatch } from "../app/hooks";
import { UserInfo, selectJWT, selectUserInfo } from "../features/user/user";
import PaymentService from "../services/payment.service";
import { setDashboardErrorMessage, setNotification } from "../features/dashboard/dashboardReducer";

export type FormProps = {
  paymentData: PaymentData,
  setModalPayment: any
}

export default function CheckoutForm({ paymentData, setModalPayment }: FormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const userInfo: UserInfo = useSelector(selectUserInfo);

  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const jwt = useSelector(selectJWT);
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {

    if (!stripe && !paymentData) {
      console.error("NO PAYMENT DATA");
      return;
    }

  }, [stripe, paymentData, jwt]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    if (email) ////console.log('ok');

      stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:3000/pruebas/",
        },
        redirect: 'if_required'
      }).then((result) => {

        if (result?.error) {
          setIsLoading(false);
          dispatch(setNotification( {title : 'An error has ocurred', description : 'Error has ocurred trying validate your payment', descriptionDetails : result.error.message}));
          // setModalPayment(false);
        } else {
          //console.log("RESULT PAYMENT DONE: ", result);
          paidApi(paymentData);
        }
      }).catch((error) => {
        dispatch(setNotification( {title : 'An error has ocurred', description : 'Error has ocurred trying validate your payment'}))
        setIsLoading(false);
        setModalPayment(false);
      })


  };

  const paidApi = async (body: PaymentData) => {

    try {
      setModalPayment(false);
      const paid = await PaymentService.paid(body); //resp?.data?.result?.status;
      if (paid?.status === 'succeeded') {
        dispatch(setNotification( {title : 'Thank you', description : 'Your payment has been received correctly'}));
        setModalPayment(false);
      }
    } catch (error: any) {
      dispatch(setNotification( {title : 'An error has ocurred', description : 'Error has ocurred trying validate your payment'}))
      setModalPayment(false);
      return undefined;
    }

  }

  return (
    <>
      <Form id="payment-form" onSubmit={handleSubmit} style={{ width: '100%', minHeight: '500px' }}>
        {message !== 'Payment succeeded!' && (
          <>
            <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={(e: any) => setEmail(e.value)}
              options={{ defaultValues: { email: userInfo.email ? userInfo.email : '' } }}
            />
            <PaymentElement id="payment-element" options={{ layout: "tabs" }} onChange={(event) => { setShowButton(event.complete ? true : false) }} />
            {message && <div className="mt-3" id="payment-message">{message}</div>}
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="mt-5 honeycomb">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <button className="btn-main mt-5" disabled={isLoading || !stripe || !elements || !showButton} type="submit">
                    Pay now
                  </button>
                </div>
              </>
            )}
          </>
        )}
        {/* Show any error or success messages */}

      </Form>

    </>
  );
}

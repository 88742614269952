import {useSelector} from "react-redux";
import {calculateCoin} from "../../../utils/blockchainUtils";
import {selectSCData, selectSCSummary} from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { ReactI18NextChild } from "react-i18next";
import { useTranslation } from "react-i18next";


interface SCDetailsSummaryProps {
    smartContractId: string;
}

function SCDetailsSummary({smartContractId}: SCDetailsSummaryProps) {
  
    const { t } = useTranslation('common');

    const contract = useSelector(selectSCData(smartContractId));
    const [price, supply, balance] = useSelector(selectSCSummary(smartContractId));

    return (
        <div className="row mb-3 details-smartcontract">
            {/* Pruebas
            <h1>
                <b>Mint price:</b>&nbsp;{}&nbsp;{calculateCoin(contract.blockchain ?? 0)}
            </h1> */}
            <div className="col-12 col-md-4 d-flex justify-content-center">
                {/* {price !== 0 && ( */}
                    <p>
                        <b>{t('scdetails.Mint-price')}:</b>&nbsp;{parseFloat(String(price)).toFixed(2)}&nbsp;{calculateCoin(contract.blockchain ?? 0)}
                    </p>
                
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-center">
                {supply !== 0 && (<p><b>{t('scdetails.maxSupply')}:</b>&nbsp;{Number(supply)}</p>)}
            </div>
            
            <div className="col-12 col-md-4 d-flex justify-content-center">
                <p><b>{t('scdetails.balance')}:</b>&nbsp;{parseFloat(String(balance)).toFixed(2)}&nbsp;{calculateCoin(contract.blockchain ?? 0  )}</p>
            </div>
            
        </div>
    );
}

export default  SCDetailsSummary;
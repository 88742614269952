import axios from "axios";
import { SmartContract } from "../models/smartContract";
import {store} from "../app/store";
import { NFTView } from "../models/nfts";
import { Transaction } from "../features/smartContractDetail/smartContractDetailsReducer";


export type InfoSCResponse = {
    nfts : NFTView[],
    smartContract : SmartContract,
    transactions : Transaction[]
}

interface SCFeatures {
    config: any;
    values: any;
}

class SmartContractService {

    private static _instance: SmartContractService;
    static getInstance() {
        if (!this._instance) {
            this._instance = new SmartContractService();
        }
        return this._instance;
    }

    async createSmartContract(smartContract : SmartContract) : Promise<SmartContract | undefined>{
        try {
            const url = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/smart-contract`;
            const response = await axios.post(url,smartContract,this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error creating Smart Contract', error);
            return undefined;
        }
    }
    
    async smartContractsCompiled (smartContractId : string){
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/compile/' + smartContractId;
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error getting contract');
            return undefined;
        }
    }

    async smartContractsPendingSign (): Promise<SmartContract[]>{
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/pending-sign';
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error searching smartContracts');
            return [];
        }
    }

    async signSmartContract (smartContractAddress: string, packageHash : string,  smartContractId : string){
        try {
            const data = {smartContractAddress, packageHash}
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/sign/' + smartContractId;
            const response = await axios.post(url, data, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error signin smartContract');
            return undefined;
        }
    }

    async deploySmartContract ( smartContractId : string){
        try {
            const url : string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/deploy/' + smartContractId;
            const response = await axios.post(url, {}, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error signin smartContract');
            return undefined;
        }
    }

    async smartContractSigned (){
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/signed';
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching user');
            return undefined;
        }
    }

    async smartContractDetails (smartContractId : any) : Promise<InfoSCResponse | undefined>{
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/info/' + smartContractId;
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error: any) {
            console.error('Error on searching user');
            if (error?.code === "ERR_NETWORK") {
                throw new Error('Connection error. Please try in some minutes');
            }
            return undefined;
        }
    }

    async getFeatures (smartContractId : any) : Promise<SCFeatures>{
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/info/features/' + smartContractId;
            const response = await axios.get(url, this.getConfigRequest());
            return response?.data?.result;
        } catch (error: any) {
            console.error('Error on searching user');
            if (error?.code === "ERR_NETWORK") {
                throw new Error('Connection error. Please try in some minutes');
            }
            throw error;
        }
    }

    async smartContractPublicDetails (smartContractId : any){
        try {
            const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/public/info/' + smartContractId;
            const response = await axios.get(url);
            return response?.data?.result;
        } catch (error: any) {
            console.error('Error on searching user');
            if (error?.code === "ERR_NETWORK") {
                throw new Error('Connection error. Please try in some minutes');
            }
            return undefined;
        }
    }

    getConfigRequest () {
        return {
            headers: {
                authorization: store.getState().user.jwt
            }
        };
    }

}

export interface TransactionMTM {
    smartContractId: string;
    tx: string;
    idNFTs: string[];
}

export default SmartContractService;
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setDashboardLoading, setDashboardMintingLoading, setDashboardNFTs, setDashboardNFTsLoading, setDashboardPendingSCs, setDashboardShowNFTsMinted, setDashboardShowSCSigned, setDashboardSignedSCs } from "../../features/dashboard/dashboardReducer";
import { selectDashboard, selectDashboardContracts } from "../../features/dashboard/dashboardSelectors";
import { selectJWT } from "../../features/user/user";
import AnalyticsService from "../../services/analytics.service";
import NFTService from "../../services/nft.service";
import SmartContractService from "../../services/smartContract.service";


export default function useDashboard() {

    const dispatch = useAppDispatch();

    const dashboard = useSelector(selectDashboard);
    const smartContracts = useSelector(selectDashboardContracts);

    const jwt = useSelector(selectJWT);
    const navigate = useNavigate();

    const scSignedCount = useRef(0);

    useEffect(() => {
      if (scSignedCount.current !== 0 && scSignedCount.current !== dashboard.smartContractsSigned.length) {
        dispatch(setDashboardShowSCSigned(true));
      }
      scSignedCount.current = dashboard.smartContractsSigned.length;
    }, [dashboard.smartContractsSigned]);
    

    useEffect(() => {
        AnalyticsService.view('Dashboard');
        if (!jwt) {
          navigate('/');
        }
    
        const callCollections = async () => {
    
          try {
            if(!dashboard.smartContracts.length && !dashboard.smartContractsSigned.length){
              const [smartContractsPending, smartContractsSigned] = await Promise.all([
                SmartContractService.getInstance().smartContractsPendingSign(),
                SmartContractService.getInstance().smartContractSigned()
              ])
              dispatch(setDashboardLoading(false))
              dispatch(setDashboardMintingLoading(false))
              if (smartContractsPending.length) {
                  dispatch(setDashboardPendingSCs(smartContractsPending.reverse()));
                  const smartContractIds = smartContractsPending.map(sc => sc._id);
              }
              if (smartContractsSigned.length) {
                  dispatch(setDashboardSignedSCs(smartContractsSigned.reverse()));
              }
            }
              
          } catch (error) {
            AnalyticsService.error('Dashboard: Smart Contracts');
          }
        };
    
        const callNFTs = async () => {
          try {
            const nfts = await NFTService.getInstance().getNFTs();
            dispatch(setDashboardNFTs(nfts));
            dispatch(setDashboardNFTsLoading(false));
          } catch (error) {
            AnalyticsService.error('Dashboard: NFTs');
          }
        }
    
        callNFTs();
        callCollections();
    
        if (dashboard.NFTsMintedMessage !== '') {
          dispatch(setDashboardShowNFTsMinted(true));
        }
    
      }, [navigate, jwt, dashboard.errorMessage, dashboard.NFTsMintedMessage, dashboard.loadingMinting]);


    return [dashboard];
}

import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BlockchainInfo, STATUS_IN_BC } from "../../models/blockchains";
import { MetadataNFTs, NFTView } from "../../models/nfts";
import { SmartContract } from "../../models/smartContract";
import '../card.scss';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { evolveNFTsAction } from "../../actions/smartContractDetails/evolveNFTsAction";
import './NFTCard.scss';
import loadingGear from './Minting.svg'
import { useSelector } from "react-redux";
import { selectSCIsNFTMinting } from "../../features/smartContractDetail/smartContractDetailSelectors";
import { selectIsWeb3Contract } from "../../features/user/user";
import BurnButton from "./BurnButton";
import MintButton from "./MintButton";

const BlockchainListAvailable: BlockchainInfo[] = [
    {
        name: "Ethereum",
        img: "/img/blockchains/ethereum.png",
        imgLogo: "/img/blockchains/onlyLogo/g-ethereum.png",
        id: 1,
        id_testnet: 2,
        selected: false
    },
    {
        name: "Polygon",
        img: "/img/blockchains/polygon.png",
        imgLogo: "/img/blockchains/onlyLogo/g-polygon.png",
        id: 3,
        id_testnet: 4,
        selected: false
    },
    {
        name: "Binance Smart Chain",
        img: "/img/blockchains/bsc.png",
        imgLogo: "/img/blockchains/onlyLogo/g-bsc.png",
        id: 5,
        id_testnet: 6,
        selected: false
    },
    {
        name: "Casper",
        img: "/img/blockchains/polygon.png",
        imgLogo: "/img/blockchains/white/casper.png",
        id: 8,
        id_testnet: 7,
        selected: false
    },
    
];

type NFTCardProps = {
    nft: NFTView,
    burneable?: boolean,
    evolveable?: boolean,
    minteable?: boolean,
    onImgLoad?: any,
    setShowGenerateNFTs: any,
    evolveNFT: any,
    setIdToEvolve: any,
    smartContract: SmartContract,
    generateErrorData: any,
    setSpinnerMinting: any
}

function NFTCard({
        nft,
        minteable = true,
        burneable,
        evolveable = true, onImgLoad, evolveNFT,
        setShowGenerateNFTs, setIdToEvolve, smartContract
    }: NFTCardProps) {
            
    // const navigate = useNavigate();
    const [infoNFT, setInfoNFT] = useState<MetadataNFTs>();
    const [spinner, setSpinner] = useState(false);
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();

    const isNFTLoading = useSelector(selectSCIsNFTMinting(nft));
    
    
    const calculateBlockchain = (blockchain: number) => {
        return BlockchainListAvailable.find(bc => bc.id === blockchain || bc.id_testnet === blockchain)?.imgLogo;
    }

    const isWeb3Contract = useSelector(selectIsWeb3Contract(nft.smartContractId));


    useEffect(() => {

        const getInfoNFT = async (url: string) => {
            if (url !== null && url !== undefined) {
                fetch(url)
                    .then((response) => response.json())
                    .then((nftJson) => {
                        ////console.log("INFO DEL NFT", nftJson);
                        setInfoNFT(nftJson);
                        setSpinner(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setSpinner(false);
                    });
            }
        }

        setSpinner(true);
        getInfoNFT(nft.json);

    }, [nft, burneable]);

    return (
        <>
            {spinner  &&
                <div className="col-3 mb-4">
                    <div className="card-body d-flex justify-content-center">
                        <div className="spinner-border m-5 text-danger" role="status">
                            <span className="visually-hidden">{t('forms.NFTCard.loading')}</span>
                        </div>
                    </div>
                </div>
            }
            {!spinner && infoNFT && nft && (
                <div className="col-10 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-8 mb-4">
                    <div className={'nft-container ' + (nft.statusBurned === STATUS_IN_BC.MINTED ? "nft__item style-2 burned " : "nft__item style-2")}>
                        <div className={[STATUS_IN_BC.PENDING, STATUS_IN_BC.REJECTED, STATUS_IN_BC.FAILED].includes(nft.status) ? "author_list_nft_pending" : "author_list_nft"}>
                            {nft.status === STATUS_IN_BC.MINTED && !nft.statusBurned && (
                                <span onClick={() => { }}>
                                    <img className="lazy" src={calculateBlockchain(nft.blockchain)} alt="" />
                                    <i className="fa fa-check"></i>
                                </span>
                            )}

                            {nft.statusBurned === STATUS_IN_BC.MINTED && (
                                <span onClick={() => { }}>
                                    <img className="lazy" src="/img/blockchains/onlyLogo/burn.png" alt="" />
                                </span>
                            )}
                        </div>
                        <div className="nft__item_wrap" style={{ height: `200}px` }}>
                            <div className="outer">
                                {/* TODO: CHANGE IN THE FUTURE */}
                                <span>
                                    <img onLoad={onImgLoad} src={nft.isImage || nft.contentType?.includes('image') ? nft.path : "/img/img-defecto.png"} className="lazy nft__item_preview" alt="" />
                                </span>
                            </div>
                        </div>
                        <div className="row nft__item_action">
                            <span >
                                <h4>{infoNFT?.name}</h4>
                                <p>{infoNFT.description}</p>
                            </span>

                        </div>
                        <div className="row">
                            {burneable && !nft.statusBurned && nft.status === STATUS_IN_BC.MINTED && !isNFTLoading && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('forms.NFTCard.Burn')}</Tooltip>}
                            >
                                <div className={burneable && evolveable ? "col-6 col-lg-6 col-md-12 col-sm-12" : "col-12 col-md-12 col-sm-12 d-flex justify-content-center"}>
                                    <BurnButton nft={nft} />
                                </div>
                            </OverlayTrigger>)}
                            
                            {evolveable && nft.status === STATUS_IN_BC.MINTED && !nft.statusBurned && !isNFTLoading && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('forms.NFTCard.evolve')}</Tooltip>}
                            >
                                <div className={burneable && evolveable && !nft.statusBurned ? "col-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end" : "col-12 d-flex justify-content-center"}>
                                    <button className="btn-main" onClick={() => { setIdToEvolve(nft.idNFT); evolveNFT(true); setShowGenerateNFTs(true) }}>{t('forms.NFTCard.Evolve')}</button>
                                </div>
                            </OverlayTrigger>)}

                            {evolveable && nft.isEvolution && [STATUS_IN_BC.PENDING, STATUS_IN_BC.REJECTED].includes(nft.status) && !isNFTLoading && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('forms.NFTCard.mint')}</Tooltip>}
                            >
                                <div className={burneable && evolveable && !nft.statusBurned && !nft.isEvolution ? "col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end" : "col-12 d-flex justify-content-center"}>

                                    <button className="btn-main" onClick={() => { dispatch(evolveNFTsAction({smartContractId: smartContract && smartContract._id ? smartContract._id : '', nft})); evolveNFT(true); }}>{t('forms.NFTCard.Mint')}</button>
                                </div>
                            </OverlayTrigger>)}

                            {[STATUS_IN_BC.PENDING, STATUS_IN_BC.REJECTED].includes(nft.status) && !nft.isEvolution && minteable && !isNFTLoading  && (
                             <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{t('forms.NFTCard.mint')}</Tooltip>}
                            >   
                                <div className={"col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"}>
                                    {isWeb3Contract ?
                                        <MintButton nft={nft} smartContractId={nft.smartContractId} />
                                        :<button className="btn-main" disabled>{t('forms.NFTCard.waitingMint')}</button>
                                    }
                                </div>
                            </OverlayTrigger>)}
                        </div>
                        {isNFTLoading && 
                        <div className="row align-items-center">
                            <div className="col-12 loading-container">
                                <img src={loadingGear} className="loading-gear" alt="minting..." />
                                {/* <div className="col-white">{t('forms.NFTCard.minting')}</div> */}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default NFTCard;

import { BlockchainNetwork, BLOCKCHAIN } from "../../models/blockchains";

export const MetamaskBlockchainListAvailable: BlockchainNetwork[] = [
    {
      id: BLOCKCHAIN.ETHEREUM,
      chainId: 1,
      explorerUrl: 'https://etherscan.io',
      name: 'Ethereum',
      currencyName: 'ETH',
      rpcUrl: 'https://mainnet.infura.io/v3/'
    },
    {
      id: BLOCKCHAIN.RINKEBY, //GOERLI!
      chainId: 5,
      explorerUrl: 'https://goerli.etherscan.io',
      name: 'Goarli ETH Testnet',
      currencyName: 'GoerliETH',
      rpcUrl: 'https://goerli.infura.io/v3/'
    },
    {
      id: BLOCKCHAIN.POLYGON,
      chainId: 137,
      explorerUrl: 'https://polygonscan.com',
      name: 'Polygon',
      currencyName: 'MATIC',
      rpcUrl: 'https://polygon-rpc.com'
    },
    {
      id: BLOCKCHAIN.MUMBAI,
      chainId: 80001,
      explorerUrl: 'https://mumbai.polygonscan.com',
      name: 'Mumbai',
      currencyName: 'MATIC',
      rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    },
    {
      id: BLOCKCHAIN.BSC,
      chainId: 56,
      explorerUrl: 'https://bscscan.com',
      name: 'BSC',
      currencyName: 'BNB',
      rpcUrl: 'https://bsc-dataseed.binance.org'
    },
    {
      id: BLOCKCHAIN.BSC_TESNET,
      chainId: 97,
      explorerUrl: 'https://testnet.bscscan.com',
      name: 'BSC Testnet',
      currencyName: 'BNB',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545'
    },
    {
      id: BLOCKCHAIN.OASIS_TESTNET,
      chainId: 42261,
      explorerUrl: 'https://testnet.explorer.emerald.oasis.dev/',
      name: 'Oasis Testnet',
      currencyName: 'TEST',
      rpcUrl: 'https://testnet.emerald.oasis.dev'
    },
    {
      id: BLOCKCHAIN.OASIS,
      chainId: 42262,
      explorerUrl: 'https://explorer.emerald.oasis.dev',
      name: 'Oasis',
      currencyName: 'ROSE',
      rpcUrl: 'https://emerald.oasis.dev'
    },
    {
      id: BLOCKCHAIN.AVAX_SUBNET_TESTNET,
      chainId: 11111,
      explorerUrl: 'https://subnets.avax.network/wagmi/wagmi-chain-testnet/explorer',
      name: 'SUBNET WAGMI',
      currencyName: 'WAGMI',
      rpcUrl: 'https://subnets.avax.network/wagmi/wagmi-chain-testnet/rpc'
    },
    {
      id: BLOCKCHAIN.AVAX_SUBNET,
      chainId: 11111,
      explorerUrl: 'https://subnets.avax.network/wagmi/wagmi-chain-testnet/explorer',
      name: 'SUBNET WAGMI',
      currencyName: 'WAGMI',
      rpcUrl: 'XXXX'
    },
    {
      id: BLOCKCHAIN.AVAX_TESTNET,
      chainId: 43113,
      explorerUrl: 'https://testnet.snowtrace.io/',
      name: 'AVALANCHE TESTNET',
      currencyName: 'AVAX',
      rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc'
    },
    {
      id: BLOCKCHAIN.AVAX,
      chainId: 43114,
      explorerUrl: 'https://snowtrace.io/',
      name: 'AVALANCHE',
      currencyName: 'AVAX',
      rpcUrl: 'https://snowtrace.io/'
    },
  ];
  
export const CasperBlockchainListAvailable: BlockchainNetwork[] = [
    {
      id: BLOCKCHAIN.CASPER_TESTNET,
      chainId: 7,
      explorerUrl: 'https://testnet.cspr.live/',
      name: 'Casper Tesnet',
      currencyName: 'CSPR',
      rpcUrl: 'https://testnet.casper.pyxinfra.com:7777/rpc'
    },
    {
      id: BLOCKCHAIN.CASPER,
      chainId: 8,
      explorerUrl: 'https://cspr.live/',
      name: 'Casper Network',
      currencyName: 'CSPR',
      // rpcUrl: 'https://mainnet.casper.pyxinfra.com'
      rpcUrl: 'https://mainnet.casper.pyxinfra.com:7777/rpc'
    },
];
import NFTCard from "./NFTCard/NFTCard";
import { useTranslation } from "react-i18next";


function NFTsList({ spinner, nfts, setShowGenerateNFTs, evolveNFT, burneable, evolveable, setIdToEvolve, smartContract, generateErrorData, setSpinnerMinting, minteable}: any) {
  const { t } = useTranslation('common');
  return (
    <>
      {spinner &&
        <div className="row">
          <div className="card-body d-flex justify-content-center">
            <div className="spinner-border m-5 text-danger" role="status">
              <span className="visually-hidden">{t('forms.NFTsList.loading')}</span>
            </div>
          </div>
        </div>
      }
      {!spinner && nfts.length > 0 && nfts.map((nft: any, index: number) => (
        <NFTCard
          key={index}
          nft={nft}
          onImgLoad={() => { }}
          evolveNFT={evolveNFT}
          setShowGenerateNFTs={setShowGenerateNFTs}
          burneable={burneable}
          evolveable={evolveable}
          setIdToEvolve={setIdToEvolve}
          smartContract={smartContract}
          generateErrorData={generateErrorData}
          setSpinnerMinting={setSpinnerMinting}
          minteable={minteable}
        />
      ))}
      {!spinner && nfts.length === 0 && (
        <div className="row d-flex justify-content-center">
          <h1 className="text-center mb-5">{t('forms.NFTsList.you')}</h1>
        </div>
      )}
    </>)
}

export default NFTsList;
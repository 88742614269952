import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";
import { selectJWT, UserInfo, WalletsUser } from "../features/user/user";
import FileService, { FileType } from "./file.service";
import { LOGIN_TYPE } from "./blockchain/Web3Connector";

class LoginService {

    JWT = useSelector(selectJWT);
    plainJWT: string = '';
    fileService = new FileService();

    // Auth endpoints
    async validateEmail(email: string) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + '/auth/validate-email', {email});
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    async signUp(user: UserPayload) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + '/auth/signup', user);
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    async signIn(wallet: string, signature: string, loginType?: LOGIN_TYPE) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_EXTERNAL_API_URL! + '/auth/signin', {wallet, signature, loginType});
            return response?.data?.result;
        } catch (error) {
            console.error('Error on sign up');
            return undefined;
        }
    }

    // User endpoints
    async findUserByWalletId(walletId: string) {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/user/${walletId}`);
            return response?.data?.result;
        } catch (error: any) {
            console.error('Error on searching user');
            if (error?.code === "ERR_NETWORK") {
                throw new Error('Connection error, API is down');
            }
            return undefined;
        }
    }

    async updateUserByWalletId(walletId: string, user: UserInfo) {
        try {
            const response = await axios.put(
                process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/user/${walletId}`,
                {user},
                this.getConfigRequest()
            );
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching user');
            return undefined;
        }
    }

    async addWalletToUser( auth : WalletsUser) {
        
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + `/user/attach/user`,
                auth,
                this.getConfigRequest()
            );
            return response?.data?.result;
        } catch (error) {
            console.error('Error on searching user');
            return undefined;
        }
    }

    async updateImageUserByWalletId(walletId: string, file: Blob | string) {
        if (typeof file === 'string' || file instanceof String) {
            const fileResponse = await fetch(file as string, {
            });
            file = await fileResponse.blob();
            this.fileService.plainJWT = this.plainJWT;
        }
        const imgName = await this.fileService.uploadFile(file, FileType.PROFILE_IMG);
        await this.updateUserByWalletId(walletId, {img: imgName})
    }

    getConfigRequest (): AxiosRequestConfig {
        return {
            headers: {
                authorization: this.JWT || this.plainJWT
            }
        };
    }

}

export interface UserPayload {
    wallet: string;
    email :  string;
    loginType: string;
    name?:  string;
    url?:  string;
    img?:  string;
    banner?:  string;
    idToken?: string;
    signature?: string;
    pin?: string;
}

export default LoginService;
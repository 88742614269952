import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useNavigate } from 'react-router-dom';
import analyticsService from '../services/analytics.service';
import { useAppDispatch } from '../app/hooks';
import { UserInfo, selectUserInfo } from "../features/user/user";
import { useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { SmartContract } from '../models/smartContract';
import useDashboard from '../views/Dashboard/useDashboard';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { setNotification } from '../features/dashboard/dashboardReducer';
import { Tooltip } from '@mui/material';
import useEvents from './useEvents';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function MTMDrawer({open, setOpen}: {open: boolean, setOpen: (value: boolean) => void}) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userInfo: UserInfo = useSelector(selectUserInfo);
    const theme = useTheme();

    const [dashboard] = useDashboard();
    useEvents();

    const [openSCs, setOpenSCs] = React.useState(false);

    const handleClickSCs = () => {
        setOpenSCs(!openSCs);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenSCs(false);
    };

    const replaceImage = (error: any) => {
        error.target.src = "/img/dashboard/profile.png";
    }


    return (
        (<Drawer variant="permanent" open={open} className=' header-style bg-dark-mtm' >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List >
                <ListItem key={'Welcome'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={userInfo.img ? userInfo.img : "/img/dashboard/profile.png"} onError={replaceImage} alt="" width="22" height="22" className="rounded-circle" />
                        </ListItemIcon>
                        <ListItemText className="" primary={userInfo.name ? userInfo.name : 'Welcome'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Dashboard'} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        onClick={() => navigate('/dashboard')}
                    >
                        <Tooltip title={'Dashboard'} placement="right">
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <i className={'fa fa-desktop icon-white'} ></i>
                        </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Dashboard'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItemButton onClick={handleClickSCs}>
                    <Tooltip title={'Collections'} placement="right">
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Collections" />
                    {openSCs ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSCs} unmountOnExit>
                    <List component="div" disablePadding>
                        {dashboard.smartContractsSigned && dashboard.smartContractsSigned.map((smartContract: SmartContract) => (
                            <ListItemButton key={smartContract._id} sx={{ pl: 4 }}>
                                <Tooltip title={smartContract.name} placement="right">
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <i className={'fa fa-id-card icon-white'} ></i>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={smartContract.name + ' (' + smartContract.symbol + ')'}  onClick={() => { analyticsService.click('Smart Contract Details'); navigate('/details/' + smartContract._id) }} />
                            </ListItemButton>
                        ))}
                        {dashboard.smartContracts && dashboard.smartContracts.map((smartContract: SmartContract) => (
                            <ListItemButton key={smartContract._id} sx={{ pl: 4 }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <i className={'fa fa-id-card icon-white'} ></i>
                                </ListItemIcon>
                                <ListItemText primary={smartContract.name + ' (' + smartContract.symbol + ')'} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
                <ListItem  key={'Analytics'} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <Tooltip title={'Analytics'} placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => dispatch(setNotification({title : 'Not available in your plan', description: 'Contact with support if you want to have access for this feature'}))}
                                >
                                    <i className={'fa fa-bar-chart icon-white'} ></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={'Analytics'} sx={{ opacity: open ? 1 : 0 }} onClick={() => dispatch(setNotification({title : 'Not available in your plan', description: 'Contact with support if you want to have access for this feature'}))} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem  key={'Settings'} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <Tooltip title={'Settings'} placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => dispatch(setNotification({title : 'Not available in your plan', description: 'Contact with support if you want to have access for this feature'}))} 
                                >
                                    <i className={'fa fa-cogs icon-white'} ></i>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={'Settings'} sx={{ opacity: open ? 1 : 0 }} onClick={() => dispatch(setNotification({title : 'Not available in your plan', description: 'Contact with support if you want to have access for this feature'}))} />
                        </ListItemButton>
                    </ListItem>
                
            </List>
            <Divider />
        </Drawer>)
    );
}

export default MTMDrawer;
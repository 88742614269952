import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './apiKeyModal.scss';
import Form from 'react-bootstrap/Form';
import { handleActiveCampaignSubmit } from 'active-campaign-react';
import { useEffect, useState } from 'react';
import AnalyticsService from '../../../services/analytics.service';
import { useTranslation } from "react-i18next";

function ApiKeyModal({ apiKey, email, show, setShow, onSubmit }: ApiKeyModalProps) {

  const analyticsService = new AnalyticsService();
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (show) { analyticsService.modal('Show Apikey'); }
    if (!show) return;
    const data: any = {
      email
    };

    const fieldApiKey: string = process.env.REACT_APP_API_KEY_FIELD! ? process.env.REACT_APP_API_KEY_FIELD! : "field[16]";
    data[fieldApiKey] = apiKey;
    const envField: string = process.env.REACT_APP_ENVIRONMENT_FIELD! ? process.env.REACT_APP_ENVIRONMENT_FIELD! : "field[17]";
    data[envField] = window.location.origin;
    data['field[22][]'] = 'Yes';
    handleActiveCampaignSubmit(data, 'onblocks', process.env.REACT_APP_FORM_ID_AC! ? process.env.REACT_APP_FORM_ID_AC! : '19');
  }, [apiKey, email, show]);
  const { t } = useTranslation('common');
  const handleClose = () => setShow(false);
  const finishRegister = () => {
    handleClose();
    onSubmit();
  };

  return (
    <Modal

      className='modal-container'
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('login.apikeymodal.your')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label style={{color : 'white'}}> <span >Copy your unique API-KEY. This key will not be available after this message</span></Form.Label>
          <Form.Control value={apiKey} readOnly type="text" />
          <Form.Text muted>{t('login.apikeymodal.copy')} </Form.Text>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn-main' onClick={ () => {navigator.clipboard.writeText(apiKey) ; setCopied(true)}}> {copied ? 'Copied' : 'Copy'} <i className="bi bi-clipboard-check-fill pointer" style={{color : 'white'}} data-toggle="tooltip" data-placement="top" title='Copy API-KEY'></i></button>
        <button className='btn-main' onClick={finishRegister}>Done</button>
      </Modal.Footer>
    </Modal>
  );
}

interface ApiKeyModalProps {
  apiKey: string;
  email: string;
  show: boolean;
  setShow: (value: boolean) => void;
  onSubmit: () => void;
}

export default ApiKeyModal;
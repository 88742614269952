import { t } from "i18next";
import { burnNFTsAction } from "../../actions/smartContractDetails/burn/burnNFTsAction";
import { burnNFTsMetamaskAction } from "../../actions/smartContractDetails/burn/burnNFTsMetamaskAction";
import { setNotification } from "../../features/dashboard/dashboardReducer";
import { isSelectedWallet } from "../../services/blockchain/BlockchainConnector";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { selectIsWeb3Contract } from "../../features/user/user";
import { selectSmartContractDetailsChainId, selectSmartContractWalletOwner } from "../../features/smartContractDetail/smartContractDetailSelectors";
import { NFTView } from "../../models/nfts";
import { Spinner } from "react-bootstrap";

function BurnButton({nft}: {nft: NFTView}) {
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    
    const isWeb3Contract = useSelector(selectIsWeb3Contract(nft.smartContractId));
    const chainId = useSelector(selectSmartContractDetailsChainId(nft.smartContractId));
    const walletOwner = useSelector(selectSmartContractWalletOwner(nft.smartContractId));

    return (
        <button className="btn-main" disabled={nft.burning}
            onClick={() => dispatch(setNotification({
                title: t('forms.NFTCard.burnNotificationTitle'),
                description: t('forms.NFTCard.burnNotificationDesc'),
                actionButton: {
                    text: t('forms.NFTCard.burnNotificationTitle'),
                    action: () => dispatch(burnNFTsAction(nft))
                },
                extraActionButton: isWeb3Contract ? {
                    text: t('forms.NFTCard.burnNotificationButton'),
                    action: () => dispatch(burnNFTsMetamaskAction(nft)),
                    validator: () => isSelectedWallet(walletOwner, chainId)
                } : undefined,
                errorText: (
                    <>
                        {t('scdetails.walletOwner')} {walletOwner}<br/>
                        {t('scdetails.changeWallet')}
                    </>
                )
        }))}>
            {!nft.burning ? t('forms.NFTCard.burn') : t('forms.NFTCard.burning')}         
        </button>
    );
}

export default BurnButton;
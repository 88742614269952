import {
    SmartContractDetail, modifySCDetails, setBurnNFT
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import AnalyticsService from "../../../services/analytics.service";
import {initializeContract} from "../../../utils/blockchainUtils";
import NFTService from "../../../services/nft.service";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { NFTBurnable, NFTView } from "../../../models/nfts";

export const burnNFTsMetamaskAction = createAsyncThunk('SC/burnNFTsMetamask', async (nft: NFTView, thunkAPI: any) => {

    AnalyticsService.click("SDC: Burn NFTs");
    const {smartContract, nfts}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[nft.smartContractId];

    const overrides = {
        gasLimit: 16721970,
    };

    if (smartContract && smartContract._id && smartContract.smartContractAddress && smartContract.blockchainId && nft._id) {
        try {
            thunkAPI.dispatch(setBurnNFT({smartContractId: nft.smartContractId, idNFT: nft.idNFT, burning: true}));

            const contract = await initializeContract(smartContract.smartContractAddress, smartContract.blockchainId);

            const isBurnable : NFTBurnable = await NFTService.getInstance().isBurnable(smartContract._id, nft._id);
            
            if(!isBurnable.burnable){
                throw new Error('Not Burnable');
            }
            
            const response = await contract.burn(isBurnable.owner ? isBurnable.owner : nft.wallet, nft.idNFT, overrides);
            await response.wait(1);
            await NFTService.getInstance().signBurn(smartContract._id ? smartContract._id : '', nft);
            AnalyticsService.transaction("SDC:(BC) Burned NFT");
            thunkAPI.dispatch(setBurnNFT({smartContractId: nft.smartContractId, idNFT: nft.idNFT, burning: false}));

        } catch (error: any) {
            AnalyticsService.error("SDC:(BC) Burning NFTs(" + error.reason + ")" + "Blockchain error -> " + error.reason);
            thunkAPI.dispatch(setBurnNFT({smartContractId: nft.smartContractId, idNFT: nft.idNFT, burning: false, error: true}));
        }
    }
});

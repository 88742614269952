import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/hooks";
import { selectUserInfo, selectWallet, setUserInfo, UserInfo } from "../features/user/user";
import LoginService from "../services/login.service";
import './Profile.scss';
import { useTranslation } from "react-i18next";

function Profile() {

    const loginService = new LoginService();
    const wallet = useSelector(selectWallet);
    const userInfo: UserInfo = useSelector(selectUserInfo);
    const [editMode, setEditMode] = useState(false);
    const [isCopied, setCopied] = useState(false);
    const [userNameChange, setUserNameChange] = useState(userInfo.name);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');

    const saveName = async () => {
        await loginService.updateUserByWalletId(wallet, { name: userNameChange })
        dispatch(setUserInfo({ ...userInfo, name: userNameChange }));
        setEditMode(false);
    };

    const saveImg = async (files: FileList | null) => {
        if (files && files.length > 0 && files[0]) {
            await loginService.updateImageUserByWalletId(wallet, files[0]);
            dispatch(setUserInfo({ ...userInfo, img: URL.createObjectURL(files[0] as Blob) }));
        }
    };;

    const copyWallet = () => {
        navigator.clipboard.writeText(wallet);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
    };

    const replaceImage = (error: any) => {
        //replacement of broken Image
        error.target.src = "/img/dashboard/profile.png";
    }

    return (
        <>
            <div className='row '>
                <div className="col-12 mb-2">
                    <div className="d_profile">
                        <div className="d-flex-col">
                            <div className="profile_avatar ">
                                <div className="container_img ">
                                    <img src={userInfo.img ? userInfo.img : "/img/dashboard/profile.png"} onError={replaceImage} alt="profile-user" />
                                    {/* <FontAwesomeIcon icon="fa-thin fa-user-vneck-hair" /> */}
                                    {/* {userInfo.img ? 
                                    <i className="fa fa-check"></i> : */}
                                    <label onClick={() => { }} className="fa fa-edit">
                                        <input id='upload_file' type="file" onChange={({ target }) => saveImg(target?.files)} />
                                    </label>
                                </div>
                                <div className="profile_name_dash mt-3">
                                    <h4>
                                        {!editMode ?
                                            <div>
                                                {userInfo.name ? userInfo.name : 'Your name'}
                                                <button id="btn_copy" title="Edit Name" onClick={() => setEditMode(true)}> <div className="fa fa-edit"></div></button>
                                            </div> : null}
                                        {editMode &&
                                            <div>
                                                <input type="text" name="userName" id="userName" className="form-control input-inline" placeholder="Name" value={userNameChange} onChange={({ target }) => setUserNameChange(target.value)} />
                                                <button id="btn_copy" title="Save" onClick={() => saveName()}> <div className="fa">{t('forms.profile.Save')}</div></button>
                                            </div>
                                        }
                                        {/* <span className="profile_username">{userInfo.url ? userInfo.url : '@username'}</span> */}
                                        <span id="wallet" className="profile_wallet">{wallet} &nbsp;</span>
                                        <button id="btn_copy" title="Copy Address" disabled={isCopied} onClick={copyWallet}> {isCopied ? 'Copied' : 'Copy'}</button>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;
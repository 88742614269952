import { createAsyncThunk } from "@reduxjs/toolkit";
import { SmartContractDetail, setBurnNFT } from "../../../features/smartContractDetail/smartContractDetailsReducer";
import { NFTView } from "../../../models/nfts";
import NFTService from "../../../services/nft.service";
import { UserState } from "../../../features/user/user";
import { burnNFTsMetamaskAction } from "./burnNFTsMetamaskAction";


export const burnNFTsAction = createAsyncThunk('SC/burnNFTs', async (nft: NFTView, thunkAPI) => {

    thunkAPI.dispatch(setBurnNFT({smartContractId: nft.smartContractId, idNFT: nft.idNFT, burning: true}));
    await NFTService.getInstance().scheduleBurn(nft.smartContractId, nft, false);
    
});

import AnalyticsService from "../../../services/analytics.service";
import {
    setPriceShowModal,
    setSupplyShowModal
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { pauseMintAction } from "../../../actions/smartContractDetails/pauseMintAction";
import { blockMarketAction } from "../../../actions/smartContractDetails/blockMarketAction";
import { burnableAction } from "../../../actions/smartContractDetails/burn/burnToggle";
import { pauseTransferAction } from "../../../actions/smartContractDetails/pauseTransferAction";
import { toggleEvolveNFTsAction } from "../../../actions/smartContractDetails/toggleEvolveNFTsAction";
import { useAppDispatch } from "../../../app/hooks";
import { useSelector } from "react-redux";
import { pauseMarketAction } from "../../../actions/smartContractDetails/pauseMarketAction";
import { selectSCConfig, selectSCData, selectSCSummary } from "../../../features/smartContractDetail/smartContractDetailSelectors";
import { useTranslation } from "react-i18next";
import { SMART_CONTRACT_TYPE } from "../../../models/smartContract";

interface SCDetailsConfigProps {
    smartContractId: string;
    releaseBalance: any
}

function SCDetailsConfig({ smartContractId, releaseBalance }: SCDetailsConfigProps) {

    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();
    const config = useSelector(selectSCConfig(smartContractId));
    const contract = useSelector(selectSCData(smartContractId));
    const [price, supply, balance] = useSelector(selectSCSummary(smartContractId));
    const isSCDeployed = smartContractId && contract?.smartContractAddress && contract?.blockchainId;


    return (
        <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="de_tab tab_methods mb-2 ">
                <ul className="de_nav">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Price for your NFTs</Tooltip>}>
                        <li className={contract.configuration?.mintPrice ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch" onClick={() => {
                                if (!isSCDeployed) return;
                                AnalyticsService.click("SDC: Set Price");
                                dispatch(setPriceShowModal({ smartContractId, show: true }));
                            }}>
                                {t('scdetails.Mint-price')}
                                <br /><i className="fa fa-tag mt-1"></i>
                                {isSCDeployed && (
                                    <button className="btn-main btn-features">{t('scdetails.Set-Price')}</button>)}
                            </span>
                        </li>
                    </OverlayTrigger>
                    {contract.configuration?.dynamicSupply && (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">Allows you to modify the maximum number of NFTs available in your Collection</Tooltip>}>
                            <li className={contract.configuration?.dynamicSupply ? "disabled switch-with-title" : "hide switch-with-title"}>
                                <span className="" onClick={() => {
                                    if (!isSCDeployed) return;
                                    AnalyticsService.click("SDC: Set Supply");
                                    dispatch(setSupplyShowModal({ smartContractId, show: true }));
                                }}>
                                    {t('scdetails.Dynamic-Supply')}
                                    <br />
                                    <i className="fa fa-plus-square-o mt-1"></i>
                                    {isSCDeployed && (
                                        <button className="btn-main btn-features">{t('scdetails.Set-supply')}</button>)}
                                </span>
                            </li>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Collection royalties for sells and secondary market</Tooltip>}
                    >
                        <li className={contract.configuration?.percentFees ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch pt-1">
                                {t('scdetails.Royalties')}
                                <br /><i className="fa fa-money mt-1" ></i>
                                {contract.configuration?.percentFees !== 0 && (<p><b>{t('scdetails.Fees')}</b>&nbsp;{contract.configuration?.percentFees?.toFixed(2)}%</p>)}
                            </span>

                        </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to add a list of users with early access to your Collection</Tooltip>}
                    >
                        <li className={contract.configuration?.whiteList ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.White-List')}
                                <i className="fa fa-list mt-1"></i>
                                <input type="checkbox" id="switch-unlock1" className="checkbox" />
                                {true ? ( //TODO: ???????
                                    <label htmlFor="switch-unlock1" onClick={() => { }}></label>
                                ) : (
                                    <label htmlFor="switch-unlock1" onClick={() => { }}></label>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>
                    <li className={contract.configuration?.dateInitMint ? "disabled switch-with-title" : "hide switch-with-title"}>
                        <span>
                            {t('scdetails.Custom-Mint')}
                            <i className="fa fa-calendar"></i>
                            <input type="checkbox" id="switch-unlock" className="checkbox" />
                            {true ? (
                                <label htmlFor="switch-unlock" onClick={() => { }}></label>
                            ) : (
                                <label htmlFor="switch-unlock" onClick={() => { }}></label>
                            )}
                        </span>
                    </li>
                    {contract.smartContractType !== SMART_CONTRACT_TYPE.MTM_1155_MIN &&  (<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to pause the secondary market (reselling) of NFTs for a period of time</Tooltip>}>
                        <li className={contract.configuration?.mintPausable ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.allowMint')}
                                <i className="fa fa-file-video-o mt-1"></i>
                                {config?.mintPausable?.loading && isSCDeployed &&
                                    <Spinner animation="grow" variant="danger" />
                                }
                                {!config?.mintPausable?.loading && (
                                    <input type="checkbox" id="switch-unlock" className="checkbox" readOnly checked={config?.mintPausable?.value} />
                                )}
                                {!config?.mintPausable?.loading && (
                                    <label htmlFor="switch-unlock" onClick={() => dispatch(pauseMintAction({ smartContractId, pause: !config?.mintPausable?.value }))}></label>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>)}
                    {contract.configuration?.marketPausable && (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">Allows you to pause the secondary market (reselling) of NFTs for a period of time</Tooltip>}
                        >
                            <li onClick={() => { }} className={contract.configuration?.marketPausable ? "disabled switch-with-title" : "hide switch-with-title"}>
                                <span className="de-switch">
                                    {t('scdetails.allowMarket')}
                                    <i className="fa fa-shopping-bag mt-1"></i>
                                    {config?.marketPausable?.loading && isSCDeployed &&
                                        <Spinner animation="grow" variant="danger" />
                                    }
                                    {!config?.marketPausable?.loading && (
                                        <input type="checkbox" id="switch-unlock2" data-toggle="toggle" data-onstyle="primary" className="checkbox" readOnly checked={config?.marketPausable?.value} />
                                    )}
                                    {!config?.marketPausable?.loading && (
                                        <label htmlFor="switch-unlock" onClick={() => dispatch(pauseMarketAction({ smartContractId, pause: !config?.marketPausable?.value }))}></label>
                                    )}
                                </span>
                            </li>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to burn (delete) NFTs. The NFT will no longer be available on the Blockchain</Tooltip>}>
                        <li onClick={() => { }} className={contract.configuration?.burnNFTs ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.burnNFTs')}
                                <i className="fa fa-fire mt-1"></i>
                                {config?.burnNFTs?.loading && isSCDeployed &&
                                    <Spinner animation="grow" variant="danger" />
                                }
                                {!config?.burnNFTs?.loading && (
                                    <input type="checkbox" id="switch-unlock2" className="checkbox" readOnly checked={config?.burnNFTs?.value} />
                                )}
                                {!config?.burnNFTs?.loading && (
                                    <label htmlFor="switch-unlock" onClick={() => dispatch(burnableAction({ smartContractId, pause: !config?.burnNFTs?.value }))}></label>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>
                    {/* <li onClick={() => setConfigValue("transferCustom")} className={smartContractData.smartContract.configuration.transferCustom ? "disabled switch-with-title" : "hide switch-with-title"}><span><i className="fa fa-exchange"></i>Transfer custom</span>
                                    </li> */}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to disable the transfer of NFTs. (This also disables secondary nfts market like "pause market")</Tooltip>}
                    >
                        <li onClick={() => { }} className={contract.configuration?.transferPausable ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.transfer')}
                                <i className="fa fa-exchange mt-1"></i>
                                {config?.transferPausable?.loading && isSCDeployed &&
                                    <Spinner animation="grow" variant="danger" />
                                }
                                {!config?.transferPausable?.loading && (
                                    <input type="checkbox" id="switch-unlock2" className="checkbox" readOnly checked={config?.transferPausable?.value} />
                                )}
                                {!config?.transferPausable?.loading && (
                                    <label htmlFor="switch-unlock" onClick={() => dispatch(pauseTransferAction({ smartContractId, pause: !config?.transferPausable?.value }))}></label>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>
                    {contract.smartContractType !== SMART_CONTRACT_TYPE.MTM_1155_MIN &&  (<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to evolve NFTs (image/video & metadata) </Tooltip>}
                    >
                        <li onClick={() => { }} className={contract.configuration?.dynamicsNFTs ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.dynamicNFTs')}
                                <i className="fa fa-retweet mt-1"></i>
                                {config?.dynamicNFTs?.loading && isSCDeployed &&
                                    <Spinner animation="grow" variant="danger" />
                                }
                                {!config?.dynamicNFTs?.loading && (
                                    <>
                                    <input type="checkbox" id="switch-unlock2" className="checkbox" readOnly checked={config?.dynamicNFTs?.value} />
                                    <label htmlFor="switch-unlock" onClick={() => dispatch(toggleEvolveNFTsAction({ smartContractId, pause: !config?.dynamicNFTs?.value }))}></label>
                                    </>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>)}
                    {contract.smartContractType === SMART_CONTRACT_TYPE.MTM_1155_MIN &&  (<OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Allows you to evolve NFTs (image/video & metadata) </Tooltip>}
                    >
                        <li onClick={() => { }} className={contract.configuration?.dynamicsNFTs ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.dynamicNFTs')}
                                <i className="fa fa-retweet mt-1"></i>
                                {config?.dynamicNFTs?.loading && isSCDeployed &&
                                    <Spinner animation="grow" variant="danger" />
                                }
                                {!config?.dynamicNFTs?.loading && (
                                    <>
                                    <input type="checkbox" id="switch-unlock2" className="checkbox" readOnly checked={config?.dynamicNFTs?.value} />
                                    </>
                                )}
                            </span>
                        </li>
                    </OverlayTrigger>)}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Release royalties to owner </Tooltip>}>
                        <li onClick={releaseBalance} className={balance !== 0 ? "disabled switch-with-title" : "hide switch-with-title"}>
                            <span className="de-switch">
                                {t('scdetails.releasePayments')}
                                <i className="fa fa-money"></i>
                            </span>
                        </li>
                    </OverlayTrigger>
                </ul>
            </div>
        </div>
    );
}

export default SCDetailsConfig;
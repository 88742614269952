import { useFieldArray, useFormContext } from 'react-hook-form';
import { WalletRoyaltyFormValues } from '../../../models/smartContract';

type propsField = {
  setTotal : any
}

function useWalletRoyaltyFormField({setTotal} : propsField) {
  const { control, register, setValue, getValues,getFieldState, formState: {errors}, setError, clearErrors } = useFormContext<WalletRoyaltyFormValues>();

  const { fields, append, remove } = useFieldArray<WalletRoyaltyFormValues>({
    control,
    name: 'royaltyList'
  });

  const addWallet = (wallet: string, royalties: number) => {
    append({
      wallet,
      royalties
    });
  };

  const updateTotal = () => {
    getTotalRoyalties();
  }
  
  const getTotalRoyalties = () => {
    let newTotal = 0;
    getValues().royaltyList.forEach( roy => newTotal += Number(roy.royalties) );
    setValue( 'total', newTotal);
    setTotal(newTotal);
  }

  const removeWallet = (index: number) => () => {
    remove(index);
    getTotalRoyalties();
  };

  return {
    fields,
    register,
    addWallet,
    removeWallet,
    updateTotal,
    getValues,
    getFieldState,
    errors,
    setError,
    clearErrors
  };
}

export default useWalletRoyaltyFormField;
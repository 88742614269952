import {createAsyncThunk} from "@reduxjs/toolkit";
import {ConfigType, setSCError, setSCSingleConfig, SmartContractDetail,} from "../../features/smartContractDetail/smartContractDetailsReducer";
import {genericMetamaskAction, PausePayload} from "./metamask/genericMetamaskAction";
import { UserState } from "../../features/user/user";
import SmartContractActionsService from "../../services/smartContract-actions.service";
import { SMART_CONTRACT_TYPE } from "../../models/smartContract";

export const pauseMarketAction = createAsyncThunk('SC/pauseMarket', async ({ smartContractId, pause }: PausePayload, thunkAPI) => {

    const user: UserState = (thunkAPI.getState() as any).user;
    const {smartContract}: SmartContractDetail = (thunkAPI.getState() as any).smartContractDetails[smartContractId];
    const wallet = user.userInfo.wallets?.find(wallet => wallet.wallet === smartContract?.walletOwner);
    if (wallet?.loginType === 'metamask') { //TODO: Handle multiple metamask accounts
        await genericMetamaskAction({
            smartContractId,
            pause,
            configKey: ConfigType.marketPausable,
            contractPauseAction: 'allowMarket',
            contractUnPauseAction: 'allowMarket',
            params: [pause],
            transactionMessage: 'market pausable'
        }, thunkAPI);
    } else {
        thunkAPI.dispatch(setSCSingleConfig({smartContractId, configKey: ConfigType.marketPausable ,loading: true}));
        try {
            await SmartContractActionsService.getInstance().smartContractSetPauseMarket(smartContractId, pause);
            thunkAPI.dispatch(setSCSingleConfig({smartContractId, configKey: ConfigType.marketPausable ,loading: false, value: pause}));
        }
        catch (completed) {
            thunkAPI.dispatch(setSCError({smartContractId: smartContractId, error: 'Error pause market'}));
            thunkAPI.dispatch(setSCSingleConfig({smartContractId, configKey: ConfigType.marketPausable ,loading: false}));
        }
    }
});

import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style.scss';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './views/Home/Home';
import Footer from './components/Footer';
import Login from './views/Login/Login';
import Dashboard from './views/Dashboard/Dashboard';
import { Provider } from 'react-redux';
import { store } from './app/store';
import SmartContractDetails from './views/SmartContractDetails/SmartContractDetails';
import * as amplitude from '@amplitude/analytics-browser';
import MintByUser from './views/MintByUser/MintByUser';
import * as Sentry from "@sentry/react";
import Header from './components/Header';
import NewHeader from './components/NewHeader';
import { Box } from '@mui/material';
import MintByEmail from './views/MintByUser/MintByEmail';

Sentry.init({
    release: process.env.npm_package_version,
    dsn: process.env.REACT_APP_SENTRY_TOKEN_ACCESS,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      common: common_en
    },
    es: {
      common: common_es
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// amplitude.init('d10db63ac295d1d1f26d207c27573c4a');

root.render(
  <>
    <Provider store={store}>
    <div className="wraper d-flex">
      <I18nextProvider i18n={i18next}>
        <Router>
            <NewHeader />
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path="/details">
                <Route 
                    path=":smartContractId" 
                    element={
                        <SmartContractDetails />
                    } 
                />
                </Route>
                <Route path='/claim' element={<MintByEmail />} />
                <Route path="/mint">
                    
                <Route 
                    path=":smartContractId" 
                    element={
                        <MintByUser />
                    } 
                />
                </Route>
                <Route path='*' element={<Navigate to='/' replace/>} />
            </Routes>
            <Footer/>
            {/* <Footer/> */}
        </Router>
      </I18nextProvider>
    </div>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { handleActiveCampaignSubmit } from "active-campaign-react";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { selectJWT, selectUserInfo, selectWallet, logout, UserInfo } from "../features/user/user";
import { useTranslation } from "react-i18next";
import './Footer.scss';
import Notification from "../components/Notification";

function Footer() {

    const jwt = useSelector(selectJWT);
    const wallet = useSelector(selectWallet);
    const userInfo: UserInfo = useSelector(selectUserInfo);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const { t } = useTranslation('common');
    const handleClose = () => setShow(false);
    const [succesMessage, setSuccesMessage] = useState<string>('');


    const sendFeedback = (e: React.SyntheticEvent) => {
        e.preventDefault();


        const target = e.target as typeof e.target & {
            email: { value: string };
            feedback: { value: string };
        }
        const data: any = {
            email: userInfo.email ? userInfo.email : target.email.value,
            'field[18]': target.feedback.value
        };

        const envField: string = process.env.REACT_APP_ENVIRONMENT_FIELD! ? process.env.REACT_APP_ENVIRONMENT_FIELD! : "field[17]";
        data[envField] = window.location.origin;

        handleActiveCampaignSubmit(data, 'onblocks', '25');
        setSuccesMessage("Your feedback has been sent correctly");
        setTimeout(() => setSuccesMessage(''), 3000);
        handleClose();
    }

    useEffect(() => {

        try {
            (window as any).ethereum?.on('accountsChanged', function (accounts: any) {
                //TODO launch modal for control accounts
                // if (wallet !== '' && window.location.pathname !== '/login' && wallet !== accounts[0]) {
                //     AnalyticsService.click("Change Account Medamask");
                //     dispatch(logout())
                //     navigate('/');
                // }
            })
        } catch (error) {
            console.error(error);
        }


    }, [navigate, wallet, jwt, dispatch]);

    return (
        <footer className="footer-light">
            <div className="subfooter">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6">
                            {/* <span className="copy">&copy; {t('forms.footer.minteandome')}</span> */}
                        </div>
                        <div className="col-6 footer-social-icons">
                            <div className="social-icons">
                                <span onClick={() => window.open("https://twitter.com/minteandome", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
                                <span onClick={() => window.open("https://www.linkedin.com/company/minteandome/", "_blank")}><i className="fa fa-linkedin fa-lg"></i></span>
                                <span onClick={() => window.open("https://discord.gg/n7GGPfP9Hb", "_blank")}><i className="fa fa-rss fa-lg"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter-floating">
                <button onClick={() => setShow(true)} className="btn-main">&nbsp;<i className="fa icon_chat_alt"></i></button>
            </div>
            {show && (
                <Modal
                    className='modal-container'
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton >
                        <Modal.Title className="col-11" >
                            <h3 className="text-center">
                                {t('forms.footer.send')}
                            </h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={sendFeedback}>
                            {!jwt && (<Form.Group className="mb-3" controlId='email'>
                                <Form.Label><span style={{ color: 'white' }}>Email address</span></Form.Label>
                                <Form.Control type="email" placeholder="your@mail.com" />
                            </Form.Group>)}
                            <Form.Group className="mb-3" controlId='feedback'>
                                <Form.Label><span style={{ color: 'white' }}>Your question or feedback</span></Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            {/* <Form.Control value={feedBack} onChange={(event: { target: { value: string; }; }) => setFeedback(event.target?.value)} type="text" placeholder="Your comments..." /> */}
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn-main">Send</button>
                                </div>

                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
            {succesMessage !== '' && (
                <Notification
                    title={succesMessage}
                    description={succesMessage}
                    show={succesMessage !== ''}
                    close={() => setSuccesMessage('')}
                    severity="success"
                />
            )}
        </footer>
    )
};
export default Footer;
import { BLOCKCHAIN, BlockchainInfo } from "../models/blockchains";
import {ethers} from "ethers";
import {SmartContractConfig} from "../features/smartContractDetail/smartContractDetailsReducer";
import {SMART_CONTRACT_TYPE, SmartContract} from "../models/smartContract";
import BlockchainConnectService from "../services/blockchainConnect.service";

const BlockchainListAvailable: BlockchainInfo[] = [
    {
        name: "Ethereum",
        img: "/img/blockchains/white/eth.png",
        imgLogo: "/img/blockchains/onlyLogo/ethereum.png",
        id: 1,
        id_testnet: 2,
        selected: false,
        coin: "ETHER",
        urlScan: 'https://etherscan.io/token/',
        urlScanTesnet: 'https://goerli.etherscan.io/token/'
    },
    {
        name: "Polygon",
        img: "/img/blockchains/white/polygon.png",
        imgLogo: "/img/blockchains/onlyLogo/polygon.png",
        id: 3,
        id_testnet: 4,
        selected: false,
        coin: "MATIC",
        urlScan: 'https://polygonscan.com/token/',
        urlScanTesnet: 'https://mumbai.polygonscan.com/token/'
    },
    {
        name: "Binance Smart Chain",
        img: "/img/blockchains/white/bsc.png",
        imgLogo: "/img/blockchains/onlyLogo/bsc.png",
        id: 5,
        id_testnet: 6,
        selected: false,
        coin: "BNB",
        urlScan: 'https://bscscan.com/token/',
        urlScanTesnet: 'https://testnet.bscscan.com/token/'
    },
    {
        name: "Casper Network",
        img: "/img/blockchains/white/casper.png",
        imgLogo: "/img/blockchains/onlyLogo/casper.png",
        id: BLOCKCHAIN.CASPER,
        id_testnet: BLOCKCHAIN.CASPER_TESTNET,
        selected: false,
        coin: "CSPR",
        urlScan: 'https://cspr.live/contract/',
        urlScanTesnet: 'https://testnet.cspr.live/contract/'
    }
];

export const calculateBlockchain = (blockchain: number) => {
    return BlockchainListAvailable.find(bc => bc.id === blockchain || bc.id_testnet === blockchain)?.img;
}

export const calculateCoin = (blockchain: number) => {
    return BlockchainListAvailable.find(bc => bc.id === blockchain || bc.id_testnet === blockchain)?.coin;
}

export const calculateScan = (blockchain: number, address : string) => {
    const bc : BlockchainInfo = BlockchainListAvailable.find(bc => bc.id === blockchain || bc.id_testnet === blockchain)!;
    if (bc.name === 'Casper Network') {
        address = address.split('-')[1]; // Remove hash-
    }
    return blockchain === bc.id_testnet ? bc.urlScanTesnet + address : bc.urlScan + address;
}

export const initializeContract = async (smartContractAddress : string, blockchainId : string, type? : SMART_CONTRACT_TYPE) => {
    const smartContractABI = require(type && (type === 3) ? './../smartContracts/smartContractMinGeneric.json' : './../smartContracts/smartContractGeneric.json')?.abi;
    const provider = await getProvider(blockchainId);
    const signer = provider.getSigner();
    return new ethers.Contract(smartContractAddress, smartContractABI, signer);
}

export const getProvider = async (blockchainId: string) => {
    const chain = parseInt(blockchainId, 10);
    await BlockchainConnectService.getConnection(chain);
    return new ethers.providers.Web3Provider((window as any).ethereum);
}

export const getConfigFromSmartContract = (smartContract: SmartContract, config: any): SmartContractConfig => {

    if(!config) return {
        burnNFTs: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.burnNFTs
        },
        marketPausable: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.marketPausable
        },
        mintPausable: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.mintPausable
        },
        marketBlocked: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.marketBlocked
        },
        transferPausable: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.transferPausable
        },
        dynamicNFTs: {
            loading: false,
            value: true,
            isAllowed: smartContract.configuration?.dynamicsNFTs
        },
        dynamicSupply: {
            isAllowed: smartContract.configuration?.dynamicSupply
        },
        mintPrice: {
            isAllowed: !!smartContract.configuration?.mintPrice
        },
        whiteList: {
            isAllowed: !!smartContract.configuration?.whiteList
        },
        percentFees: {
            isAllowed: !!smartContract.configuration?.percentFees,
            value: smartContract.configuration?.percentFees
        },
        dateInitMint: {
            isAllowed: !!smartContract.configuration?.dateInitMint
        }
    }
    return {
        burnNFTs: {
            loading: false,
            value: config[0],
            isAllowed: smartContract.configuration?.burnNFTs
        },
        marketPausable: {
            loading: false,
            value: config[1],
            isAllowed: smartContract.configuration?.marketPausable
        },
        mintPausable: {
            loading: false,
            value: config[2],
            isAllowed: smartContract.configuration?.mintPausable
        },
        marketBlocked: {
            loading: false,
            value: config[3],
            isAllowed: smartContract.configuration?.marketBlocked
        },
        transferPausable: {
            loading: false,
            value: config[4],
            isAllowed: smartContract.configuration?.transferPausable
        },
        dynamicNFTs: {
            loading: false,
            value: config[5],
            isAllowed: smartContract.configuration?.dynamicsNFTs
        },
        dynamicSupply: {
            isAllowed: smartContract.configuration?.dynamicSupply
        },
        mintPrice: {
            isAllowed: !!smartContract.configuration?.mintPrice
        },
        whiteList: {
            isAllowed: !!smartContract.configuration?.whiteList
        },
        percentFees: {
            isAllowed: !!smartContract.configuration?.percentFees,
            value: smartContract.configuration?.percentFees
        },
        dateInitMint: {
            isAllowed: !!smartContract.configuration?.dateInitMint
        }
    };
}


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlockchainInfo, STATUS_IN_BC } from "../models/blockchains";
import { SmartContract } from "../models/smartContract";
import AnalyticsService from "../services/analytics.service";
import './card.scss';
import { useTranslation } from "react-i18next";

export const BlockchainListAvailable: BlockchainInfo[] = [
  {
    name: "Ethereum",
    img: "/img/blockchains/white/eth.png",
    imgLogo: "/img/blockchains/onlyLogo/ethereum.png",
    id: 1,
    id_testnet: 2,
    selected: false
  },
  {
    name: "Polygon",
    img: "/img/blockchains/white/polygon.png",
    imgLogo: "/img/blockchains/onlyLogo/polygon.png",
    id: 3,
    id_testnet: 4,
    selected: false
  },
  {
    name: "Binance Smart Chain",
    img: "/img/blockchains/white/bsc.png",
    imgLogo: "/img/blockchains/onlyLogo/bsc.png",
    id: 5,
    id_testnet: 6,
    selected: false
  }
];

type cardSlideProps = {
  avatar?: string;
  banner?: string;
  smartContract?: SmartContract;
  smartContractSigned?: any;
  createNFTs?: any;
  mintNFTs?: any;
}

function CardSlide({ avatar, banner, smartContract, smartContractSigned, createNFTs, mintNFTs }: cardSlideProps) {

  // const [error, setError]=useState("");
  const [spinner, setSpinnerSign] = useState(false);
  const [pending, setPending] = useState(0);
  const [allowGenerate, setAllowGenerate] = useState(true);
  const [sign, setSign] = useState(false);
  const analyticsService = new AnalyticsService();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const calculateBlockchain = (blockchain: number) => {
    return BlockchainListAvailable.find(bc => bc.id === blockchain || bc.id_testnet === blockchain)?.img;
  }

  const signSmartContract = async () => {
    analyticsService.click("Sign Smart Contract");
    setSpinnerSign(true);
    smartContractSigned(smartContract);
  }

  const generateNFTs = async () => {
    analyticsService.click("Generate NFTs");
    navigate(smartContract ? '/details/' + smartContract._id : '');
    // createNFTs(smartContract);
  }

  const tryMintNFTs = () => {
    analyticsService.click("Mint NFTs");
    mintNFTs(smartContract);
  }

  useEffect(() => {
    // //console.log("SC : ", smartContract);
    setSign(smartContract!.status === STATUS_IN_BC.MINTED || smartContract!.includeDeploy ? false : true);
    setSpinnerSign(false);
    const pendingSign = smartContract!.nftsGenerated ? smartContract!.nftsSigned ? smartContract!.nftsGenerated - smartContract!.nftsSigned : smartContract!.nftsGenerated : 0;
    setPending(pendingSign > 0 ? pendingSign : 0);
    setAllowGenerate(smartContract!.nftsGenerated && smartContract!.maxNFTs ? smartContract!.nftsGenerated < smartContract!.maxNFTs : true)
  
  }, [smartContract]);
  return (
    <>
      {smartContract && smartContract.blockchain && (<div className='itm' >
        <div className="nft_coll style-2">
          <div className="nft_pic m-2" onClick={() => { analyticsService.click('Smart Contract Details'); navigate('/details/' + smartContract._id) }}>
            <span><img src={banner} className="lazy img-cards" alt="" /></span>
          </div>
          <div className="nft_coll_pp">
            <span><img className="lazy" src={avatar} alt="" /></span>
            <i className="fa fa-check"></i>
          </div>
          <div className="nft_coll_pp_bc d-flex justify-content-center">
            <span><img className="lazy" src={calculateBlockchain(smartContract.blockchain)} alt="" /></span>
          </div>
          <div className="nft_coll_info mt-2 mb-2">
            <span><h4>{smartContract.name}</h4></span>
            {/* <span className=""><h5>{ description }</h5></span> */}
          </div>
          <div className="row nft_coll_info">
            <span className="col-6"><h4>{t('forms.cardslide.pending-sign')} {smartContract.standard}</h4></span>
            <span className="col-6"><h4>{t('forms.cardslide.supply')} {smartContract.maxNFTs}</h4></span>
            {/* <span className="col-3"><h4>{t('forms.cardslide.total')} {smartContract.nftsGenerated}</h4></span> */}
          </div>
          {sign && !spinner && (smartContract.includeDeploy || smartContract.paid) && (<div className='row mt-4'>
            <div className='col-lg-12'>
              <div className="items_filter">
                <ul className="row">
                  <li id='Mainbtn' className="col-5 active btn-main" onClick={() => { signSmartContract() }} ><span>{t('forms.cardslide.deploy')}</span></li>
                  <div className="col-1"></div>
                  <li id='Mainbtn' className="col-5 active btn-main " onClick={generateNFTs}><span >{t('forms.cardslide.create')}</span></li>
                </ul>
              </div>
            </div>
          </div>)}
          {spinner &&
            <div className="row">
              <div className="card-body d-flex justify-content-center">
                <div className="spinner-border m-2 text-danger" role="status">
                  <span className="visually-hidden">{t('forms.cardslide.loading')}</span>
                </div>
              </div>
            </div>
          }
          {/* {(!sign && !spinner && allowGenerate) && (<div className='row mt-4'>
            <div className='col-lg-12'>
              <div className="items_filter">
                <ul className="row">
                  
                  {pending <= 0 && (<div className="col-1"></div>)}
                  <div id='Mainbtn' className={pending > 0 ? "col-5 active btn-main" : "col-9 active btn-main d-flex justify-content-center"} onClick={generateNFTs}><span>{t('forms.cardslide.create')}</span></div>

                  {pending > 0 && (
                    <>
                      <div className="col-1"></div>
                      <div id='Mainbtn' className="col-5 active btn-main " onClick={tryMintNFTs}><span >{t('forms.cardslide.mint')}</span></div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>)} */}
          <div className='row mt-4'>
            <div className='col-12'>
              <div className="items_filter d-flex justify-content-center">
              <div id='Mainbtn' className={"col-10 active btn-main "} onClick={generateNFTs}><span>{t('forms.cardslide.create')}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>)}
      {/* {smartContract && signSmartContract()} */}
    </>
  )
}

export default CardSlide;
import {
    SmartContractDetail
} from "../../../features/smartContractDetail/smartContractDetailsReducer";
import {useAppDispatch} from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { signSmartContractAction } from "../../../actions/smartContractDetails/metamask/signSmartContractAction";
import {Modal} from "react-bootstrap";
import BlockchainConnector, { isSelectedWallet } from "../../../services/blockchain/BlockchainConnector";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";


interface SCDetailsPayDeployPayload {
    smartContractDetails: SmartContractDetail;
    setShow: (value: boolean) => void;    
}

function SCDetailsPayDeployModal({smartContractDetails, setShow}: SCDetailsPayDeployPayload) {

    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const [showWalletError, setShowWalletError] = useState<boolean>(false);


    const checkIsSelectedWallet = async () => {
        const isCasper = smartContractDetails.smartContract?.standard === 'CEP-78';
        if (await isSelectedWallet(smartContractDetails.smartContract?.walletOwner!, smartContractDetails.smartContract?.blockchainId!) || isCasper) {
            dispatch(signSmartContractAction({smartContractId : smartContractDetails.smartContractId}));
            setShow(false)
        } else {
            setShowWalletError(true);
        }
    }

    return (
        <Modal
            className='modal-container'
            show={true}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton >
                <Modal.Title className="col-11" >
                    <h3 className="text-center">
                        {t('forms.cardslide.deploy')} <span className="">{smartContractDetails.smartContract?.name}</span> {t('scdetails.contract')}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row mt-2 mb-2  d-flex justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center mb-2">
                <i className="bi bi-card-checklist icon-big color-red"></i> <br/>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                    <p>{t('scdetails.deployed')}</p>
                    <br/>
                </div>
                <div className="col-lg-10 mt-3">
                    <h4 className="text-center">{t('scdetails.deployWeb3')} </h4>
                </div>
                {showWalletError &&
                    <Alert severity="error">
                        {t('scdetails.walletOwner')} {smartContractDetails.smartContract?.walletOwner}<br/>
                        {t('scdetails.changeWallet')}
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-12 ">
                        <button className=" btn-main" onClick={checkIsSelectedWallet} >
                            {t('scdetails.payDeploy')}
                        </button>
                    </div>

                </div>

            </Modal.Footer>
        </Modal>
    )
}


export default SCDetailsPayDeployModal;
import Configurator from "../../views/Configurator/Configurator";
import Header from "../../components/Header";
import Profile from "../../components/Profile";
import { SMART_CONTRACT_TYPE, SmartContract } from "../../models/smartContract";
import SmartContractService from "../../services/smartContract.service";
import { ethers } from "ethers";
import GenerateNFTs from "../../components/GenerateNFTs";
import NFTService from "../../services/nft.service";
import NFTsList from "../../components/NFTsList";
import { STATUS_IN_BC } from "../../models/blockchains";
import { Spinner } from "react-bootstrap";
import AnalyticsService from "../../services/analytics.service";
import SmartContractList from "../../components/dashboard/SmartContractList";
import Notification from "../../components/Notification";
import { useTranslation } from "react-i18next";
import BlockchainConnectService from "../../services/blockchainConnect.service";
import useDashboard from "./useDashboard";
import { addPendingContract, setDashboardErrorMessage, setDashboardMintedMessage, setDashboardMintingLoading, setDashboardPendingSCs, setDashboardShowNFTsMinted, setDashboardShowSCSigned, setDashboardSignedSCs, setDashboardSuccesMessage } from "../../features/dashboard/dashboardReducer";
import { useAppDispatch } from "../../app/hooks";
import './Dashboard.scss'
import SkeletonDashboard from './SkeletonDashboard'
import { signSmartContractAction } from "../../actions/smartContractDetails/metamask/signSmartContractAction";
import { useState } from "react";

function Dashboard() {

  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [showConfigurator, setShow] = useState(false);
  const [showError, setShowError] = useState(true);
  // const [emptyList, setEmptyList] = useState(true);
  const [showGenerateNFTs, setShowGenerateNFTs] = useState(false);
  const [smartContractChoosed, setSmartContractChoosed] = useState<SmartContract>({});
  const [showNFTs, setShowNFTs] = useState(false);
  const handleClose = () => setShowError(false);
  const [showMint, setShowMint] = useState(false);

  const [dashboard] = useDashboard();


  const smartContractCreated = async (scCreated: SmartContract) => {
    scCreated.autoSign = true;

    const pendingNew = [];
    pendingNew.push(scCreated);
    dispatch(addPendingContract(scCreated));

    if (scCreated.includeDeploy) {
      dispatch(setDashboardSuccesMessage('Your contract will be deployed automatically'));
      return;
    }

    // signSmartContract(scCreated);
    // if (scCreated._id) dispatch(signSmartContractAction({ smartContractId: scCreated._id }));

  };


  const generateErrorData = async (msgAnalytics: string, msgUser: string) => {
    AnalyticsService.error(msgAnalytics);
    dispatch(setDashboardErrorMessage(msgUser));
    setShowError(true);
  }

  const setSignedSmartContract = async (smartContractId: string, smartContractAddress: string) => {

    try {
      await SmartContractService.getInstance().signSmartContract(smartContractAddress, '',  smartContractId);
    } catch (error) {
      AnalyticsService.error("Dashboard:(API) Signed SmartContract " + smartContractId);
    }
  };

  const getSmartContractSigned = (smartContract: SmartContract) => {
    // //console.log("GET SC", smartContract);
    smartContract = { ...smartContract }; // READ-ONLY PROPERTIES
    smartContract.status = STATUS_IN_BC.MINTED;
    const smartContractsFiltered = dashboard.smartContracts.filter(sc => sc._id !== smartContract._id);
    // //console.log("smartContractsFiltered", smartContractsFiltered);
    dispatch(setDashboardPendingSCs(smartContractsFiltered));
    smartContract.autoSign = false;
    const signedNew = [smartContract];
    // //console.log("signedNew", smartContractsFiltered);
    dispatch(setDashboardSignedSCs(signedNew.concat(dashboard.smartContractsSigned)));
  };

  const generateNFTs = (smartContract: SmartContract) => {
    if (smartContract._id) {
      setSmartContractChoosed(smartContract);
      setShowGenerateNFTs(true)
    }
  };

  const getPenfingNFTs = async (smartContract: SmartContract) => {
    ////console.log("GET PENDING NFTS", smartContract);
    if (smartContract._id) {
      const nftsToSign = await NFTService.getInstance().nftsPendingMint(smartContract._id);
      if (nftsToSign.totalToMint > 0) mintNFTs(smartContract, nftsToSign);
    }
  }

  const smartContractWithNFTsPending = async (smartContract: SmartContract) => {
    const newSCs: SmartContract[] = [];
    dashboard.smartContractsSigned.forEach(sc => {
      if (sc._id === smartContract._id) {
        smartContract.autoSign = true;
        newSCs.push(smartContract);
      }
      else {
        newSCs.push(sc);
      }
    });
    dispatch(setDashboardSignedSCs(newSCs));
  }

  const mintNFTs = async (smartContract: SmartContract, nftsToSign: any) => {

    AnalyticsService.click("Dashboard: Mint NFTs");
    const overrides = {
      gasLimit: 16721970,
    };

    try {
      const chain = parseInt(nftsToSign.blockchainId, 10);
      await BlockchainConnectService.getConnection(chain);

      const smartContractABI = require('./../../smartContracts/smartContractGeneric.json').abi;
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(nftsToSign.smartContractAddress, smartContractABI, signer);

      let response;
      try {

        if (smartContract.smartContractType === SMART_CONTRACT_TYPE.MTM_1155_MIN) {
          response = await contract.mintBatch(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.ids, overrides);
        }
        else {
          response = await contract.mintNFTs(nftsToSign.addresses, nftsToSign.amounts, nftsToSign.uris, overrides);
        }
        setShowMint(true);
        setSignedNFTs(smartContract, nftsToSign);
        response.wait(1).then((finalized: any) => {
          NFTService.getInstance().mintNFT(smartContract._id ? smartContract._id : '', nftsToSign);
          dispatch(setDashboardMintedMessage('NFTs minted correctly'));
        }).catch((error: any) => {
          console.error(error);
          generateErrorData("Dashboard: Sign NFTs wait confirmation", "Transaction Failed, please,  contact with support");
        })

      } catch (error) {

        generateErrorData("Dashboard: Sign NFTs sending transaction", "Transaction Failed, please,  contact with support");
      }


    } catch (error) {
      console.error(error);
      generateErrorData("Dashboard: Sign NFTs", "Transaction Failed, please,  contact with support");
    }


  }

  const setSignedNFTs = async (smartContract: SmartContract, nftsToSign: any) => {

    try {
      await NFTService.getInstance().signNFT(smartContract._id ? smartContract._id : '', nftsToSign);
    } catch (error) {
    }
  }

  return (
    <div className="d-flex flex-column width-screens">
      <section className='container no-bottom mt-2 '>
        <Profile />
        <div className='row'>
          <div className='col-lg-12'>
            <div className="items_filter">
              <ul className="row d-flex justify-content-end ">
                <div id='' className="col-md-2 col-6 btn-main hide m-2" onClick={() => { setShowNFTs(!showNFTs) }}><span>{showNFTs ? "My Collections" : "My NFTs"}</span></div>
                {/* <li className="col-1"></li>
                <li id='' className="col-2 btn-main"><span onClick={() => { }}>My NFTs</span></li> */}
                {/* <div className="col-8"></div> */}
                <div id='' className="col-12 col-md-4 col-lg-2 btn-main  btn-main m-3" onClick={() => { setShow(true); AnalyticsService.click('Dashboard: Create Collection'); }}><span >{t('dashboard.create')}</span></div>
              </ul>

            </div>
          </div>
        </div>
      </section>
      {!showNFTs && dashboard.smartContracts?.length === 0 && dashboard.smartContractsSigned?.length === 0 && !dashboard.loading && (
        <section className="container">
          <div className="row d-flex justify-content-center">
            <h1 className="text-center mb-5">{t('dashboard.collection')}</h1>
            <button className="col-4 btn-main" onClick={() => { setShow(true); AnalyticsService.click('Dashboard: Create First Collection') }}>{t('dashboard.first')}</button>
          </div>
        </section>
      )}
      {!showNFTs && dashboard.smartContracts.length > 0 && !dashboard.loading && (<section className="container no-top no-bottom mb-3">
        <SmartContractList
          title={t('dashboard.off-chain')}
          description={t('dashboard.collection-created')}
          smartContracts={dashboard.smartContracts}
          generateNFTs={generateNFTs}
          signSmartContract={(contract: SmartContract) => { if (contract._id) dispatch(signSmartContractAction({ smartContractId: contract._id })) }}
          getPenfingNFTs={getPenfingNFTs}
        />
      </section>
      )}
      {!showNFTs && dashboard.smartContractsSigned?.length! > 0 && !dashboard.loading && (<section className="container no-top">
        <SmartContractList
          title={t('dashboard.on-chain')}
          description={t('dashboard.collection-deployed')}
          smartContracts={dashboard.smartContractsSigned}
          generateNFTs={generateNFTs}
          getPenfingNFTs={getPenfingNFTs}
        />
      </section>
      )}
      {!showNFTs && dashboard.smartContracts.length === 0 && dashboard.loading && (<SkeletonDashboard />)}
      {showConfigurator && (
        <Configurator show={showConfigurator} setShow={(value: boolean) => setShow(value)} setShowError={setShowError} smartContractCreated={smartContractCreated} />
      )}
      {!showNFTs && showGenerateNFTs && (
        <GenerateNFTs
          show={showGenerateNFTs}
          setShow={setShowGenerateNFTs}
          smartContract={smartContractChoosed}
          smartContractWithNFTsPending={smartContractWithNFTsPending}
          setNFTsMinted={(message: string) => dispatch(setDashboardMintedMessage(message))}
          setShowMint={setShowMint}
          setError={(errorMessage: string) => dispatch(setDashboardErrorMessage(errorMessage))}
          showError={setShowError}
          setSpinnerMinting={(value: boolean) => dispatch(setDashboardMintingLoading(value))}
          signSmartContract={(contract: SmartContract) => { if (contract._id) dispatch(signSmartContractAction({ smartContractId: contract._id })) }}
          scDeployed={smartContractChoosed.status ? smartContractChoosed.status : STATUS_IN_BC.PENDING}
          setEvolveNFT={() => { }} />
      )}
      {showNFTs && (
        <section className='container no-bottom'>
          <div className="row">
            <NFTsList spinner={dashboard.loadingNFTs} nfts={dashboard.nftsList} evolveable={false} minteable={false} />
          </div>
        </section>
      )}
      {dashboard.errorMessage !== '' && (
        <Notification
          title={t('dashboard.sorry')}
          description={dashboard.errorMessage}
          show={showError}
          close={handleClose}
          severity="error"
        />
      )}
      {dashboard.showNFTMinted && (
        <Notification
          title={t('dashboard.successful')}
          description={dashboard.NFTsMintedMessage}
          show={dashboard.showNFTMinted}
          close={() => dispatch(setDashboardShowNFTsMinted(false))}
          severity="success"
        />
      )}
      {dashboard.succesMessage !== '' && (
        <Notification
          title={dashboard.succesMessage}
          description={dashboard.succesMessage}
          show={dashboard.succesMessage !== ''}
          close={() => dispatch(setDashboardSuccesMessage(''))}
          severity="success"
        />
      )}
      {dashboard.showSCSigned && (
        <Notification
          title={t('dashboard.signedSC')}
          description={t('dashboard.signedSC')}
          show={dashboard.showSCSigned}
          close={() => dispatch(setDashboardShowSCSigned(false))}
          severity="success"
        />
      )}
      {dashboard.loadingMinting && (
        <section className='container'>
          <div className="row">
            <div className="card-body d-flex justify-content-center">
              <Spinner animation="border" variant="danger" />
            </div>
          </div>
        </section>
      )}
      {showMint && (
        <Notification
          title={t('dashboard.minting-NFTs')}
          description={t('dashboard.your-NFTs')}
          show={showMint}
          close={() => setShowMint(false)}
          severity="info"
        />
      )}
    </div>
  );
}

export default Dashboard;
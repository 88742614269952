import { BLOCKCHAIN, STATUS_IN_BC } from "./blockchains";
import { NFTView } from "./nfts";
import { PaymentData } from "./payment";

export const enum SMART_CONTRACT_TYPE {
    MTM_721,
    MTM_1155,
    MTM_78,
    MTM_1155_MIN
}

export enum STANDARD{
    ERC_721,
    ERC_1155,
    CEP_78
}


export type SmartContractData = {
    smartContract : SmartContract;
    nfts : NFTView[];
}


export type SmartContract = {
    _id?: string;
    walletOwner?: string;
    publicKeyCasper?: string;
    standard?: MTM_STANDARD;
    maxNFTs?: number;
    name?: string;
    description?: string;
    symbol?: string;
    nftsSigned?: number;
    nftsGenerated?: number;
    nftsMinted?: number;
    smartContractCompiled?: string;
    smartContractAddress?: string;
    smartContractType?: SMART_CONTRACT_TYPE;
    blockchain?: BLOCKCHAIN;
    blockchainId?: string;
    configuration?: ConfigSmartContract;
    attributes?: Attribute;
    autoSign?:boolean;
    status?: STATUS_IN_BC;
    img?: string;
    banner?: string;
    price?: number;
    includeDeploy?: boolean;
    paid?: boolean;
    configResponse?: any;
    valuesResponse?: any;
    paymentData?: PaymentData;
}

export type MTM_STANDARD = 'ERC-721' | 'ERC-1155' | 'CEP-78'

export type Attribute = {
    key?: string;
    value?: string;
}

export type ConfigSmartContract = {
    mintPrice?: number;
    mintPausable?: boolean;
    dynamicSupply?: boolean;
    dateInitMint?: string;
    dateEndMint?: string;
    whiteList?: Boolean;
    dateInitWhiteList?: string;
    dateEndWhiteList?: string;
    maxWL?: number;
    marketBlocked?: boolean;
    marketPausable?: boolean;
    burnNFTs?: boolean;
    transferPausable?: boolean;
    dynamicsNFTs?: boolean;
    percentFees?: number; 
    uniqueImage?: string;
    feesMTM : number;
    royaltyRecipients?: RoyaltyRecipient[];
    mintByUser? : boolean;
}


export type Configuration = {
    royalties: boolean,
    whiteList: boolean,
    dynamicNFTs: boolean,
    fixedPrice: boolean,
    dynamicSupply: boolean,
    customMintDate: boolean,
    mintPausable: boolean,
    burnNFTs: boolean,
    transferPausable: boolean,
    transferCustom: boolean,
    marketBlocked: boolean,
    marketPausable: boolean
}

export type ConfigSCProps = {
    blockchain : BLOCKCHAIN,
    standard : MTM_STANDARD,
    step : number,
    setShow: any,
    smartContractCreated : any,
    setShowError : any
    casperWallet?: string;
}

export type RoyaltyRecipient = {
    wallet : string;
    royalties : number;
}

export interface WalletRoyaltyFormValues {
    total : number;
    royaltyList : RoyaltyRecipient[];
}

import Blockchains from "../../components/Blockchains";
import { useEffect, useState } from "react";
import ConfiguratorSmartContract from "../../components/ConfiguratorSmartContract";
import { Modal, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { BLOCKCHAIN, BlockchainInfo } from "../../models/blockchains";
import AnalyticsService from "../../services/analytics.service";
import './configurator.css';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserInfo, selectUserInfo } from "../../features/user/user";
import { MTM_STANDARD } from "../../models/smartContract";


const BlockchainListAvailable: BlockchainInfo[] = [
    {
        name: "Casper Network",
        img: "/img/blockchains/white/casper.png",
        imgLogo: "/img/blockchains/onlyLogo/casper.png",
        id: BLOCKCHAIN.CASPER,
        id_testnet: BLOCKCHAIN.CASPER_TESTNET,
        selected: true,
        isCasper: true
    },
    {
        name: "Ethereum",
        img: "/img/blockchains/white/eth.png",
        imgLogo: "/img/blockchains/onlyLogo/ethereum.png",
        id: BLOCKCHAIN.ETHEREUM,
        id_testnet: BLOCKCHAIN.RINKEBY,
        selected: false
    },
    {
        name: "Polygon",
        img: "/img/blockchains/white/polygon.png",
        imgLogo: "/img/blockchains/onlyLogo/polygon.png",
        id: BLOCKCHAIN.POLYGON,
        id_testnet: BLOCKCHAIN.MUMBAI,
        selected: false
    },
    {
        name: "Binance Smart Chain",
        img: "/img/blockchains/white/bsc.png",
        imgLogo: "/img/blockchains/onlyLogo/bsc.png",
        id: BLOCKCHAIN.BSC,
        id_testnet: BLOCKCHAIN.BSC_TESNET,
        selected: false
    }
    // {
    //     name: "Oasis Network",
    //     img: "/img/blockchains/oasis.png",
    //     imgLogo: "/img/blockchains/oasis.png",
    //     id: BLOCKCHAIN.OASIS,
    //     id_testnet: BLOCKCHAIN.OASIS_TESTNET,
    //     selected: false,
    //     isCasper: false
    // },
    // {
    //     name: "AVALANCHE",
    //     img: "/img/blockchains/avax.png",
    //     imgLogo: "/img/blockchains/avax.png",
    //     id: BLOCKCHAIN.AVAX,
    //     id_testnet: BLOCKCHAIN.AVAX_TESTNET,
    //     selected: false,
    //     isCasper: false
    // }
];

interface NFTConfig {
    type: string;
    img: string;
    standard: MTM_STANDARD;
    desc: string;
    details: string;
}

function Configurator({ show, setShow, smartContractCreated, setShowError }: any) {

    const [standard, setStandard] = useState<MTM_STANDARD>("ERC-1155");
    const [blockchain, setBlockchain] = useState(process.env.REACT_APP_ENV_IS_TESTNET! === 'true' ? BLOCKCHAIN.CASPER_TESTNET : BLOCKCHAIN.CASPER);
    const [step, setStep] = useState(1);
    const [casperWallet, setCasperWallet] = useState<string>();
    const analyticsService = new AnalyticsService();
    const { t } = useTranslation('common');
    const userInfo: UserInfo = useSelector(selectUserInfo);


    const nfts: NFTConfig[] = [
        {
            type: "ERC-721 Collection",
            img: "/img/dashboard/card-721.png",
            standard: "ERC-721",
            desc: "Enables unique and easy NFT Collections for any creator or community",
            details: "\"ERC721 is a standard for representing ownership of non-fungible tokens, that is, where each token is unique.\""
        },
        {
            type: "ERC-1155 Collection",
            img: "/img/dashboard/card-1155.png",
            standard: "ERC-1155",
            desc: "Allows more configurable and customizable Collections of NFTs than ERC721",
            details: "\"ERC-1155 Multi Token Standard allows each token ID to represent a new type of configurable token, which can have its own metadata, provisioning, and other attributes.\""
        },
        {
            type: "CEP-78 Collection",
            img: "/img/dashboard/card-1155.png",
            standard: "CEP-78",
            desc: "NFT standard for Casper Network",
            details: "\"CEP-78 Multi Token Standard allows each token ID to represent a new type of configurable token, which can have its own metadata, provisioning, and other attributes.\""
        }
    ];

    const handleClose = () => { analyticsService.click("CSC: Close"); setShow(false) };


    const setTypeSmartContract = (value: MTM_STANDARD) => {
        analyticsService.click("Standard " + value);
        setStandard(value);
        ////console.log("Standar: " , standard );
    }

    const setBlockchainChosen = (bc: BLOCKCHAIN) => {
        analyticsService.click("Blockchain " + bc);
        setBlockchain(bc);
    };

    const getPercentStep = () => {
        switch (step) {
            case 1:
                return 30;
            case 2:
                return 45;
            case 3:
                return 60;
            case 4:
                return 75;
            case 5:
                return 90;
        }
    }

    const isCasperSelected = () => {
        return blockchain === 7 || blockchain === 8;
    }

    useEffect(() => {
        if (show) {
            analyticsService.modal("Configurator Smart Contract");
            analyticsService.click("CSC:Standard default " + standard);
            analyticsService.click("CSC:Blockchain default " + blockchain);
        }
    }, []);
    return (
        <Modal
            className='modal-container'
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header  >
                
                <Modal.Title className="col" >
                    <h2 className="text-center">
                        {t('configurator.smart')}
                    </h2>
                    <ProgressBar animated now={getPercentStep()} variant="mtmbar" />
                </Modal.Title>
                <button type="button" onClick={handleClose} className="btn-close btn-close-white" aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body>
                <div style={{minHeight : '600px', maxHeight: '600px', overflowY : 'auto', overflowX : 'hidden'}}>
                {step === 1 && (
                    <>
                        <h1 className="text-center mt-3 mb-2">{t('configurator.choose-blockchain')}</h1>
                        <section className="col bcs-configurator">
                            <Blockchains
                                blockchainchosen={setBlockchainChosen}
                                blockchainsList={BlockchainListAvailable}
                                setCasperWallet={setCasperWallet}
                                cols="col-4 pt-4"
                                polygonMain={false}/>
                        </section>
                    </>
                )}
                {step === 2 && (
                    <section className='container no-top mt-3 no-bottom mb-2'>
                    <h1 className="text-center mb-5">  {t('configurator.choose-standard')}</h1>
                    <div className='row'>
                        <div className={isCasperSelected() ? "create-smart col-12" : 'col-12'}>
                            {nfts.filter(nft => isCasperSelected() ? nft.standard === 'CEP-78' : nft.standard !== 'CEP-78').map((nft, index) => (
                                <OverlayTrigger
                                    key={index}
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">{nft.details}</Tooltip>}
                                >
                                    <div key={index.toString()} 
                                        className={standard === nft.standard ? "col-lg-5 col-md-12 opt-create activated pointer" : "col-lg-5 col-md-12 opt-create pointer"}
                                        onClick={() => setTypeSmartContract(nft.standard)}>
                                        <img src={nft.img} alt="" />
                                        <h3>{nft.type}</h3>
                                        <p className="mt-3">{nft.desc}</p>
                                    </div>
                                </OverlayTrigger>
                            ))}
                        </div>
                    </div>
                </section>
                )}
                {step !== 1 && (<>
                    <section className='container no-top mt-3 no-bottom mb-2'>
                        <ConfiguratorSmartContract
                            blockchain={blockchain}
                            standard={standard}
                            step={step}
                            setShowError={setShowError}
                            setShow={(value: boolean) => setShow(value)}
                            smartContractCreated={smartContractCreated}
                            casperWallet={casperWallet}/>
                    </section>
                </>)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row col-12">
                    {step === 1 && (
                        <div className="col-6"></div>
                    )}
                    {step !== 1 && (
                        <div className="col-6 d-flex justify-content-start">
                            <div className='btn-main' onClick={() => {
                                    analyticsService.click("CSC: Step " + String(step - 1));
                                    if (userInfo?.wallets && userInfo.wallets?.length <= 1  && step === 4) {
                                        setStep(step - 2);
                                    } else {
                                        setStep(step - 1);
                                    }
                                }}>
                                {t('configurator.previous')}
                            </div>
                        </div>
                    )}
                    {step >= 1 && step < 5 && (
                        <div className="col-6 d-flex justify-content-end">
                            <div className='btn-main' onClick={() => {
                                analyticsService.click("CSC: Step " + String(step + 1));
                                if (userInfo?.wallets && userInfo.wallets?.length <= 1 && step === 2) {
                                    setStep(step + 2);
                                } else {
                                    setStep(step + 1);
                                }
                            }}>{t('configurator.next')}</div>
                        </div>
                    )}
                </div>

            </Modal.Footer>
        </Modal>

    );
}

export default Configurator;
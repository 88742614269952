import * as amplitude from '@amplitude/analytics-browser';

class AnalyticsService {

    view( view : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'View ->' + view);
    }

    click( button : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Click ->' + button);
    }

    modal( modal : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Modal ->' + modal);
    }

    error( error : string){
        //console.log("Error:", error);
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Error ->' + error);
    }

    transaction( tx: string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Transaction OK ->' + tx);
    }

    static view( view : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'View ->' + view);
    }

    static click( button : string){
        // console.log('click : ', button);
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Click ->' + button);
    }

    static modal( modal : string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Modal ->' + modal);
    }

    static error( error : string){
        //console.log("Error:", error);
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Error ->' + error);
    }

    static transaction( tx: string){
        // if(process.env.REACT_APP_ENV_ANALYTICS !== "LOCAL-") amplitude.track(process.env.REACT_APP_ENV_ANALYTICS! + 'Transaction OK ->' + tx);
    }


}

export default AnalyticsService;
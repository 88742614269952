import { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormProvider } from "react-hook-form";
import { ConfigSCProps, Configuration, SmartContract, SMART_CONTRACT_TYPE } from '../models/smartContract';
import AnalyticsService from '../services/analytics.service';
import FileService, { FileType } from '../services/file.service';
import SmartContractService from '../services/smartContract.service';
import { useTranslation } from "react-i18next";
import WalletRoyaltyFormField from './forms/walletsRoyalties/walletRoyaltyFormField';
import useWalletRoyaltyForm from './forms/walletsRoyalties/useWalletRoyaltyForm';
import { selectUserInfo, UserInfo, WalletsUser } from "../features/user/user";
import { useSelector } from "react-redux";
import { useAppDispatch } from '../app/hooks';
import { setDashboardErrorMessage, setDashboardSuccesMessage, setNotification, setPaymentData } from '../features/dashboard/dashboardReducer';
import './ConfiguratorSmartContract.scss';
import { PaymentData } from '../models/payment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { BLOCKCHAIN } from '../models/blockchains';

export type myImg = {
    path: string,
    file: any
}

const walletsProviders = {
    google: './img/login/google.png',
    discord: './img/login/discord.png',
    linkedin: './img/login/linkedin.png',
    metamask: './img/wallet/1.png',
    casper : './img/blockchains/white/casper.png'
}

function ConfiguratorSmartContract({ blockchain, standard, step, setShow, smartContractCreated, casperWallet, setShowError }: ConfigSCProps) {

    const [img, setImage] = useState<myImg>();
    const [banner, setBanner] = useState<myImg>();
    const [config, setConfig] = useState<Configuration>({
        royalties: false,
        whiteList: false,
        dynamicNFTs: false,
        fixedPrice: false,
        dynamicSupply: false,
        customMintDate: false,
        mintPausable: false,
        burnNFTs: false,
        transferPausable: false,
        transferCustom: false,
        marketBlocked: false,
        marketPausable: false
    });
    const [spinner, setSpinner] = useState(false);
    const mint4AllService = new SmartContractService();
    const analyticsService = new AnalyticsService();
    const { methods } = useWalletRoyaltyForm();
    const userInfo: UserInfo = useSelector(selectUserInfo);
    let initialWallets: WalletsUser[] = userInfo.wallets?.map((wallet, index) => ({
        wallet: wallet.wallet,
        loginType: wallet.loginType,
        selected: wallet.selected
    })) || [];
    const [wallets, setWallets] = useState<WalletsUser[]>(initialWallets);
    const [selectedWallet, setSelectedWallet] = useState<string>(wallets[0].wallet);
    const dispatch = useAppDispatch();
    const fileService = new FileService();
    const { t } = useTranslation('common');

    const getImg = (event: any) => {
        setImage({ path: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] })
    };
    const getBanner = (event: any) => {
        setBanner({ path: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] })
    };

    const sendForm = async (e: React.SyntheticEvent) => {
        analyticsService.click("CSC: Send Smart Contract");
        setSpinner(true);
        e.preventDefault();
        const target = e.target as typeof e.target & {
            typeSmartContract: { value: SMART_CONTRACT_TYPE };
            name: { value: string };
            description: { value: string };
            symbol: { value: string };
            maxNFTs: { value: number }
            royaltiesMTM: { value: number },
            royaltiesOwner: { value: number },
            fixedPrice: { value: number },
            maxWhiteList: { value: number },
            whiteListStartingDate: { value: string },
            whiteListExpirationDate: { value: string },
            mintStartingDate: { value: string },
            mintExpirationDate: { value: string },
        };

        const data: SmartContract = {
            name: target.name.value,
            description: target.description.value,
            symbol: target.symbol.value,
            maxNFTs: target.maxNFTs.value,
            walletOwner: selectedWallet || userInfo.wallet,
            configuration: {
                feesMTM: 0
            }
        }

        if (data.configuration) {

            if (config.royalties && methods.getValues().royaltyList.length > 0) {
                data.configuration.royaltyRecipients = methods.getValues().royaltyList;
            }
            else {
                data.configuration.percentFees = 0;
            }
            if (config.whiteList) {
                data.configuration.maxWL = target.maxWhiteList.value;
                data.configuration.whiteList = config.whiteList;
                data.configuration.dateInitWhiteList = target.whiteListStartingDate.value;
                data.configuration.dateEndWhiteList = target.whiteListExpirationDate.value;
            }
            if (config.customMintDate) {
                data.configuration.dateInitMint = target.mintStartingDate.value;
                data.configuration.dateEndMint = target.mintExpirationDate.value;
            }
            if (config.mintPausable) {
                data.configuration.mintPausable = config.mintPausable;
            }
            if (config.fixedPrice) {
                data.configuration.mintPrice = target.fixedPrice.value;
            }


            data.configuration.marketPausable = config.marketPausable;
            data.configuration.marketBlocked = config.marketBlocked;
            data.configuration.burnNFTs = config.burnNFTs;
            data.configuration.transferPausable = config.transferPausable;
            data.configuration.dynamicsNFTs = config.dynamicNFTs;
            data.configuration.dynamicSupply = config.dynamicSupply;
        }
        data.blockchain = blockchain;
        data.standard = standard;

        if (casperWallet) {
            data.publicKeyCasper = casperWallet;
        }

        try {
            if (img) {
                const imgName = await fileService.uploadFile(img.file, FileType.SMART_CONTRACT_IMG);
                data.img = imgName;
            }
            if (banner) {
                const bannerName = await fileService.uploadFile(banner.file, FileType.SMART_CONTRACT_BANNER);
                data.banner = bannerName;
            }
            const scCreated = await mint4AllService.createSmartContract(data);

            if (scCreated && scCreated.includeDeploy) {
                dispatch(setNotification({
                    title: 'Collection ' + scCreated.name + ' created',
                    description: scCreated.blockchain === BLOCKCHAIN.POLYGON ||  scCreated.blockchain === BLOCKCHAIN.MUMBAI ? 'Your contract will be deployed in the next 24 hours' : "You can deploy your contract now",
                    descriptionDetails: 'You can create NFTs now!'
                }));
                setSpinner(false);
                smartContractCreated(scCreated);
                setShow(false);
                return;
            }

            if (scCreated && !scCreated.includeDeploy && !scCreated.paid) { // TODO FLUJO PAYMENT
                
                if(scCreated.paymentData?.clientSecret){
                    dispatch(setPaymentData(scCreated.paymentData));
                }
                
                setSpinner(false);
                smartContractCreated(scCreated);

                if(scCreated.paymentData?.invoiceId){
                    dispatch(setNotification({
                        title: 'Collection ' + scCreated.name + ' created',
                        description: scCreated.blockchain === BLOCKCHAIN.POLYGON ||  scCreated.blockchain === BLOCKCHAIN.MUMBAI ? 'Your contract will be deployed in the next 24 hours' : "You can deploy your contract now",
                        descriptionDetails: 'You can create NFTs now!'
                    }));
                    setShow(false);
                    return;
                }

                setShow(false);
                return;
            }

            if(!scCreated || (!scCreated.includeDeploy && !scCreated.paid && !scCreated.paymentData?.clientSecret && scCreated.paymentData?.invoiceId )){
                setSpinner(false);
                setShow(false);
                dispatch(setDashboardErrorMessage("Error creating and deploying Smart Contract "));
                setShowError(true);
                throw new Error("Smart Contract error");
            }
            

        } catch (error) {
            analyticsService.error("CSC: Sending Smart Contract");
        }

    }


    const setConfigValue = (value: string) => {

        let configUpdated: any = { ...config };
        const event = !configUpdated[value] ? "CSC: Selected feature " : "CSC: Unselected feature ";
        analyticsService.click(event + value);
        configUpdated[value] = !configUpdated[value];
        setConfig(configUpdated);
    }

    const select = (selectedWallet: WalletsUser) => {
        const newWallets = wallets?.map(wallet => ({
            wallet: wallet.wallet,
            loginType: wallet.loginType,
            selected: wallet.wallet === selectedWallet.wallet
        }));
        setWallets(newWallets);
        setSelectedWallet(selectedWallet.wallet);
    }

    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-10">
                    <FormProvider {...methods}>
                        <form id="form-create-item" onSubmit={sendForm} className="form-border " >
                            <div className="field-set ">
                                {step === 3 && (
                                    <>
                                        <h1 className="d-flex justify-content-center">{t('forms.configuratorsmartcontract.selectWallet')}</h1>
                                        <p className='selectWalletDescription text-center'>{t('forms.configuratorsmartcontract.selectWalletDescription')}</p>
                                        <div className='wallets-scroller'>
                                            {wallets?.map((wallet) => (
                                                [BLOCKCHAIN.CASPER_TESTNET, BLOCKCHAIN.CASPER].includes(blockchain) && wallet.loginType === 'casper' && (
                                                    <div key={wallet.wallet}
                                                        className={wallet.selected ? 'wallet-container social-login selected' : 'wallet-container social-login'}
                                                        onClick={() => select(wallet)}>
                                                        <img src={walletsProviders[wallet.loginType]} />
                                                        <span>{`${wallet.wallet?.slice(0, 10)}. . .${wallet.wallet?.slice(-10)}` }</span>
                                                    </div>
                                                )
                                            ))}
                                            {wallets?.map((wallet) => (
                                                ![BLOCKCHAIN.CASPER_TESTNET, BLOCKCHAIN.CASPER].includes(blockchain) && wallet.loginType !== 'casper' && (
                                                    <div key={wallet.wallet}
                                                        className={wallet.selected ? 'wallet-container social-login selected' : 'wallet-container social-login'}
                                                        onClick={() => select(wallet)}>
                                                        <img src={walletsProviders[wallet.loginType]} />
                                                        <span>{`${wallet.wallet?.slice(0, 10)}. . .${wallet.wallet?.slice(-10)}` }</span>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </>
                                )}
                                {step === 4 && (
                                    <>
                                        <h1 className="d-flex justify-content-center">{t('forms.configuratorsmartcontract.select')}</h1>
                                        <div className="de_tab tab_methods d-flex justify-content-center mt-5 mb-5">
                                            <div className="de_nav">
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.you')}</Tooltip>}
                                                >
                                                    <li id='btn3' onClick={() => setConfigValue("royalties")} className={config.royalties ? "active" : ""}><span><i className="fa fa-money" ></i>{t('forms.configuratorsmartcontract.set')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.it')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => setConfigValue("mintPausable")} className={config.mintPausable ? "active" : ""}><span><i className="fa fa-pause"></i>{t('forms.configuratorsmartcontract.pause-minting')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.allows')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => setConfigValue("fixedPrice")} className={config.fixedPrice ? "active" : ""}><span><i className="fa fa-tag"></i>{t('forms.configuratorsmartcontract.minting')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.allows-you')}</Tooltip>}
                                                >
                                                    <li id='btn2' onClick={() => setConfigValue("marketBlocked")} className={config.marketBlocked ? "active" : ""}><span><i className="fa fa-lock"></i>{t('forms.configuratorsmartcontract.block')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.reselling')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => setConfigValue("marketPausable")} className={config.marketPausable ? "active" : ""}><span><i className="fa fa-pause"></i>{t('forms.configuratorsmartcontract.pause-market')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.delete')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => setConfigValue("burnNFTs")} className={config.burnNFTs ? "active" : ""}><span><i className="fa fa-fire"></i>{t('forms.configuratorsmartcontract.delete-NFTs')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                {/* <li id='btn1' onClick={() => setConfigValue("transferCustom")} className={config.transferCustom ? "active" : ""}><span><i className="fa fa-exchange"></i>Transfer custom</span>
                                        </li> */}
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.transfer')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => setConfigValue("transferPausable")} className={config.transferPausable ? "active" : ""}><span><i className="fa fa-pause"></i>{t('forms.configuratorsmartcontract.pause-transfer')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.imagen/video')}</Tooltip>}
                                                >
                                                    <li id='btn3' onClick={() => setConfigValue("dynamicNFTs")} className={config.dynamicNFTs ? "active" : ""}><span><i className="fa fa-retweet"></i>{t('forms.configuratorsmartcontract.dynamics-NFTs')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.allows-modify')}</Tooltip>}
                                                >
                                                    <li id='btn3' onClick={() => setConfigValue("dynamicSupply")} className={config.dynamicSupply ? "active" : ""}><span><i className="fa fa-plus-square-o"></i>{t('forms.configuratorsmartcontract.dynamic-supply')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.allows-add')}</Tooltip>}
                                                >
                                                    <li id='btn3' onClick={() => { }} className={config.whiteList ? "disabled " : "disabled"}><span><p>{t('forms.configuratorsmartcontract.coming-soon')}</p><i className="fa fa-list"></i>{t('forms.configuratorsmartcontract.white-list')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2">{t('forms.configuratorsmartcontract.allows-enable')}</Tooltip>}
                                                >
                                                    <li id='btn1' onClick={() => { }} className={config.customMintDate ? "disabled" : "disabled"}><span><p>{t('forms.configuratorsmartcontract.coming-soon')}</p><i className="fa fa-calendar"></i>{t('forms.configuratorsmartcontract.custom')}</span>
                                                    </li>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step === 5 && (
                                    <>
                                        <h1 className="text-center mb-5">  {t('configurator.set-data')}</h1>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-12 col-lg-6">
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.title')}</h5>
                                                <input type="text" name="name" id="name" className="form-control" required placeholder="e.g. 'Dashboard minteandome Collection'" />
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.description')}</h5>
                                                <input data-autoresize required name="description" id="description" className="form-control" placeholder="e.g. 'This is very limited collection'" />
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.symbol')}</h5>
                                                <input data-autoresize required name="symbol" id="symbol" className="form-control" placeholder="e.g. M4A" />
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.max-supply')}</h5>
                                                <input type="number" min="1" required name="maxNFTs" id="maxNFTs" className="form-control" placeholder="enter supply" />
                                            </div>
                                        </div>
                                        <div className="spacer-10"></div>
                                        <div className='row'>
                                            <div className='col-12 col-lg-6'>
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.image-collection')}</h5>

                                                <div className="d-create-file-sc">
                                                    {img && (
                                                        <div className='mt-2 mb-2'>
                                                            <img className="img-banner-sc" src={img.path} alt="collection"></img>
                                                        </div>
                                                    )}
                                                    {!img && (
                                                        <p id="file_name">{t('forms.configuratorsmartcontract.PNG')}</p>
                                                    )}
                                                    <div className='browse'>
                                                        <input type="button" id="get_file" className="btn-main" value="Browse" />
                                                        <input id='upload_file' accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" onChange={getImg} multiple />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.banner')}</h5>
                                                <div className="d-create-file-sc">
                                                    {banner && (
                                                        <div className='mt-2 mb-2'>
                                                            <img className="img-banner-sc" src={banner.path} alt="banner-collection"></img>
                                                        </div>
                                                    )}
                                                    {!banner && (<p id="file_name">{t('forms.configuratorsmartcontract.PNG')}</p>)}
                                                    <div className='browse'>
                                                        <input type="button" id="get_file" className="btn-main" value="Browse" />
                                                        <input id='upload_file' accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" onChange={getBanner} multiple />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center">
                                            {/* {userInfo.defaultConfig && userInfo.defaultConfig?.feesMTM > 0 && (<div className="col-6">
                                                <h5 className="text-center">{t('forms.configuratorsmartcontract.mint4All')}</h5>
                                                <input type="number" name="royaltiesMTM" id="royaltiesMTM" className="form-control" placeholder="Min 1.5%" value={userInfo.defaultConfig?.feesMTM ? userInfo.defaultConfig?.feesMTM : 3.5 } disabled />
                                            </div>)} */}
                                            {config.royalties && (
                                                <div className='col-12'>

                                                    {/* <div className="col-6">
                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.owner')}</h5>
                                                    <input type="number" step=".01" name="royaltiesOwner" id="royaltiesOwner" min="0" className="form-control" placeholder="suggested: 0, 10%, 15,5%, 30%. Maximum is 90%" />
                                                </div> */}
                                                    <WalletRoyaltyFormField />
                                                </div>
                                            )}
                                            {config.fixedPrice && (
                                                <div className="col-6">
                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.price')}</h5>
                                                    <input type="number" step=".01" name="fixedPrice" id="fixedPrice" min="0" className="form-control" placeholder="enter price for one item (ETH, BNB, MATIC)" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="spacer-10"></div>
                                        <div className="de_tab tab_methods">
                                            <div className="de_tab_content pt-3">
                                                {config.whiteList && (
                                                    <>
                                                        <h5 className="text-center">{t('forms.configuratorsmartcontract.whitelist')}</h5>
                                                        <div className="spacer-10"></div>
                                                        <div id="tab_opt_2" className=''>

                                                            <div className="row d-flex justify-content-center">
                                                                <div className="col-md-6">
                                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.starting')}</h5>
                                                                    <input type="date" name="whiteListStartingDate" id="whiteListStartingDate" className="form-control" min="1997-01-01" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.expiration')}</h5>
                                                                    <input type="date" name="whiteListExpirationDate" id="whiteListExpirationDate" className="form-control" />
                                                                </div>
                                                                <div className="col-6">
                                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.max')}</h5>
                                                                    <input type="number" name="maxWhiteList" id="maxWhiteList" className="form-control" placeholder="enter price for one item (ETH, BNB, MATIC)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {config.customMintDate && (
                                                    <>
                                                        <div className="spacer-30"></div>
                                                        <h5 className="text-center">{t('forms.configuratorsmartcontract.mint-date')}</h5>
                                                        <div className="spacer-10"></div>
                                                        <div id="tab_opt_2" className=''>

                                                            <div className="row d-flex justify-content-center">
                                                                <div className="col-md-6">
                                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.starting-date')}</h5>
                                                                    <input type="date" name="mintStartingDate" id="mintStartingDate" className="form-control" min="1997-01-01" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="text-center">{t('forms.configuratorsmartcontract.expiration-date')}</h5>
                                                                    <input type="date" name="mintExpirationDate" id="mintExpirationDate" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                        </div>


                                        <div className="spacer-single"></div>
                                        {!spinner && (
                                            <div className="col-12 d-flex justify-content-center">
                                                <input type="submit" id="submit" className="btn-main" value="Create Smart Contract" />
                                            </div>
                                        )}
                                        {spinner && (
                                            <div className="row">
                                                <div className="card-body d-flex justify-content-center">
                                                    <div className="honeycomb">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                            </div>
                        </form>
                    </FormProvider>
                </div>                           
            </div>
        </>
    )
}


export default ConfiguratorSmartContract;
import axios, { AxiosRequestConfig } from "axios";
import {store} from "../app/store";
import { ethers } from "ethers";


class SmartContractActionsService {

    private static _instance: SmartContractActionsService;
    static getInstance() {
        if (!this._instance) {
            this._instance = new SmartContractActionsService();
        }
        return this._instance;
    }

    async smartContractSetBurnable (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-burnable';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;

    }

    async smartContractSetPauseMint (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-pause-mint';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;

    }

    async smartContractSetPauseMarket (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-pause-market';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;

    }

    async smartContractSetPauseTransfer (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-pause-transfer';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;
    }

    async smartContractSetBlockMarket (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-block-market';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;
    }

    async smartContractSetEvolve (smartContractId : string, value: boolean){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-evolve';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;
    }

    async smartContractSetPrice (smartContractId : string, value: ethers.BigNumber | number){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-price';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;
    }

    async smartContractSetSupply (smartContractId : string, value: ethers.BigNumber | number){
        const url: string = process.env.REACT_APP_BASE_URL! + process.env.REACT_APP_INTERNAL_API_URL! + '/smart-contract/' + smartContractId + '/set-supply';
        const response = await axios.post(url, {value}, this.getConfigRequest());
        return response?.data?.result;
    }


    getConfigRequest () :AxiosRequestConfig {
        return {
            headers: {
                authorization: store.getState().user.jwt
            }
        };
    }
}

export default SmartContractActionsService;

import {createAsyncThunk} from "@reduxjs/toolkit";
import { TransactionMTM } from "../../services/smartContract.service";
import BlockchainConnector from "../../services/blockchain/BlockchainConnector";
import { addSignedContract } from "../../features/dashboard/dashboardReducer";
import { SmartContract } from "../../models/smartContract";

export const signSmartContractAction = createAsyncThunk('SC/deploySCTx', async ({smartContractId ,idNFTs, tx}: TransactionMTM, thunkAPI) => {
    const smartContracts: SmartContract[] = (thunkAPI.getState() as any).dashboard.smartContracts
    const smartContract = smartContracts.find(sc => sc._id === smartContractId);
    if (smartContract) {
        const chain = parseInt(smartContract.blockchainId!, 10);
        const blockchain = new BlockchainConnector(chain);
        const provider = await blockchain.getProvider();
        const transaction = await provider.getTransaction(tx);
        await transaction.wait(1);
        thunkAPI.dispatch(addSignedContract(smartContract));
    }
});